@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --primary-grey-color: #333;
  --primary-blue-color: #255cb7;
  --primary-color: #a80000;
  --secondary-color: #750707;
  --tertiary-color: rgba(169, 22, 34, 0.1);
  --red-color: #a91622;
  --red-icon-color: #780d0d;
  --color-text-primary: #201f1e;
  --color-text-grey-primary: #323130;
  --color-text-grey-secondary: rgba(0, 0, 0, 0.54);
  --color-text-grey-tertiary: #797979;
  --color-background-grey: #edebe9;
  --color-background-grey-scondary: #a19f9d;
  --navbar-item-color: #605e5c;
  --footer-black: #323130;
  --white-color: #fff;
  --black-color: #000;
  --cross-color: #e81d2e;
  --tick-color: #72c700;
  --dot-success: #6dd400;
  --dot-warning: #f7b500;
  --background-color: #f8f8f8;
  --border-primary-color: #979797;
  --primary-font-regular: "AvenirNextRegular";
  --primary-font-bold: "AvenirNextBold";
  --primary-font-demi-bold: "AvenirNextDemiBold";
  --primary-font-medium: "AvenirNextMedium";
  --secondary-font-regular: "RobotoRegular";
  --secondary-font-medium: "RobotoMedium";
  --secondary-font-bold: "RobotoBold";
  --tertiary-font-regular: "MontserratRegular";
  --tertiary-font-medium: "MontserratMedium";
  --tertiary-font-bold: "MontserratBold";
  --tertiary-font-semi-bold: "MontserratSemiBold";
}

.generic-heading.other-form-Heading,
.other-form-Heading {
  font-family: "CostarBrown", Helvetica, Arial, sans-serif !important;
  color: #000;
  /* font-weight: normal !important; */
  color: #000 !important;
}

.marketing-preference-listing li {
  list-style-type: none;
}

.white-page {
  background: #fff !important;
}

.address-in-headers {
  font-size: 27px;
  font-weight: bold;
  font-family: "Lato";
}

.custom-header-padding {
  padding: 0px !important;
  padding-top: 0.5rem !important;
}

.city-state-in-headers {
  font-size: 17px;
  font-family: var(--secondary-font-regular);
}

.property-header-style {
  background: white;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  /* border-bottom: 2px solid; */
  /* border: 1px solid; */
  /* padding: 0px; */
  box-shadow: 0px 5px #ececec;
}

#thank-you-style {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.3em !important;
  min-height: 1.3em !important;
  text-align: center !important;
  font-size: 2.2rem !important;
  letter-spacing: 0.05em !important;
  color: rgb(7, 50, 82) !important;
}

.center-content {
  display: flex;
  justify-content: center;
}

.other-sub-form-Heading {
  font-family: "CostarBrown", Helvetica, Arial, sans-serif !important;
  font-weight: 100 !important;
  color: #000 !important;
}

.font-size-on-listing {
  font-size: 23px;
}

.marketing-head {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
  line-height: 2rem;
  font-family: var(--primary-font-medium);
}

.input-field-style,
.input-field-style:hover,
.input-field-style:focus-visible,
.input-field-style:focus {
  border: none !important;
  margin-left: 5px;
  background: transparent;
  outline: none;
  border-bottom: 2px solid var(--primary-blue-color) !important;
}

.marketing-section {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  background: #eef9ff;
  font-size: 18px;
  font-family: var(--secondary-font-regular);
}

.primary-red-color {
  color: var(--primary-color);
}

.red_color {
  color: var(--red-color) !important;
}

.border_red_color {
  border: 1px solid var(--red-color) !important;
}

.border_blue_color {
  border: 1px solid var(--primary-blue-color) !important;
}

.primary-ml-text {
  font-family: var(--primary-font-medium);
  font-size: 27px;
}

#space-to-list .modal-content {
  min-width: 540px;
}

.ml-listing-section {
  padding: 35px 0;
  background: hsla(0, 4.9%, 88%, 0.5294117647058824);
}

.notify-popup {
  color: #000;
}

.ml-listing-section div.headd {
  padding: 0 2rem;
  font-family: var(--primary-font-medium);
}

.ml-listing-section div.headd h3,
.ml-listing-section div.headd button {
  font-weight: 500;
  font-family: var(--tertiary-font-semi-bold);
}

.font-family-primary {
  font-family: var(--primary-font-demi-bold);
}

.ml-view-btn {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px 20px;
  color: var(--primary-color);
  cursor: pointer;
}

.primary-blue-color {
  color: var(--primary-blue-color);
}

.delete-btn-table {
  background: var(--primary-color);
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  margin-right: 5px;
}

.coupon-form label.my-label {
  font-weight: 700;
}

.form-control:disabled,
.form-control[readonly] {
  background: #5a585840 !important;
}

.active-discount-type {
  background: var(--primary-blue-color);
  color: var(--white-color) !important;
  border: 1px solid var(--primary-blue-color);
  padding: 5px;
}

.in-active-discount-type {
  border: 1px solid var(--primary-blue-color);
  padding: 5px;
}

.check-box-limit {
  height: calc(1.25em + 0.25rem + 1px);
  width: 8%;
  margin-left: 5px;
}

span.d-flex label.text-style {
  margin: auto 0;
}

.btn-action {
  margin: auto;
  text-align: center;
}

.edit-btn-table {
  background: var(--primary-blue-color);
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
}

.view-btn-table {
  background: var(--primary-blue-color);
  color: white !important;
  padding: 5px 8px;
  border-radius: 5px;
  margin-left: 5px;
}

.redhanded-slogan-footer {
  text-align: center;
  margin: 2rem;
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: none;
  font-family: var(--primary-font-regular);
}

.hr-style {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid var(--primary-blue-color);
}

.subs-detail-parent {
  background: var(--whie-color);
}

.plan-properties span.d-flex {
  margin-bottom: 1rem;
}

.add-coupon-btn {
  position: absolute;
  right: 10px;
  top: 20px;
}

.add-coupon-btn button {
  color: #fff;
  background: var(--primary-color);
  border-radius: 5px;
  padding: 10px;
}

.show-on-mob-msg {
  display: none !important;
}

.tab-class {
  padding: 15px;
  border: 2px solid var(--primary-blue-color);
  border-radius: 15px;
}

.plan-properties .margin-for-icon {
  color: var(--primary-blue-color);
}

.coupon-body {
  padding: 25px;
}

.total-and-coupon {
  text-align: left;
}

.price-coupon-side {
  text-align: right;
  width: 85px;
  border-bottom: grey 1px solid;
}

.coupon-apply-amount {
  margin-top: 10px;
  float: right;
  font-size: 16px;
  font-weight: 500;
}

.pd-bt-5 {
  padding-bottom: 5px !important;
}

.coupon-label.input-label-form {
  margin-bottom: 0.01rem;
  color: #000;
}

.discount-coupon-btn {
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 5px;
  font-family: var(--secondary-font-medium);
  padding: 7px 10px;
}

p.active-tab-icon.tab-class {
  background: #255cb7;
  color: #fff;
  border-radius: 15px;
}

.box-shadow-sub-plan {
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.active-tab-icon.tab-class .icon-plan {
  color: #fff;
}

.generic-heading.redhanded-thankyou-msg {
  color: #073252 !important;
  text-transform: none;
  font-weight: 600 !important;
  font-family: "AvenirNextMedium";
  font-size: 30px !important;
}

.redhanded-slogan {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  font-family: "AvenirNextMedium";
  justify-content: center;
  display: flex;
  color: #003975 !important;
  font-weight: bold !important;
  font-size: 30px !important;
  letter-spacing: 1px !important;
}

.video-btn-color {
  background: #007bff !important;
  height: 100px !important;
  width: 100px !important;
}

.play-parent-btn {
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
  border-radius: 64px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-parent-btn .play-me-btn {
  border-style: solid;
  border-width: 16px 0px 16px 26px;
  border-color: transparent transparent transparent white;
  margin-left: 7px;
}

.moda-headr {
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.play-parent-btn .play-me-btn {
  border-style: solid;
  border-width: 16px 0px 16px 26px;
  border-color: transparent transparent transparent white;
  margin-left: 7px;
}

.testimonial-play-btn {
  position: absolute;
  top: 100px;
  left: 43%;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
  border-radius: 64px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-play-btn .play-me-btn {
  border-style: solid;
  border-width: 16px 0px 16px 26px;
  border-color: transparent transparent transparent var(--red-icon-color);
  margin-left: 7px;
}

.pulsee {
  border-radius: 50%;
  /* background: var(--red-icon-color); */
  cursor: pointer;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  animation: pulsee 1.5s infinite;
}

.pulse {
  border-radius: 50%;
  /* background: var(--red-icon-color); */
  cursor: pointer;
  box-shadow: 0 0 0 rgb(172 27 27 / 67%);
  animation: pulse 1.5s infinite;
}

.pulsee:hover {
  animation: none;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulsee {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulsee {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(255, 255, 255);
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(172, 27, 27, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(172, 27, 27, 0.4);
    box-shadow: 0 0 0 0 rgba(172, 27, 27, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.signup-wizard .form-bside-checkout .total span.discount-label-heading {
  font-size: 15px !important;
  font-weight: 100 !important;
  color: gray !important;
  font-family: "MontserratRegular" !important;
}

.p-upg-plan {
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px !important;
}

.verify-modal p {
  font-size: 17px;
  padding-bottom: 0.5rem;
  font-family: var(--primary-font-regular);
  color: var(--border-primary-color);
}

.verify-modal h2 {
  font-size: 25px;
  padding-bottom: 0.5rem;
  font-family: var(--primary-font-regular);
}

.verify-modal Button,
.verify-modal Button {
  background-color: rgba(8, 56, 92, 255);
  width: 35%;
  padding: 10px 15px 10px 15px;
  color: white;
  font-size: 20px;
}

.plan-pric-mob-subs-linethrough {
  font-size: 20px !important;
}

.plan-pric-mob-subs {
  font-size: 25px !important;
  font-weight: 800;
}

.roleid Button {
  background: none;
  border: none;
  box-shadow: none;
}

.roleid Button:focus {
  background: none;
  border: none;
  box-shadow: none;
}

.roleid Button:hover {
  background: none;
  border: none;
  box-shadow: none;
}

.roleid Button:focus {
  background: none;
  border: none;
  box-shadow: none;
}

.btn-blue-color.disable-me {
  cursor: no-drop;
  background: grey !important;
}

.roleid .btn-primary:not(:disabled):not(.disabled):active {
  background: none;
  border: none;
  box-shadow: none;
}

.pricing {
  position: relative;
  display: flex;
  justify-content: center;
}

.pricing .nav {
  position: absolute;
  bottom: 1rem;
  display: flex;
  background: #08395d;
  color: #ffffff;
  width: 361px;
  height: 60px;
  /* justify-content: center; */
  border-radius: 50px;
  align-items: center;
}

.pricing .nav-link.active {
  background-color: var(--l-blue) !important;
}

.pricing .nav-link {
  margin-left: 15px;
  margin-right: 15px;
  width: 150px;
  border-radius: 50px;
  background: none;
  display: flex;
  color: white !important;
  justify-content: center;
}

h4.custom-home-heading-size,
h3.custom-home-heading-size {
  font-size: 20px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}

.modal-header-style {
  width: 100% !important;
  margin: auto !important;
  text-align: center !important;
  border: none;
}

.modal-dialog-scrollable .modal-content {
  height: 70%;
}

.no-border {
  border: none !important;
}

.black {
  color: #000 !important;
}

.modal-open .backdropClassName.modal-backdrop.show {
  opacity: 0.85 !important;
}

.primary-blue-color {
  color: var(--primary-blue-color) !important;
}

.primary-grey-color {
  color: #333 !important;
}

.primary-border-grey {
  border: 1px solid #333 !important;
}

.primary-blue-bg-color {
  background: var(--primary-blue-color) !important;
}

.background-color {
  background: var(--background-color) !important;
}

h1.font-size-3 {
  font-size: 2.35rem !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.color-black {
  color: #000 !important;
}

.sec-top-bar {
  height: 40px;
  width: 100%;
  background: var(--primary-color);
  display: flex;
  border-bottom: 1px solid;
  color: var(--white-color);
  /* border-top: 1px solid; */
  border-color: #08395d30;
  box-shadow: 0 4px 2px -2px #cecccc87;
}

.upgrade-me-btn-on-bar {
  background: var(--primary-blue-color);
  color: var(--white-color);
  text-transform: uppercase;
  border-radius: 5px;
  padding: 4px 8px;
}

.menu-item-nav-div {
  display: flex;
  padding: 0 10px;
}

.menu-item-nav-div a {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-weight: 500;
  color: #fff !important;
  font-size: 17px;
  margin: auto 0;
  /* text-transform: uppercase; */
}

.input-login-parent {
  display: flex;
  border: 1px solid #58585873;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  height: calc(3rem + 1px);
  margin-bottom: 15px;
  background-color: #dfe8ff;
}

.btn-social-size {
  background: transparent;
  font-size: 2.25rem;
}

/* #login-modal .modal-content {
  border-radius: 20px;
} */

.login-signup-btn-style,
.login-signup-btn-style:hover {
  height: 60px;
  border-radius: 10px !important;
  font-size: 20px;
  background: #1877f2 !important;
  font-weight: bold !important;
  letter-spacing: 4px;
  color: #fff;
}

.login-icon svg {
  color: var(--red-icon-color);
  font-size: 20px;
}

.login-visible-invisible-icon {
  position: absolute;
  right: 25px;
  top: 10px;
}

.login-eye {
position: absolute;
right: 10px;
top: 5px;
}

.no-border.input-no-border-radius.form-control {
  height: calc(1.85em + 1rem + 0px);
  background: #f0f4ff;
}

.input-no-border-radius {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.login-icon {
  background: #dfe8ff;
  padding: 5px;
  color: #58a4d8;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 70px;
}

.updrage-div {
  display: flex;
  justify-content: flex-end;
  margin: auto;
  padding: 0 30px;
}

.generic-heading {
  font-family: var(--tertiary-font-regular);
  font-weight: bold !important;
  font-size: 30px !important;
  letter-spacing: 1px !important;
  color: #000 !important;
  /* text-transform: uppercase; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.border-0 {
  border: none !important;
  border: 0 !important;
}

.redhanded-map-style-1 {
  height: 350px !important;
  width: 85% !important;
}

.free-invite-btn {
  height: 44px;
  width: 120px;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
  margin: auto 0;
}

/* .plan-name-mob-subs h1 {
color: var(--primary-blue-color);
} */

.generic-heading span {
  background: var(--primary-color);
  /* height: 125px; */
  width: 15px;
  margin: 5px 15px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.text-cen {
  font-size: 18px;
  text-align: center;
}

.show-mw-onMob {
  display: none;
}

.btn-blue-color {
  font-weight: 600;
  letter-spacing: 1px;
  border: 2px solid #255cb7;
  color: #fff !important;
  background: #255cb7 !important;
}

.btn-blue-color.btn-blue-color-dark {
  background-color: var(--d-blue) !important;
  border: 2px solid var(--d-blue) !important;
}

.btn-blue-color.invite-btn {
  margin-bottom: 2px;
}

.rent-spree-logo-btn {
  height: 25px;
}

.btn-blue-border-1 {
  font-weight: 600;
  letter-spacing: 1px;
  /* height: auto !important; */
  color: #255cb7 !important;
  border: 2px solid #255cb7 !important;
  background: #fff !important;
}

.btn-red-border-1 {
  font-weight: 600;
  letter-spacing: 1px;
  /* height: auto !important; */
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  background: #fff !important;
}

.btn-red-border-1.selected-plan {
  color: var(--primary-color) !important;
  background: var(--white-color) !important;
  border: 2px solid var(--primary-color) !important;
}

.btn-space-link {
  font-weight: 600;
  letter-spacing: 1px;
  /* height: auto !important; */
  padding: 10px 20px;
  color: #255cb7 !important;
  border: 2px solid #255cb7 !important;
  /* background: #fff !important; */
}

.btn-blue-border {
  font-weight: 600;
  letter-spacing: 1px;
  /* height: auto !important; */
  color: #255cb7 !important;
  border: 3px solid #255cb7 !important;
  background: #fff !important;
}

.recommended-cards-modify .slick-list {
  height: 500px !important;
}

.sec-address-listing {
  font-size: 16px !important;
  margin-bottom: 10px !important;
  min-height: 1rem;
}

.primarry-address-listing {
  font-weight: bolder !important;
  color: black !important;
  /* / font-size: 1.18rem !important;  */
  letter-spacing: 0;
  font-size: 1.4vw !important;
}

.min-height-card {
  min-height: 4rem;
}

.price-listing-slider,
.price-listing-slider1 {
  color: #255cb7 !important;
  font-size: 15px;
}

.carosel-card-height-home.custom-height {
  height: 205px !important;
}

.error-message-generic p {
  color: var(--primary-color) !important;
  word-break: break-word;
  text-transform: capitalize;
  white-space: break-spaces;
}

.hearder-plan-card {
  padding: 1.4rem 1.7rem;
  border: 0 solid #dfe3e7;
  background-color: transparent;
  font-weight: 500;
  font-size: 2.2rem;
  align-items: center;
}

.admin-edit-icon {
  float: right;
  font-size: 1.5rem;
}

.break-word {
  word-break: break-word;
}

.custom-stepper div {
  font-family: var(--primary-font-regular);
}

.tenant-profile-industry-icon {
  width: 150px;
  height: 150px;
}

.verified-text {
  color: black !important;
  font-size: 18px !important;
  font-family: var(--primary-font-medium) !important;
}

.no-border {
  border: none !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.negotiation-card {
  width: 100%;
}

.question-list {
  font-family: var(--tertiary-font-semi-bold);
  font-size: 20px;
  width: 50%;
  white-space: break-spaces;
  word-break: break-word;
}

.space-comparison-heading {
  text-align: center;
  text-transform: uppercase;
}

.comparison-detail {
  font-family: "Noto Sans JP";
  margin: 15px 0;
  white-space: break-spaces;
  word-break: break-word;
}

.grid-wraper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-around;
}

.dot-response {
  background: var(--red-icon-color);
  width: 10px;
  z-index: 544;
  position: absolute;
  height: 10px;
  border-radius: 50%;
  right: 0;
}

.compare-img-parent {
  position: relative;
  text-align: center;
  height: 150px;
  margin-bottom: 2px;
  overflow: hidden;
}

.cross-compare {
  position: absolute;
  right: 10px;
  z-index: 150;
}

.compare-img-parent img {
  height: 100%;
}

.parent-div-compare {
  list-style: none;
  width: 20%;
  border: 1px solid #ececec;
  border-radius: 4px;
  position: relative;
}

.compare-body {
  display: flex;
  width: 100%;
  align-items: center;
  height: 196px;
  position: relative;
  cursor: pointer;
}

.add-question-parent {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.popup-header-cls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.add-question {
  background: var(--red-color);
  color: var(--white-color);
  border-radius: 25px;
  margin-top: 18px;
  padding: 10px;
  font-family: var(--primary-font-medium);
}

.bar {
  width: 10%;
  border-radius: 35px;
  height: 10px;
  margin: auto;
}

.bar.grey {
  background: gray;
}

.bar.success {
  background: #11a71c;
}

.bar.warning {
  background: #f59e1d;
}

h4.popup-header {
  margin-left: 0 !important;
  font-size: 25px !important;
  text-transform: uppercase;
}

.primary-font-regular {
  font-family: var(--primary-font-regular) !important;
}

.primary-font-medium {
  font-family: var(--primary-font-medium) !important;
}

.no-data {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.no-data-button,
.no-data-button span.new-listing {
  padding: 15px 25px;
  background: var(--primary-blue-color);
  color: white !important;
  border-radius: 5px;
}

.main-donate-sec {
  padding: 3rem;
}

.side-by-side-data {
  flex-direction: row;
  justify-content: space-between;
}

.edit-to-save {
  color: var(--primary-blue-color);
  text-decoration: underline;
  font-family: var(--primary-font-regular);
  font-size: 20px;
  text-transform: uppercase;
  background: none;
}

.our-partners-div {
  justify-content: space-around;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  border-bottom: 1px solid;
  color: var(--primary-color) !important;
}

.center-button {
  justify-content: center;
  display: flex;
}

.our-partners-div h3 {
  margin: auto 0;
  font-family: var(--primary-font-medium);
}

.description-text-partners {
  font-family: var(--primary-font-regular);
  padding: 25px;
  border-radius: 25px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
}

.partners-div {
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
  margin: 3rem;
  padding: 2rem;
  background: #fafafa;
}

.donate-now-heading {
  /* color: var(--red-icon-color); */
  font-family: var(--primary-font-demi-bold);
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.doonate-now-ul li {
  color: var(--primary-blue-color);
  text-align: left;
}

.donate-btn {
  background: var(--red-icon-color);
  padding: 15px 30px;
  color: var(--white-color);
  border-radius: 4px;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
  font-size: 17px;
  margin-top: 1rem;
}

.partner-description {
  font-size: 18px;
  font-family: "AvenirNextRegular";
  word-break: break-word;
  white-space: break-spaces;
}

.form-bside-checkout {
  margin: 4rem !important;
}

.connection-part .connection-action a.dropdown-item:hover,
.connection-part .connection-action a.dropdown-item:active,
.connection-part .connection-action a.dropdown-item:focus {
  background: transparent;
}

.mar-auto-ver {
  margin: auto 0 !important;
}

.your-reason-part {
  margin: auto 10px;
  margin-top: 5px;
  padding: 15px;
  white-space: break-spaces;
  font-family: var(--secondary-font-regular);
  font-style: italic;
  border: 1px solid var(--border-primary-color);
  border-radius: 4px;
}

.report-span.mt-1 {
  font-family: var(--secondary-font-regular) !important;
  font-size: 17px !important;
  color: var(--color-text-grey-tertiary) !important;
  margin-top: 1rem !important;
}

.form-bside-checkout p {
  font-size: 18px !important;
}

.border-btn {
  border: 2px solid #255cb7 !important;
}

.signup-wizard .form-bside-checkout .plan .btn-1,
.signup-wizard .form-bside-checkout .plan span,
.signup-wizard .form-bside-checkout .total span {
  font-size: 22px !important;
}

.description-text-partners h3,
.description-text-partners h2 {
  color: var(--primary-blue-color);
  display: flex;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
  font-size: 1.75rem;
}

.serch-container-z-index {
  z-index: 555;
}

.partners-heading {
  /* color: var(--red-icon-color); */
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.break-me-all {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

#more-drop .dropdown-toggle::after {
  display: none;
}

.block-btn.btn-blue-border-1 {
  color: #fff !important;
  border: 2px solid #8a2d2d !important;
  background: #8a2d2d !important;
}

.partner-donate-btn {
  background: var(--red-icon-color);
  padding: 10px;
  float: right;
  color: var(--white-color) !important;
  border-radius: 4px;
  margin-top: 5px;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
  font-size: 17px;
}

.doonate-now-ul li p {
  color: var(--color-text-primary);
}

.doonate-now-ul {
  text-align: center;
  width: fit-content;
  margin: auto;
}

.donate-now-text {
  font-family: var(--primary-font-medium);
  color: var(--color-text-primary);
  font-size: 25px;
}

#responsive-navbar-nav input.search-bar-nav {
  height: 45px;
}

.text-uppercase {
  text-transform: uppercase;
}

.parent-filter .search-filter-part {
  background: var(--primary-blue-color);
  padding: 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.search-filter-part input.search-bar-nav {
  height: 45px;
}

.view-properties-body .view-properties-body-part .location-list {
  overflow-y: scroll;
  height: 700px;
}

.view-properties-body .view-properties-body-part .location-list .location-part {
  background: var(--white-color);
  border: 1px solid #d1d1d1;
  position: relative;
}

.view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  img {
  max-height: 132px !important;
}

.view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-name {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  margin-bottom: 0px;
  font-size: 20px !important;
  margin-top: 15px;
  padding-right: 20px;
  word-break: break-word;
  white-space: break-spaces;
}

.view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-address {
  color: var(--navbar-item-color);
  font-family: var(--primary-font-regular);
  font-size: 17px;
}

.view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-area {
  font-family: var(--primary-font-regular);
}

.view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-area
  strong {
  font-family: var(--primary-font-demi-bold);
}

.parent-filter .search-filter-part div {
  margin-top: 2px;
}

.view-properties-body .parent-filter .search-filter-part div {
  padding: 0 5px;
  margin: 2px 0;
}

.parent-filter {
  padding: 2rem;
}

.primary-font-demi-bold {
  font-family: var(--primary-font-demi-bold) !important;
}

.primary-font-bold {
  font-family: var(--primary-font-bold) !important;
}

.review-heading-tenant {
  margin-left: 1.35rem;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 27px !important;
  letter-spacing: 0;
  line-height: 34px;
  text-transform: uppercase;
}

button {
  border: none;
}

.margin-auto-imp {
  margin: auto !important;
}

.invitation-back {
  padding-top: 10px;
}

.map-part {
  height: 250px;
}

.map-part-map-part {
  height: 500px;
  margin-top: 1rem;
}

.heading-text {
  width: 100%;
  color: var(--color-text-grey-primary);
  font-size: 36px;
  padding-top: 84px;
  letter-spacing: 0;
  text-align: center !important;
}

.tenant-body-part .connection-part .tenant-listing-span {
  padding-left: 10px;
}

.body-color {
  background-color: var(--background-color);
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 103px;
}

.main-row {
  padding-top: 52px;
  background-color: white;
}

.modal-body-popup {
  padding: 3rem;
}

.modal-body-popup-1 {
  padding: 0.5rem;
}

.modal-body-popup h4.heading-popup-main,
.modal-body-popup-1 h4.heading-popup-main {
  color: var(--color-text-primary);
  text-align: center;
}

.pop-up-property-detaile-btn.btn-blue-color.yes-no-btn,
.pop-up-property-detaile-btn.btn-blue-border-1.yes-no-btn {
  padding: 10px 25px;
}

.pop-up-dashboard-btn {
  color: var(--white-color);
  border-radius: 3px;
  padding: 10px 25px;
  background: var(--red-color);
  text-transform: uppercase;
}

.heart-row {
  justify-content: flex-end;
  color: #797979;
  font-family: var(--primary-font-regular);
  font-size: 16px;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-top: 29px;
  margin-right: 0px;
}

.heart {
  margin-right: 8px;
}

.input-value-to-copy {
  width: 100%;
  border: none;
}

.record-deal-div-parent {
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100%;
}

input.input-value-to-copy:focus-visible {
  outline: none;
}

.copy-btn {
  float: right;
  background: var(--red-color);
  padding: 8px 10px;
  border-radius: 5px;
  color: var(--white);
}

.row-reverse {
  flex-direction: row-reverse;
}

.first-field {
  padding-top: 35px !important;
}

.msg-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding-top: 4px;
  font-size: 15;
  font-family: var(--primary-font-demi-bold);
  background: var(--color-background-grey-scondary);
  margin-top: 20px;
}

.msg-icon-1 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding-top: 4px;
  font-size: 15;
  font-family: var(--primary-font-demi-bold);
  margin-top: 12px;
}

.message-card-active .msg-icon {
  background-color: var(--red-color);
}

.message-card-active {
  padding: 0px 5px;
  background-color: rgba(216, 216, 216, 0.2);
}

.filed-search-height {
  height: 45px;
}

.first-card {
  border-bottom: 1px solid var(--black-color);
}

.cd {
  border-bottom: 1px solid var(--black-color);
}

.main-card {
  background-color: white;
  padding: 1rem 2rem 1rem 2rem;
  border-right: 1px solid var(--black-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

p.favourite {
  color: var(--red-color);
  font-family: var(--primary-font-regular);
}

p.not-favourite {
  font-family: var(--primary-font-regular);
  color: #333;
}

.text-1 {
  color: var(--black-color);
  font-family: var(--primary-font-medium);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 20px;
}

.message-card-active .text-1 {
  color: var(--primary-blue-color);
}

/* .text-2 {
color: var(--navbar-item-color);
font-family: var(--primary-font-regular);
font-size: 16px;
letter-spacing: 0;
line-height: 22px;
} */

.date {
  color: var(--color-background-grey-scondary);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
  padding-top: 10px;
}

.heart-2 {
  color: var(--red-color);
  text-align: right;
  font-size: 30px;
}

.icon-1 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--color-background-grey-scondary);
  padding-top: 2px;
  margin-top: 20px;
}

.icon-2 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding-top: 2px;
  margin-top: 20px;
}

.text-3 {
  color: var(--black-color);
  font-family: var(--primary-font-regular);
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 20px;
  position: relative;
  right: 23px;
}

.text-2 {
  color: var(--navbar-item-color);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.date {
  color: var(--color-background-grey-scondary);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
  padding-top: 10px;
}

.heart-2 {
  color: var(--red-color);
  text-align: right;
  font-size: 30px;
}

.avenu-1 {
  color: var(--primary-blue-color);
  font-family: var(--primary-font-regular);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  font-weight: 600;
}

.avenu-2 {
  color: var(--color-text-primary);
  font-family: var(--primary-font-medium);
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
}

.message-filter {
  background: var(--white-color);
  border: 1px solid #a29999;
  border-radius: 4px;
}

.message-filter button.drop-down-custom {
  width: 100%;
  padding-right: 0;
  min-height: 45px;
}

.message-filter .drop-down-messages-data p {
  font-family: var(--secondary-font-regular) !important;
  /* color: var(--primary-blue-color) !important; */
}

.message-filter .selected-drop-down-field {
  color: var(--black-color);
  font-family: var(--primary-font-regular);
  word-break: break-word;
  font-weight: bold;
  white-space: break-spaces;
}

.message-filter .dropdown-menu {
  width: 100%;
}

.no-message-selected {
  height: 100%;
}

.no-message-selected span {
  margin: auto;
}

.message-filter .connection-options p {
  margin: 0;
  text-transform: uppercase;
  color: var(--black-color);
  font-family: var(--primary-font-regular);
}

.avenu-3 {
  color: #333 !important;
  font-family: var(--primary-font-regular);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 5px;
}

.heart-4 {
  color: var(--red-color);
  text-align: right;
  font-size: 40px;
}

p.date-2 {
  position: relative;
}

p.date-2::before {
  content: "";
  top: 9px;
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--color-background-grey-scondary);
  right: 60%;
}

p.date-2::after {
  content: "";
  top: 9px;
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--color-background-grey-scondary);
  left: 60%;
}

.plan-body-card {
  padding: 2rem;
  text-align: center;
}

.req-picture {
  width: 250px !important;
  margin: auto !important;
}

.homepage-video-border {
  border: 2px solid;
  border-radius: 4px;
  border-color: var(--primary-color);
}

.custom-size-img-pro {
  height: 150px !important;
  width: 150px !important;
}

.plan-body-card span,
.plan-body-card div {
  font-size: 25px;
}

.update-plan-btn {
  background: var(--red-color);
  padding: 5px 15px;
  color: white;
  border-radius: 5px;
}

.date-2 {
  height: 19px;
  width: 100%;
  color: var(--color-background-grey-scondary);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.tk-1 {
  color: var(--black-color);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 5px;
}

.tk-2 {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

/* .send-msg{
text-align: left;
} */

.new-text {
  position: relative;
  top: 9px;
  right: 26px;
}

.time-2 {
  color: var(--color-background-grey-scondary);
  font-family: var(--primary-font-regular);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

input#exampleForm\.ControlInput1 {
  height: 110px;
}

.paper {
  color: var(--color-background-grey-scondary);
  font-size: 23px;
  padding-left: 2rem;
}

.arrow {
  color: var(--color-background-grey-scondary);
  font-size: 23px;
  padding-left: 1rem;
}

.input-label-form {
  font-family: var(--secondary-font-regular);
  color: var(--color-text-grey-secondary);
}

.subs-title- {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  font-size: 1.3rem;
}

.property-detail-parent {
  margin: 0 30px;
}

.property-detail-header {
  padding: 0px 50px;
}

.property-detail-header .property-detail-heading {
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-primary);
  text-transform: uppercase;
}

.property-detail-header .sub-heading {
  font-family: var(--primary-font-demi-bold);
  color: #333;
  text-transform: uppercase;
}

.property-detail-header .sub-heading .remainig-days {
  text-transform: none;
  margin-left: 2rem;
  color: var(--primary-blue-color);
  font-family: var(--primary-font-demi-bold);
}

.property-detail-msg {
  display: flex;
  justify-content: right;
  padding: 0px 50px;
}

.inline-style {
  flex-direction: row;
  justify-content: space-between;
}

.detail-info-contact {
  margin-top: 25px;
  font-family: var(--primary-font-demi-bold);
}

.listing-heding {
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-padding-Vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.custom-image-modal-listing {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.baack-drop {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1 !important;
  z-index: 10;
}

.fade.modal.show {
  overflow: hidden;
  padding: 0 !important;
}

.fade.modal.show #img-popup {
  height: 100%;
  margin: 0;
  display: table;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  background: #000000c2;
  color: #fff !important;
  max-width: 100% !important;
}

#img-popup.modal-lg,
#img-popup.modal-xl {
  max-width: 90% !important;
}

.notify-img-parent {
  position: relative;
  border: 2px solid #255cb7;
  border: 2px solid var(--primary-blue-color);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-bottom: 2px;
  overflow: hidden;
  float: left;
  margin-right: 10px;
}

.notify-detail {
  /* margin-left: 16px; */
  width: 360px;
}

#img-popup .modal-header {
  border: none !important;
}

#img-popup .modal-content {
  background: transparent !important;
  border: none !important;
  /* position: fixed !important; */
}

.img-pop-up-data {
  /* text-align: center; */
  overflow: hidden;
  text-align: center;
  /* height: 650px;
width: 650px;
margin: auto !important; */
}

.next-role:disabled {
  background: #9c454c;
}

.test-width {
  padding-bottom: 45px;
}

.slick-active {
  padding: 0 5px;
  padding-bottom: 50px;
}

.home-listing-slider.image-slider-detail .slick-active {
  padding: 0 1px;
  padding-bottom: 50px;
}

.recommended-cards-modify .slick-active {
  padding: 0 10px !important;
}

.missing-requirements {
  font-size: 1.5rem;
  text-align: center;
}

.imgs-pop-up-data {
  /* max-width: 100vh; */
  /* width: 580px; */
  /* height: auto; */
  object-fit: contain;
  text-align: center;
  height: 580px;
}

.floor-plan-width {
  width: 250px;
  height: 250px;
  overflow: hidden;
}

.detail-home-listing .slick-track {
  display: flex;
}

.cursor {
  cursor: pointer;
}

.page-height {
  min-height: 80vh;
  /* min-height: 100vh; */
}

.justify-center {
  justify-content: center;
}

a,
a:hover,
a:focus,
a:visited {
  color: inherit;
  text-decoration: none !important;
}

.parent-filter-tenant-listing {
  padding: 2rem 4rem;
}

.modal-body-popup-no-match {
  padding: 1rem;
}

.no-match-msg {
  font-size: 20px;
  word-break: break-all;
  font-weight: 700;
  color: #333;
  /* margin: auto; */
  text-align: center;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.margin-auto {
  margin: auto;
}

.font-800 {
  font-size: 48px;
  line-height: 72px;
}

.font-700 {
  font-size: 32px;
  line-height: 48px;
}

.font-600 {
  font-size: 24px;
  line-height: 36px;
}

.font-500 {
  font-size: 22px;
  line-height: 28px;
}

.font-400 {
  font-size: 16px;
  line-height: 20px;
}

.font-300 {
  font-size: 14px;
  line-height: 16px;
}

.font-200 {
  font-size: 12px;
  line-height: 16px;
}

.font-100 {
  font-size: 10px;
  line-height: 12px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-1-5 {
  margin-bottom: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.dashboard-name-scroller {
  height: 135px;
  overflow-y: scroll;
}

.error-message-generic {
  text-align: center;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 17px;
}

.profile-image-section {
  margin-top: 70px;
}

.img-sec {
  position: relative;
  border: 2px var(--primary-color) solid;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  padding: 0;
  margin-bottom: 2px;
  background: transparent;
}

.for-get-me-btn {
  float: right;
  margin-right: 1px;
}

.skip-btn {
  float: right;
  font-size: 18px;
  position: absolute;
  right: 30px;
  bottom: 65px;
  color: var(--primary-blue-color);
  text-decoration: underline;
  cursor: pointer;
}

.input-edit-icon {
  margin: auto;
  background: var(--primary-blue-color);
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.check-edit-icon {
  margin: auto;
  background: #25b401;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.disclaimer {
  font-size: 0.93rem;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
}

.disclaimer a {
  color: black;
  font-weight: 600;
  text-decoration: underline !important;
}

.img-sec-profile-parent {
  position: relative;
  /* border: 2px solid #a80000; */
  border: 2px solid var(--primary-blue-color);
  border-radius: 50%;
  margin-bottom: 2px;
  overflow: hidden;
}

.color-class {
  color: #a80000;
}

.img-sec img {
  padding: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.custom-size {
  width: 150px !important;
  height: 150px !important;
}

.img-sec .form-file-input {
  position: absolute;
  top: 0%;
  height: 100%;
  border-radius: 50%;
  z-index: 10000;
  left: 0;
}

.right-0 {
  right: 0;
}

.add-pictures {
  margin: 30% 0;
  text-align: center;
}

.img-sec .form-file-input input {
  height: 100%;
}

.user-profile-section {
  background: var(--background-color);
}

.profile-image-section .profile-section {
  margin-left: 70px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b1acac;
}

.profile-section .user-name h3 {
  text-transform: uppercase;
  font-family: var(--primary-font-bold);
  font-size: 36px;
  color: var(--color-text-grey-primary);
}

.profile-section .user-name span {
  text-transform: capitalize;
  font-size: 30px;
  font-weight: 500;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
}

.profile-section .user-name {
  margin: auto;
  margin-left: 40px;
}

.form-control:focus {
  box-shadow: none;
}

.form-control {
  font-family: var(--secondary-font-regular);
}

.error-message-field-generic {
  color: var(--primary-color);
  font-size: 12px;
  /* text-transform: capitalize; */
}

.form-a span.bio-text {
  font-size: 14px;
  color: #605e5ee3;
  font-weight: 400;
}

.form-a span.subscription-detail {
  color: #605e5ee3;
  text-transform: none;
}

.vertical-al {
  vertical-align: baseline !important;
}

.selected-plan {
  color: var(--primary-blue-color) !important;
  background: var(--white-color) !important;
  border: 1px solid var(--primary-blue-color) !important;
}

.form-a label {
  color: #605e5ee3;
  font-weight: 500;
}

span.read-only-fields {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: var(--white-color);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*Nav Bar*/

.navbar {
  background: #fff;
  box-shadow: 0 4px 2px -2px #cecccc87;
}

.navbar-header-title-parent {
  margin: 0 auto;
}

.navbar-header-title {
  color: var(--primary-color);
  text-transform: uppercase;
  font-family: var(--tertiary-font-semi-bold);
  /* font-size: 35px; */
  font-size: 50px;
}

.sign-me-out {
  color: var(--red-icon-color);
  font-weight: 800;
  /* font-size: 20px; */
  text-align: center;
}

/* .dropdown-item :hover {
text-decoration: underline;
} */
.dropdown_profile .dropdown-item:hover {
  background-color: #cccccc54 !important;
}

.dropdown_profile .dropdown-item.item_no_hover:hover {
  background-color: transparent !important;
}

.dropdown_profile .dropdown-item {
  margin-bottom: 5px;
}

.dropdown_profile .dropdown-item .sign-me-out,
.dropdown_profile .dropdown-item .nav-link {
  padding: 0rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  text-transform: capitalize;
}

.nav-bar-link-part .navbar-nav {
  margin: auto;
  /* margin-right: 0px !important; */
}

.search-bar-nav {
  width: 100% !important;
  border-color: #a29999;
}

.navbar-dropdown .dropdown-item:active {
  background-color: white;
  color: #212529;
  text-decoration: none !important;
}

.navbar-dropdown .dropdown-item:hover {
  background-color: white;
  color: #212529;
  text-decoration: none !important;
}

.search-icon {
  position: absolute;
  right: 30px;
}

.login-nav-link {
  margin: 0px 10px;
}

.nav-link {
  font-weight: 500;
  color: #000 !important;
  /* font-size: 17px; */
  font-size: 12px;
  text-transform: uppercase;
}

.nav-link-login {
  font-weight: 500;
  color: #000 !important;
  font-size: 17px;
}

.navbar-profile Button {
  padding: 10px 15px 10px 15px;
  border: none;
  width: 100%;
}

.upgrade-button,
.upgrade-button:hover {
  background-color: rgba(169, 21, 33, 255);
}

.gold-button,
.gold-button:hover {
  background-color: rgba(185, 166, 0, 255);
}

.diamond-button,
.diamond-button:hover {
  background-color: rgba(8, 56, 92, 255);
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.login-signup-btn button {
  background: var(--primary-color);
  color: var(--white-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.header-title-div {
  margin: 0 auto;
}

.navbar-toggler {
  z-index: 100000;
}

/*End Of NavBar*/

/*Registration*/

.registration-div div.how-it-works {
  background: var(--white-color);
}

.registration-div div.registration-as {
  padding: 35px 0px 0px 0px;
  /* background: #e2dfdf87; */
}

.registration-div div.registration-as h1 {
  color: var(--red-color);
  text-align: center;
  margin: 5rem 0rem 3rem 0rem;
  font-family: var(--primary-font-demi-bold);
}

.registration-div div.registration-as .sign-up-as {
  color: var(--white-color);
  text-align: center;
  margin: 0 auto;
}

.registration-div div.registration-as .landloard {
  background-color: var(--d-blue);
  margin: 0px auto;
  padding: 70px 0px;
  border-radius: 5px;
  line-height: 35px;
  margin-bottom: 15px;
}

.registration-div div.registration-as .landloard .heading-,
.registration-div div.registration-as .tenant .heading- {
  font-family: var(--primary-font-medium);
  color: var(--white-color);
  font-size: 35px;
}

.registration-div div.registration-as .tenant {
  background-color: var(--l-blue);
  margin: 0px auto;
  padding: 70px 0px;
  border-radius: 5px;
  line-height: 35px;
  margin-bottom: 15px;
}

.registration-div div.registration-as .tenant p,
.registration-div div.registration-as .landloard p {
  font-size: 25px;
  padding-bottom: 0.5rem;
  font-family: var(--primary-font-regular);
}

.is-featured {
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  top: 0;
  z-index: 2;
  color: #fff;
  padding: 3px 12px;
  background: var(--primary-color);
  text-transform: uppercase;
}

.is-featured-list {
  font-size: 13px;
  font-weight: 500;
  border-top-left-radius: 8px;
  z-index: 2;
  color: #fff;
  padding: 3px 12px;
  background: var(--primary-color);
  text-transform: uppercase;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  img,
.view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  img {
  min-height: 125px;
  max-height: 125px !important;
  border-bottom-left-radius: 10px;
}

.not-feature-image {
  min-height: 100% !important;
  border-top-left-radius: 10px;
}

.is-featured-detail {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  top: 0;
  color: #fff;
  padding: 5px 15px;
  background: var(--primary-color);
  text-transform: uppercase;
}

.registration-div div.registration-as .tenant p strong,
.registration-div div.registration-as .landloard p strong {
  text-transform: uppercase;
}

.registration-as-btn {
  color: var(--primary-blue-color);
  padding: 10px 25px;
  background: var(--white-color);
  border-radius: 2px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: var(--primary-font-medium);
}

/*End Of Registration*/

/* How It Work*/

div.how-it-works {
  padding: 35px 0px;
  background: #e2dfdf87;
}

div.how-it-works h5 {
  text-transform: uppercase;
  text-align: center;
  font-family: var(--primary-font-medium);
}

div.how-it-works .work-list {
  margin: 30px 0px;
}

div.how-it-works .work-list div {
  text-align: center;
}

div.how-it-works .work-list div p {
  /* color: var(--secondary-color); */
  white-space: break-spaces;
  word-wrap: break-word;
  /* width: 200px; */
  margin: 0 auto;
  font-family: var(--primary-font-medium);
  margin-top: 20px;
  font-size: 30px;
  font-weight: normal;
  text-transform: uppercase;
}

div.how-it-works .work-list div p strong {
  font-size: 40px;
  font-weight: bold;
  color: #255cb7;
}

/*End Of How It Work*/

/*Testimonial*/

.testimonial {
  background-color: var(--d-blue);
}

.height-100vh {
  height: 100vh !important;
}

.height-10vh {
  height: 10vh !important;
}

.height-15vh {
  height: 15vh !important;
}

.height-90vh {
  height: 90vh !important;
}

.height-110vh {
  height: 100vh !important;
}

.testimonial .carousel-user {
  background-color: white;
  border-radius: 8px;
}

.lower-case {
  text-transform: capitalize !important;
}

.signUp-social-medial-link {
  text-decoration: underline;
  margin: auto;
}

.redhanded-logo-homepage {
  margin-right: 4rem;
  float: right;
  height: 220px;
  /* height: 270px;
width: 270px; */
}

div.testimonial h5 {
  /* text-transform: uppercase; */
  text-align: center;
  color: var(--black-color);
  font-size: 2.5rem !important;
  font-weight: 400;
  position: relative;
  /* top: 7rem; */
  position: inherit;
  padding-top: 40px;
  padding-bottom: 25px;
  font-family: var(--primary-font-regular);
}

.testimonial-carousel h4 {
  font-family: var(--primary-font-medium);
  color: var(--whie-color);
}

.custom-img-size-sty {
  height: 50px;
  width: 50px;
}

.testimonial-carousel p {
  font-family: var(--primary-font-regular);
  color: var(--white-color);
  line-height: 1;
}

.testimonial-carousel p.hide-me-onsmall {
  margin-bottom: 0;
}

.carousel-left {
  margin: 50% 0;
}

.dashboard-img-parent {
  position: relative;
  border: 2px solid #a80000;
  border: 2px solid var(--primary-blue-color);
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin-bottom: 2px;
  overflow: hidden;
}

.signage-img-parent {
  position: relative;
  border: 2px solid #a80000;
  border: 2px solid var(--primary-blue-color);
  border-radius: 50%;
  height: 65px;
  width: 65px;
  margin-bottom: 2px;
  overflow: hidden;
}

.pr-im-nav {
  border-radius: 50%;
}

.connection-img-parent {
  position: relative;
  /* border: 2px solid #a80000; */
  border: 2px solid var(--primary-blue-color);
  border-radius: 50%;
  height: 45px;
  width: 45px;
  margin-bottom: 2px;
  overflow: hidden;
}

.mar-top-detail {
  margin-top: 70px !important;
}

.mar-top-detail-login {
  margin-top: 80px !important;
}

.carouserl-img-parent {
  overflow: hidden;
  height: 20vh;
  width: auto;
}

.carousel-listing img {
  width: 100%;
  height: 100%;
}

/* .carouserl-img-parent img {
max-width: 100%;
width: 100%;
} */

.carousel-property-image-parent {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  height: 400px !important;
  position: relative;
  margin: 10px;
}

.my-border-0 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.my-border-2 {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.body-parent-carouset img {
  height: -webkit-fill-available;
}

button.delete-coupon:disabled {
  cursor: no-drop;
  color: #fff !important;
  border-color: #6d81a0 !important;
  background: #6d81a0 !important;
}

.connection-img-parent img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

/* .pagination {
float: right;
} */

.pagination li.break-me {
  border: none;
}

.pagination li.previous,
.pagination li.next {
  display: none;
}

.pagination li.active {
  color: var(--white-color);
  border-color: var(--white-color);
  background-color: var(--red-icon-color);
}

.pagination li {
  border: 1px solid;
  padding: 5px;
  margin-left: 1px;
  border-radius: 5px;
}

.carousel-right {
  margin: 50%;
}

/* .carousel div.carousel-user {
box-shadow: 3px 5px 5px rgba(34, 25, 25, 0.8);
margin: 5px;
} */

.width-auto-withbs {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  width: auto !important;
}

.highcharts-credits {
  display: none !important;
}

.slick-slide {
  margin: 0px;
}

.slick-slider {
  margin: 13px;
}

.carousel-user {
  height: 280px;
  position: relative;
  margin: 0;
}

.recommended-cards-modify .slick-slide {
  margin: 0px !important;
}

.recommended-cards-modify .slick-slider {
  margin: 0px !important;
}

.recommended-cards-modify .carousel-user {
  height: 250px;
  position: relative;
  margin: 10px 0px;
}

.detail-home-listing .carousel-user {
  height: auto !important;
}

.testimonial .carousel-user span {
  margin-left: 5px;
  /* position: absolute;
margin: 5px 0px;
z-index: 5;
top: 50%;
background: #0f3e8c7a;
color: #fff !important; */
}

.testimonial-text {
  padding: 5px 10px;
  margin: 0 !important;
}

.signup-with-social-parent {
  display: flex;
  justify-content: space-between;
}

.RFS-StepContainer span.completed {
  border-color: var(--primary-blue-color) !important;
}

.expire-plan {
  text-align: center;
  color: var(--primary-grey-color);
  font-weight: 700;
  font-size: 17px;
}

.signup-with-social {
  margin: auto 0;
  text-decoration: underline !important;
  color: var(--primary-blue-color) !important;
}

.shortVideo {
  color: var(--red-icon-color);
}

.react-player-width video {
  width: auto !important;
}

.marketing-toggle {
  margin: 0 10px;
  font-family: var(--primary-font-medium);
}

.outline-red-btn {
  background: #fff !important;
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.visible-invisible-icon {
  position: absolute;
  right: 25px;
  top: 38px;
}

.download-brochure {
  font-family: var(--primary-font-medium);
  font-size: 1rem;
  background: var(--primary-blue-color);
  color: var(--white-color);
  border: 2px solid;
  padding: 10px 15px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
}

.pos-relative {
  position: relative;
}

.download-data {
  background: #00000078;
  color: white;
  height: 100%;
  position: absolute;
  /* text-align: center; */
  top: 0;
  width: 100%;
  /* margin: auto; */
  justify-content: center;
  display: flex;
}

.snippet-image {
  height: 100%;
}

.broc-image {
  height: 120px;
  width: 120px;
  padding: 5px;
  margin: auto;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
}

/* .download-my-social-image {
  border: 2px solid var(--primary-blue-color);
} */

.download-my-social-image .download-data {
  display: none;
}

.download-my-social-image:hover .download-data {
  display: flex;
  margin: auto;
  text-align: center;
}

.download-data span {
  margin: auto;
  text-align: center;
}

.snippet {
  font-family: var(--primary-font-medium);
  font-size: 1.2rem;
  color: var(--primary-blue-color);
  /* text-decoration: underline; */
  border: 2px solid;
  padding: 10px 15px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
}

.autocomplete-dropdown-container {
  z-index: 5000;
  position: absolute;
  width: 100%;
  background: white;
}

#tawkchat-status-text-container.theme-background-color {
  background: var(--red-color) !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.sort-by-drop-class {
  font-family: var(--primary-font-medium);
  color: var(--black-color);
  display: flex;
  font-size: 20px;
}

.active-sort-drop {
  color: var(--primary-blue-color);
}

.chat-user-list-parent {
  height: 650px;
  overflow-y: auto;
}

.slick-center div.carousel-user {
  transform: scale(1.25) !important;
}

.slick-center div.carousel-user-active {
  transform: scale(1.05) !important;
}

.slick-current div.carousel-user.small-carousel {
  transform: scale(1) !important;
  opacity: 1 !important;
}

div.carousel-user.small-carousel {
  opacity: 0.5;
  min-height: 300px;
  transform: scale(1) !important;
}

div.carousel-user.small-carousel .carousel-body {
  padding: 15px;
}

.carousel-user.small-carousel p {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 400;
}

.carousel-user.small-carousel p.testimonial-text-data {
  margin-top: 0.5rem !important;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-family: "Lato";
  font-weight: 600;
}

.edit-in-dashboard {
  color: var(--color-text-primary);
}

/*End Of Testimonial*/

/*Footer*/

.mt-1-5 {
  margin-top: 1.5rem;
}

footer {
  overflow: hidden;
  position: relative;
  left: 0px;
  bottom: 0px;
  height: 140px;
  width: 100%;
  background: var(--footer-black);
}

footer .footer-left-menu {
  color: var(--white-color);
  font-size: 14px;
  padding-left: 1rem;
}

footer span.footer-aside {
  color: var(--white-color);
  font-size: 14px;
  margin-left: 18px;
}

footer .footer-left-menu a {
  color: var(--white-color);
  font-size: 16px;
  margin: 0px 10px;
  font-family: var(--primary-font-regular);
}

footer .footer-bside a {
  color: var(--white-color);
  font-size: 16px;
  margin: 0px 10px;
  font-family: var(--primary-font-regular);
}

footer .footer-left-menu a:hover {
  text-decoration: none;
}

footer .footer-bside a:hover {
  text-decoration: none;
}

footer .footer-bside {
  float: right;
}

footer .footer-bside .contact {
  float: right;
}

footer .footer-middle .logo {
  background: var(--white-color);
  width: 30px;
  height: 30px;
  color: var(--footer-black);
  border-radius: 50%;
  padding: 6px;
  margin: 0px 8px;
}

.click-able-color {
  color: var(--primary-blue-color) !important;
  font-weight: 500;
}

.mapText {
  font-weight: 800;
  font-size: 15px;
}

footer .footer-middle {
  text-align: center;
}

/*End Of Footer*/

/*Dashboard*/

.dashboard-header {
  padding-top: 1rem;
  padding-bottom: 20px;
  border-bottom: 1px solid #b1acac;
}

.dashboard-header-tenant {
  border-bottom: none;
}

.dashboard-body-parent {
  padding: 0 50px;
}

.space-link {
  margin-left: 5.5rem;
  font-size: 20px;
}

.dashboard-body-parent span.new-listing {
  font-family: var(--primary-font-medium);
  font-weight: 600;
  border: 2px solid var(--primary-blue-color);
  padding: 10px 15px;
  border-radius: 4px;
  color: var(--primary-blue-color);
}

.color-red {
  color: var(--primary-color) !important;
}

.drop-down-field-error {
  border-color: var(--primary-color);
}

.dashboard-body-parent .list-part {
  margin: 5px 0px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-detail-part {
  background: var(--white-color);
  padding: 25px;
  display: flex;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.list-detail-part .request {
  margin-top: 50px;
  position: relative;
}

.list-detail-part .request-div {
  text-align: center;
  margin-right: 20px;
}

.bold-me {
  font-weight: 700 !important;
}

.bold-me-1 {
  font-weight: bold !important;
}

.renew-button {
  background: #255cb7;
  color: var(--white-color) !important;
  border: none;
  padding: 10px 10px;
  top: 48px;
  right: -2px;
  position: absolute;
  font-family: var(--primary-font-medium);
  border-radius:5px;
  font-size:12px;
}

.days-button {
border: none;
top: -60px;
right: -2px;
position: absolute;
font-family: var(--primary-font-medium);
background: #d2e2fc;
display: inline-block;
font-size: 16px;
padding: 4px 10px;
border-radius: 5px;
color: #255cb7;
margin-bottom: 0;
}

.custom.active {
  background: var(--primary-blue-color) !important;
  transform: scale(1.15);
  z-index: 2;
}

.sub-pop-up-parent {
  background: var(--red-icon-color);
  color: var(--white);
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: var(--primary-font-bold);
  border-radius: 4px;
}

.RFS-StepCircle.custom.active:hover {
  background: #0744a9 !important;
}

.RFS-StepCircle.custom.active .RFS-Label {
  color: var(--primary-blue-color) !important;
}

.RFS-StepCircle.custom:hover {
  background: transparent !important;
}

.cross-tick-class {
  font-size: 2.5rem;
}

.RFS-StepCircle.custom.completed {
  border: var(--primary-blue-color) 1px solid;
}

.RFS-StepCircle.custom {
  border: #a19f9d 1px solid;
}

.RFS-StepCircle.custom.active {
  border: none;
  width: 35px;
  height: 35px;
}

.RFS-StepCircleContent-d5.completed {
  color: var(--color-text-primary);
}

.RFS-Label,
.RFS-Label-d7 {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.deal-btn {
  background: var(--primary-color);
  border-radius: 3px;
  color: var(--white-color);
  font-family: var(--primary-font-medium);
  padding: 10px 30px;
  text-transform: uppercase;
}

.list-detail-part .request-div span {
  color: var(--primary-blue-color);
  text-transform: uppercase;
  font-weight: 490;
  text-decoration: underline;
  font-family: var(--primary-font-regular);
  font-size:13px;
}

.list-detail-part .list-title {
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-primary);
  font-size: 23px;
}

.list-detail-part .list-att strong {
  font-family: var(--primary-font-demi-bold);
  color: var(--color-text-grey-primary);
  font-size: 15px;
}

.list-detail-part .list-att span.monthly-rent,
span.monthly-rent {
  font-weight: 600;
}

.list-detail-part .list-att {
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-primary);
  font-size: 15px;
}

.list-detail-part .request-div p {
  font-family: var(--primary-font-demi-bold);
  font-size: 20px;
  margin-bottom: 2px;
}

.custom-mar {
  margin-top: 2rem;
}

.list-action-part {
  background: #f3f1f1;
  text-align: center;
  padding: 15px;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

h2.detail-info-contact.normal-text-transform {
  margin-top: 25px;
  font-family: var(--primary-font-demi-bold);
  text-transform: none;
  font-size: 1.5rem;
}

.dashboard-header h1,
.dashboard-header h2,
div.dashboard-body-parent .heading-dashboard {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: var(--color-text-grey-primary);
  text-transform: uppercase;
  font-family: var(--primary-font-regular);
}

.data-div span h4 {
  color: var(--black-color);
  font-family: var(--primary-font-demi-bold);
}

.data-div span.text {
  color: var(--black-color);
  font-family: var(--primary-font-regular);
  font-size: 18px;
  margin-right: 18px;
  text-transform: capitalize;
}

.user-msg-connection-status {
  font-family: var(--primary-font-regular);
  color: var(--black-color);
  font-size: 20px;
}

.underline-me {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

div.user-msg-connection-status span strong {
  color: var(--primary-blue-color);
}

div.user-detail-dashboard div.data-div {
  float: right;
}

div.user-detail-dashboard div.data-div span.user-dashboard-icon {
  margin: 0 15px;
}

div.user-detail-dashboard div.data-div span.user-dashboard-icon .user-icon {
  font-size: 50px;
}
.to-analytics {
  margin-left: 14%;
}
/*End of Dashborad*/

/* RedHanded Section */

.redhanded .a-side .aside-btn button {
  background: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 3px;
  width: 13.5rem;
  height: 4rem;
  margin: 0px 25px 10px 0px;
  text-transform: uppercase;
}

.redhanded .b-side img {
  margin-top: 3.25rem;
}

.redhanded .a-side h1 {
  color: var(--secondary-color);
  font-size: 3rem;
  /* width: 70%; */
  line-height: 3rem;
}

.redhanded .c-side p {
  color: var(--color-text-primary);
  font-size: 20px;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
}

/* .redhanded .d-side {
background: #BCBCBC; 
} */

.home-screen-parent .how-it-works {
  margin-top: -5px;
}

.nav-bar-authentication-btn-row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.redhanded .a-side .aside-btn button {
  background: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 3px;
  /* width: 12rem; */
  height: 4rem;
  margin: 0px 25px 0px 0px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: var(--primary-font-medium);
}

.sc-bdVaJa.eOPJCx.search-icon {
  display: none;
}

.my-field {
  margin: auto 0;
}

.field-search-btn {
  height: 55px;
  border-radius: 7px;
  margin-left: 1rem;
}

.my-field .jZkBQk {
  width: 100%;
  margin: auto;
  height: 9vh !important;
}

.jZkBQk > .wrapper {
  border-radius: 5px;
  width: 100% !important;
  border-color: #a29999;
}

.my-field .jZkBQk > .wrapper {
  border-radius: 5px;
  width: 100% !important;
  border-color: #a29999;
  height: 9vh !important;
  position: relative !important;
}

.frjscc {
height: 40px !important;
min-height: 40px !important;
}

.search__box .search__icon {
position: absolute;
top: 15px;
z-index: 9;
right: 10px;
}

.frjscc > input {
font-size:14px !important;
}

.my-field .jZkBQk > .wrapper .frjscc {
  height: 100%;
}

.form-inline div.sc-ifAKCX.jZkBQk {
  width: 100% !important;
  z-index: 5;
}

/* End of RedHanded Section */

.react-player video {
  width: auto !important;
}

/* requirements slider*/

.scroll-section-parent::-webkit-scrollbar {
  width: 8px;
}

.jZkBQk > .wrapper {
  border-radius: 5px !important;
}

/* / Track / */

.scroll-section-parent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sc-htpNat.ViWoI.clear-icon {
  display: none;
}

.discard-msg {
  font-size: 20px;
}

.modal-footer-cus {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  /* border-top: 1px solid #dee2e6; */
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

/* / Handle / */

.scroll-section-parent::-webkit-scrollbar-thumb {
  background: var(--primary-blue-color);
  border-radius: 10px;
}

/* requirements slider*/

#nav-drop-down-profile .dropdown-menu .notification-body::-webkit-scrollbar,
.scroll-section::-webkit-scrollbar {
  width: 6px;
}

/* / Track / */

#nav-drop-down-profile
  .dropdown-menu
  .notification-body::-webkit-scrollbar-track,
.scroll-section::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* / Handle / */

#nav-drop-down-profile
  .dropdown-menu
  .notification-body::-webkit-scrollbar-thumb,
.scroll-section::-webkit-scrollbar-thumb {
  background: var(--primary-blue-color);
  border-radius: 10px;
}

.requirements .carousel-user {
  height:300px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: var(--white-color);
  border-radius: 6px;
  overflow: auto;
}

.requirements .tabs-btn {
  justify-content: center;
}

.requirements h2 {
  color: #000;
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: uppercase;
}

.requirements .tabs-btn .btn-1 {
  background: var(--red-color);
  color: var(--white-color);
  border: none;
  border-radius: 5px;
  width: 180px;
  height: 35px;
  font-size: 16px;
  padding: 0;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.requirements .tabs-btn .btn-2 {
  background: var(--white-color);
  padding: 0;
  color: var(--black-color);
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  width: 180px;
  height: 35px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--primary-font-medium);
}

.requirements .slick-slide {
  margin: 0px 20px;
}

.requirements .recommended-cards-modify .slick-slide {
margin: 0px !important;
opacity:0;
transition: all .5s;
}

.requirements .recommended-cards-modify .slick-slide.slick-active {
opacity:1;
transition: all .5s;
}

.requirements .card-section {
  padding: 1rem;
  position: relative;
}

.requirements .carousel-user img {
  margin-right: 10px !important;
}

.requirements .recommended-cards-modify .carousel-user img {
  width: 100%;
  margin: 0px;
  max-height: 100%;
}

.property-image-slider-style {
  border-bottom: 3px solid #a80000;
}

.requirements .carousel-user h4,
.requirements .carousel-user h5 {
  font-size: 1.2em !important;
  color: var(--black-color);
  font-weight: bolder !important;
  font-family: var(--tertiary-font-regular);
  text-shadow: none !important;
}

.requirements .carousel-user p {
  font-size: 13px;
  color: #007bff;
  font-weight: 400;
  margin-top: 10px;
  /* font-family: var(--tertiary-font-regular); */
}

.form-a .btn-1 {
  background: var(--white-color);
  border: 1px solid #dde4ec;
  color: #8a2d2d;
  border-radius: 5px;
  width: 6rem;
  height: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
}

.form-a .btn-2 {
  background: var(--red-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 5px;
  width: 6rem;
  height: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
}

.slick-slider .carousel-user span.carousel-vedio-icon {
  bottom: 100%;
  top: 10%;
  color: #fff !important;
}

div.carousel-user.small-carousel {
  position: relative;
  cursor: grab;
  width: auto;
}

.carousel-vedio-icon svg {
  border-radius: 50%;
  background: #0e0e0e9c;
}

.client {
  position: absolute;
}

/* end of requirements slider*/

/* SignUp wizard form*/

.signup-wizard {
  text-align: center;
  background: var(--background-color);
}

.signup-wizard h2,
.signup-wizard h1 {
  padding-top: 5rem;
  text-transform: uppercase;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-medium);
}

h2.h2-detail {
  line-height: 1.5;
  font-family: var(--primary-font-regular);
  font-size: 20px;
  color: var(--color-text-primary);
  margin-top: 40px;
}

h3.role-des {
  font-size: 25px;
  padding-bottom: 0.5rem;
  font-weight: 400;
}

.summary-section h3 {
  font-size: 25px;
  /* font-weight: 500; */
  text-transform: uppercase;
}

.signup-wizard .form-aside h3.subs-title- {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0rem;
  margin-top: 1rem;
  margin-top: auto;
  font-weight: 400;
}

.signup-wizard h2.p1 {
  padding: 0;
  text-transform: none;
}

.signup-wizard .p1 {
  margin-top: 1.5rem;
  font-size: 21px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.signup-wizard .form-aside {
  text-align: left;
  text-transform: uppercase;
  border-radius: 7px;
  padding: 3rem;
  margin: 4rem 2rem 10rem 4rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.signup-wizard .form-cside {
  text-align: left;
  text-transform: uppercase;
  border-radius: 7px;
  padding: 3rem;
  margin: 2rem 7rem 10rem 7rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cus-pad-top {
  padding: 5px 0 0 0;
}

.signup-wizard-thankyou .form-cside {
  text-align: left;
  text-transform: uppercase;
  border-radius: 7px;
  padding: 3rem;
}

.black-bold {
  color: #000 !important;
  font-family: var(--primary-font-medium) !important;
  font-weight: bold !important;
}

.signup-wizard .form-aside h3,
.signup-wizard .form-cside h3 {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0rem;
  /* margin-top: 1rem; */
  margin-top: auto;
  font-weight: 400;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.signup-wizard .form-cside h4 {
  font-family: var(--primary-font-demi-bold);
  font-size: 21px;
  font-weight: 700;
  margin-top: 1rem;
  text-transform: none;
}

.signup-wizard .form-cside p {
  font-family: var(--primary-font-regular);
  color: #000000;
  font-size: 16px;
  margin-top: 1rem;
  text-transform: none;
}

.normal-text-transform {
  text-transform: none;
}

.signup-wizard .form-bside .plan-list p {
  font-family: var(--primary-font-regular);
  font-size: 14px;
  color: #201f1e;
  margin-top: 0rem;
  /* margin-left: 40px; */
  text-align: left;
}

.comparison table thead tr th:last-child,
.comparison table tbody tr td:last-child {
  border-right: none;
}

.comparison table thead tr th {
  border-top: none;
}

.signup-wizard .form-bside {
  border-radius: 7px;
  padding: 3rem;
  margin: 4rem 4rem 10rem 1rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.signup-wizard .form-bside .btn-1 {
  background: #dc4e41;
  color: var(--white-color);
  border: none;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  font-weight: 400;
  width: 18rem;
  padding: 10px 25px;
  margin: auto;
  text-transform: uppercase;
}

.signup-wizard .form-bside .btn-2 {
  background: #3b5998;
  color: var(--white-color);
  border: none;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  margin: auto;
  width: 18rem;
  padding: 10px 25px;
  font-weight: 400;
  text-transform: uppercase;
}

.signup-wizard .form-bside .btn-3 {
  background: #007ab9;
  color: var(--white-color);
  border: none;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  width: 18rem;
  padding: 10px 25px;
  margin: auto;
  font-weight: 400;
  text-transform: uppercase;
}

.signup-wizard .form-bside p {
  margin-top: 1rem;
  font-size: 14px;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
}

.social-media-buttons-parent button {
  font-family: var(--primary-font-regular);
}

.signup-wizard .form-bside h5 {
  font-size: 25px;
  /* font-weight: 500; */
  text-transform: uppercase;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
}

.signup-wizard .form-bside .plan .btn-1 {
  background: var(--white-color);
  color: var(--black-color);
  border-radius: 5px;
  font-family: var(--primary-font-demi-bold);
  width: auto;
  height: auto;
  box-shadow: none;
  text-transform: capitalize;
  font-size: 16px;
  margin: 0;
  padding: 0;
  /* background: var(--white-color);
color: var(--black-color);
border-radius: 5px;
width: 10rem;
height: 2.5rem;
box-shadow: none;
text-transform: uppercase;
font-size: 20px;
font-weight: 500; */
}

.signup-wizard .form-bside .plan {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup-wizard .form-bside .plan span {
  font-family: var(--primary-font-demi-bold);
  font-size: 16px;
  /* font-weight: 500; */
}

.signup-wizard .form-bside .logo {
  margin-right: 1rem;
}

.signup-wizard .form-bside .total {
  padding: 20px 0;
  border-top: 1px solid #cfcfcf;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 1rem;
border-top: 1px solid #CFCFCF;
width: 100%; */
}

.signup-wizard .form-bside .total span {
  font-family: var(--primary-font-demi-bold);
  font-size: 16px;
}

.signup-wizard .form-bside .p2 {
  text-align: left;
  /* margin-left: 10px; */
}

.signup-wizard .form-bside .total h5 {
  font-family: var(--primary-font-demi-bold);
  /* margin-top: 1rem; */
  font-size: 16px;
}

.signup-wizard .form-aside .btn-1 {
  background: var(--white-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--secondary-color);
  border-radius: 5px;
  width: 6rem;
  height: 2.5rem;
  font-weight: 400;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.signup-wizard .form-aside .btn-2 {
  background: var(--red-color);
  border: none;
  color: var(--white-color);
  font-family: var(--primary-font-medium);
  border-radius: 5px;
  width: 6rem;
  height: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
}

.login-signup-btn .login-modal {
  position: absolute;
  z-index: 10000;
  top: 130%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  width: 380px;
  background: var(--white-color);
}

.login-signup-btn .login-modal form {
  padding: 25px;
}

.login-signup-btn .login-modal label {
  text-transform: uppercase;
  color: #a49f9f;
  font-weight: 500;
}

.arrow-up {
  width: 0;
  height: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  right: 5%;
  bottom: 100%;
  border-bottom: 10px solid rgb(255 255 255);
}

.login-signup-btn .login-modal h5 {
  text-align: center;
  text-transform: uppercase;
}

.signup-wizard .form-cside .btn-2 {
  background: var(--red-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 5px;
  font-weight: 400;
  margin-top: 14rem;
  padding: 10px 15px;
  text-transform: uppercase;
}

.signup-wizard .table thead th,
.signup-wizard .table tbody td {
  vertical-align: inherit;
  border-bottom: 1px solid var(--primary-blue-color);
  white-space: nowrap;
  text-align: center;
}

.Subscription .table thead th,
.Subscription .table tbody td {
  vertical-align: inherit;
  border-bottom: 1px solid var(--primary-blue-color);
}

/* end of SignUp wizard form*/

.comparison {
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.comparison table td .table-icon,
.icon-plan,
.plan-feature-parent .table-icon {
  color: var(--primary-blue-color);
  /* margin: 0 5px; */
}

h4.cus-head-size {
  margin: 20px;
  font-size: 1.3rem !important;
}

.tabl-icon-mar-left {
  margin-left: 5px;
}

.plan-next-btn {
  display: flex;
  margin: 10px auto;
  padding: 10px 30px;
  font-family: var(--primary-font-medium);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--white-color);
  border: none;
  border-radius: 3px;
  margin-top: 50px;
}

.plan-feature-parent {
  vertical-align: inherit;
  /* border-bottom: 1px solid var(--primary-blue-color); */
  white-space: nowrap;
  padding: 10px;
  height: 5rem;
}

.sustom-span {
  width: 65px;
  margin: auto 0;
  font-size: 20px;
}

.plan-feature-parent div.field {
  margin: auto 0;
  font-size: 22px;
  width: 285px;
  text-transform: capitalize;
}

.plan-feature-parent span.info {
  margin: auto;
  margin-left: 5px;
}

.side-tab-icon-list {
  font-size: 1.85rem;
}

.margin-for-icon {
  margin-right: 1rem;
  margin-left: 5px;
}

.plan-feature-parent {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.active-tab-icon {
  font-family: var(--primary-font-demi-bold);
}

.compare-row .fixed-length {
  width: 250px;
}

.comparison td,
.comparison th {
  border-right: 1px solid var(--primary-blue-color);
  empty-cells: show;
  padding: 10px;
  height: 5rem;
}

.comparison .tenant-table td,
.comparison .tenant-table th {
  border-right: 0px solid var(--primary-blue-color);
}

.comparison td {
  text-transform: none !important;
  text-align: center;
}

.compare-heading {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0 !important;
  padding-bottom: 0.5rem !important;
  padding-top: 2rem !important;
  color: var(--primary-blue-color);
  font-family: var(--primary-font-regular);
  min-height: 105px;
}

.compare-heading .option {
  font-size: 18px;
  text-transform: capitalize;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
}

.comparison .compare-row {
  background: #0f3e8c40;
  border-top: 1px solid var(--primary-blue-color);
  border-bottom: 1px solid var(--primary-blue-color);
}

.comparison .tickgreen {
  color: var(--tick-color) !important;
  font-size: 2.5rem !important;
}

.comparison .crossred {
  color: var(--cross-color) !important;
  font-size: 2.5rem !important;
}

.comparison .days {
  font-size: 1.5rem;
}

.comparison .info {
  float: right;
  cursor: pointer;
  color: var(--color-text-primary);
}

.comparison .info svg {
  color: var(--color-text-primary);
}

.comparison th {
  font-weight: normal;
  padding: 0;
}

.comparison tr td {
  /* text-align: left; */
  width: 370px;
}

.comparison tr td:first-child {
  text-align: left;
  /* width: 370px; */
}

/* .Subscription .comparison tr td:first-child {
text-align: left;
width: 340px !important;
} */

.Subscription .comparison tr td {
  /* text-align: left; */
  width: 340px !important;
}

.comparison tr td {
  font-size: 20px;
  font-weight: 400;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
}

.signup-wizard .table tbody tr:last-child td,
.Subscription .table tbody tr:last-child td {
  border-bottom: none;
}

.comparison .tl {
  color: var(--black-color);
  font-size: 2rem;
  padding: 2rem;
  font-family: var(--primary-font-regular);
}

.comparison .t2 {
  color: var(--black-color);
  font-size: 2rem;
  padding: 2.5rem 4rem 2rem 1rem;
  font-family: var(--primary-font-regular);
}

.phone-number {
  color: var(--primary-blue-color);
}

.comparison .qbse,
.comparison .qbo {
  color: var(--white-color);
  padding: 10px;
  font-size: 13px;
  border-right: 1px solid #ccc;
  border-bottom: 0;
}

.comparison .tl2 {
  border-right: 0;
}

.comparison .qbse {
  background: #0078c1;
  border-top-left-radius: 3px;
  border-left: 0px;
}

.comparison .qbo {
  background: #009e2c;
  border-top-right-radius: 3px;
  border-right: 0px;
}

.comparison .price-info {
  padding: 5px 15px 15px 15px;
  text-align: center;
}

.pt-26 {
  padding-top: 18px;
}

.comparison .price-was {
  color: #999;
  text-decoration: line-through;
}

.comparison .price-now,
.comparison .price-now span {
  color: var(--color-text-primary);
  font-family: var(--primary-font-medium) !important;
  min-height: 96px;
}

.comparison .price-now span {
  font-size: 36px !important;
  padding: 0rem !important;
}

.comparison .price-small {
  font-size: 18px !important;
  position: relative;
  top: -11px;
  left: 2px;
}

.comparison .price-buy {
  background: #ff5406;
  padding: 10px 20px;
  font-size: 12px;
  display: inline-block;
  color: var(--white-color);
  text-decoration: none;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 5px 0 10px 0;
}

.form-a .subs-heading {
  margin-bottom: 40px;
}

.comparison .price-try button {
  background: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 3px;
  padding: 0.6rem;
  font-family: var(--primary-font-medium);
  font-weight: 400;
  text-transform: uppercase;
}

.comparison .price-try {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

/* Verification*/

.verification-wizard {
  text-align: center;
  background: var(--background-color);
  min-height: 100vh;
}

.verification-wizard h2 {
  padding-top: 5rem;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2.3rem;
  color: #454443;
}

.verification-body {
  border-radius: 7px;
  padding: 3rem;
  margin: 2rem 7rem 10rem 7rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.verification-body p {
  font-family: var(--primary-font-regular);
  font-size: 21px;
  font-weight: 500;
  color: #201f1e;
}

.verification-body h3 {
  font-family: var(--primary-font-regular);
  text-transform: uppercase;
  font-size: 36px;
  color: var(--color-text-grey-primary);
}

.verification-wizard .verification-body .btn-parent {
  margin-top: 200px;
  margin-bottom: 120px;
}

.verification-wizard .verification-body .btn-parent span.btn-2 {
  margin: 10px;
  margin-top: 15px;
  background: var(--red-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 5px;
  font-weight: 400;
  margin-top: 4rem;
  padding: 10px 15px;
  cursor: pointer;
  text-transform: uppercase;
}

.tenant-profile-link-tag {
  margin: auto 0;
}

/*End of Verification*/

/* Start of Connections*/

.property-detail-parent .header-list-detail .analytics-body {
  background: var(--white-color);
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
}

.property-detail-parent .header-list-detail .connection-listing-body {
  background: var(--white-color);
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-primary-color);
  margin: 0 4rem;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .connection-list-part {
  padding-bottom: 1rem;
  margin: 0 4rem;
}

.connection-part {
  border-bottom: 1px solid var(--border-primary-color);
  padding-bottom: 1rem;
  position: relative;
}

.connection-part:last-child {
  border-bottom: none;
}

.connection-part .tea {
  border: 3px solid var(--red-color);
  padding: 1rem;
  color: var(--secondary-color);
  width: 115px;
}

.connection-part .connection-detail {
  margin: auto;
  padding-top: 1px;
}

.connection-part .connection-detail .user-logo-icon {
  margin: 0 10px;
}

.connection-part .connection-detail .connection-detail-name {
  font-family: var(--primary-font-medium);
  color: var(--color-text-primary);
}

.img-side-class {
  text-align: center;
  margin: auto;
}

.img-size-style {
  object-fit: cover;
  width: 100%;
}

.video-section-home-page-style .slick-prev {
  left: 0px;
  z-index: 5;
}

.video-section-home-page-style .slick-next {
  right: 15px;
  z-index: 5;
}

.video-section-home-page-style .slick-prev:before,
.slick-next:before {
  color: #000 !important;
  font-size: 35px !important;
}

.home-search .wrapper div.sc-bwzfXH.bkngVT {
  background-color: #fff !important;
}

.connection-part .connection-detail .connection-detail-location {
  font-family: var(--primary-font-regular);
  font-size: 15px;
  text-transform: uppercase;
  white-space: break-spaces;
  word-break: break-all;
}

.cus-siz {
  width: 300px;
}

.connection-part .connection-detail .connection-detail-address {
  text-transform: uppercase;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.connection-part .connection-action {
  margin: auto;
}

.connection-part .connection-action a {
  margin: auto 0;
}

.connection-part .connection-action span {
  margin: 10px;
  font-size: 20px;
}

.connection-part .connection-action div {
  margin: auto;
}

.connection-part .connection-action .message {
  font-family: var(--primary-font-medium);
  color: var(--red-color);
  text-decoration: underline;
}

.connection-part .connection-action .remove {
  font-family: var(--primary-font-medium);
  color: var(--color-text-primary);
  text-decoration: underline;
}

.connection-part .connection-location {
  margin: auto;
}

.connection-part .connection-location .connection-requirement {
  font-family: var(--primary-font-medium);
  color: var(--color-text-grey-tertiary);
  text-transform: uppercase;
  padding: 0 15px;
}

.connection-part
  .connection-location
  .connection-requirement
  .connection-requirement-data {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.connection-part
  .connection-location
  .connection-requirement
  .connection-requirement-data
  strong {
  font-family: var(--primary-font-demi-bold);
}

.connection-pop-up {
  position: relative;
  flex-direction: row;
  justify-content: center;
}

.connection-pop-up .acent-decline-popup {
  width: 450px;
}

.connection-popup-info {
  white-space: break-spaces;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
  font-size: 15px;
  margin: 0 !important;
}

.msg-con {
  font-size: 20px;
  font-family: var(--primary-font-medium);
}

.msg-con-btn {
  border-radius: 3px;
  /* background-color: #a91622 !important; */
  padding: 8px 30px 8px 30px;
  color: var(--white-color);
  font-family: var(--primary-font-medium);
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin-top: 15px;
}

.connection-part .connection-pop-up .dropdown-item:active,
.connection-part .connection-pop-up .dropdown-item:hover,
.connection-part .connection-pop-up .dropdown-item:focus {
  background: transparent;
  color: var(--color-text-primary);
  border: none;
}

.connection-pop-up .acent-decline-popup .heading-popup {
  color: #bf3b2f;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.data-span-dropdown-popup {
  margin-top: 0 !important;
}

.message-body-popup span {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-regular);
  font-size: 15px;
}

.message-body-popup .message-part {
  background: #f4f2ef;
  margin: auto 10px;
  margin-top: 5px;
  padding: 15px;
  white-space: break-spaces;
  font-family: var(--secondary-font-regular);
  font-style: italic;
}

.message-body-popup .your-message-part {
  margin: auto 10px;
  margin-top: 5px;
  padding: 15px;
  height: 100px;
  white-space: break-spaces;
  font-family: var(--secondary-font-regular);
  font-style: italic;
  border: 1px solid var(--border-primary-color);
  border-radius: 4px;
}

.message-body-popup .chat-box-title {
  font-family: var(--secondary-font-regular);
  font-size: 16px !important;
  color: var(--color-text-grey-tertiary);
}

.popup-btn-parent {
  margin: auto;
  text-align: center;
}

.popup-btn-parent .accept-btn {
  border: none;
  text-transform: uppercase;
  background: #00ad0d;
  font-family: var(--primary-font-regular);
  color: var(--white-color);
  padding: 8px 25px;
  border-radius: 3px;
}

.popup-btn-parent .decline-btn {
  border: none;
  text-transform: uppercase;
  background: #e81d2e;
  font-family: var(--primary-font-regular);
  color: var(--white-color);
  padding: 8px 25px;
  border-radius: 3px;
}

.popup-btn-parent .back-btn {
  margin-top: 4px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: uppercase;
  font-size: 15px;
}

.send-request-popup {
  width: 450px;
}

.send-request-popup .heading-popup {
  color: #bf3b2f;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.connection-detail .user-logo-icon {
  margin: 0 10px;
}

.connection-detail .connection-detail-location {
  font-family: var(--primary-font-regular);
  font-size: 20px;
  text-transform: uppercase;
}

.message-body-popup span {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-regular);
  font-size: 15px;
}

.message-body-popup .message-part {
  background: #f4f2ef;
  margin: auto 10px;
  margin-top: 5px;
  padding: 15px;
  white-space: break-spaces;
  font-family: var(--secondary-font-regular);
  font-style: italic;
}

.message-body-popup .your-message-part {
  margin: auto 10px;
  margin-top: 5px;
  padding: 15px;
  height: 100px;
  white-space: break-spaces;
  font-family: var(--secondary-font-regular);
  font-style: italic;
  border: 1px solid var(--border-primary-color);
  border-radius: 4px;
}

.message-body-popup .chat-box-title {
  font-family: var(--secondary-font-regular);
  font-size: 16px !important;
  margin-left: 10px;
  color: var(--color-text-grey-tertiary);
}

.popup-btn-parent {
  margin: auto;
  text-align: center;
}

.popup-btn-parent .invite-btn {
  border: none;
  text-transform: uppercase;
  background-color: var(--red-color);
  font-family: var(--primary-font-regular);
  color: var(--white-color);
  padding: 8px 25px;
  border-radius: 3px;
}

.popup-btn-parent .back-btn {
  margin-top: 4px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: uppercase;
  font-size: 15px;
}

.custom-table-invite-btn .dropdown-item:active,
.custom-table-invite-btn .dropdown-item:hover,
.custom-table-invite-btn .dropdown-item:focus {
  background: transparent;
  color: var(--color-text-primary);
  border: none;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  .active-drop-down-field {
  background: var(--dot-success);
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  .warning-drop-down-field {
  background: var(--dot-warning);
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  .dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 5px;
  display: inline-block;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .drop-down-custom {
  background: var(--white-color);
  border: 1px solid #979797;
  border-radius: 4px;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .list-sort
  .dropdown-toggle::after {
  color: var(--red-color);
}

.drop-down-custom:focus {
  box-shadow: none;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  p {
  margin: 0;
  text-transform: uppercase;
  font-family: var(--secondary-font-regular);
  color: var(--black-color);
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .selected-drop-down-field {
  background: #f2f2f2;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .dropdown-item {
  padding: 0.5rem 1.5rem;
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .selected-drop-down-field
  p {
  color: var(--primary-blue-color);
}

.property-detail-parent
  .header-list-detail
  .connection-listing-body
  .filter-part-connections
  .list-sort {
  margin-top: 2px;
}

.property-detail .analytics-body .analytics-tab-part {
  margin: 1rem;
}

.property-detail .analytics-body .analytics-parent {
  padding: 0 5rem;
}

.property-detail .analytics-body .analytics-parent .upgrade-plan {
  background: var(--primary-blue-color);
  margin: auto;
  border-radius: 5px;
  padding: 3rem;
}

.property-detail
  .analytics-body
  .analytics-parent
  .upgrade-plan
  .upgrade-msg-plan {
  color: var(--white-color);
  font-family: var(--primary-font-demi-bold);
  margin: auto;
  white-space: break-spaces;
  word-break: break-all;
}

.auto-width-height {
  height: auto !important;
  width: auto !important;
}

.custom---btn-right {
  margin-right: 1rem;
}

.custom---btn-left {
  margin-left: 1rem;
}

h4.custom-he {
  font-size: 27px !important;
}

h4.custom-hee {
  font-size: 25px !important;
}

.property-detail
  .analytics-body
  .analytics-parent
  .upgrade-plan
  .upgrade-plan-btn {
  color: var(--white-color);
  padding: 10px 25px;
  text-transform: uppercase;
  background: var(--white-color);
  width: fit-content;
  font-weight: bold;
  margin-bottom: 0;
  color: var(--primary-blue-color);
  border-radius: 4px;
  font-family: var(--primary-font-medium);
}

.property-detail .analytics-body .analytics-parent .analtics-card {
  border-radius: 5px;
  background: #f6fbff 0% 0% no-repeat padding-box;
  border: 1px solid #5baffd;
  max-height: 204px;
  margin-right: 14px;
}

.property-detail
  .analytics-body
  .analytics-parent
  .analtics-card
  .analytics-card-header {
  padding: 14px;
  border-bottom: 1px solid #e1e1e1;
  background: #f6fbff 0% 0% no-repeat padding-box;
}

.property-detail
  .analytics-body
  .analytics-parent
  .analtics-card
  .analytics-card-header
  .analytic-main-heading {
  /* font-family: var(--primary-font-demi-bold);
color: var(--color-text-grey-primary);
font-size: 20px; */
}

.property-detail
  .analytics-body
  .analytics-parent
  .analtics-card
  .analytics-card-header
  .analytic-main-heading
  .analytics-header-icon {
  float: right;
}

.property-detail
  .analytics-body
  .analytics-parent
  .analtics-card
  .analytics-card-header
  .analytic-sub-heading {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
  font-family: "MontserratRegular";
}

.property-detail
  .analytics-body
  .analytics-parent
  .analtics-card
  .analytic-card-body {
  /* margin: auto;
flex: none; */
}

.property-detail
  .analytics-body
  .analytics-parent
  .analtics-card
  .analytic-card-body
  .analytic-card-body-data {
  font-family: "MontserratBold";
  letter-spacing: 0px;
  color: #255cb7;
  text-transform: uppercase;
  font-size: 60px;
}

.property-detail .analytics-body .analytics-tab-part span.tab-click {
  text-transform: uppercase;
  font-family: var(--primary-font-medium);
  padding: 0 10px;
  color: var(--color-background-grey-scondary);
  border-right: 1px solid var(--color-text-grey-tertiary);
}

.property-detail .analytics-body .analytics-tab-part span.tab-click:last-child {
  border-right: none;
}

.property-detail
  .analytics-body
  .analytics-tab-part
  span.tab-click.active-tab-analytics {
  color: var(--color-text-grey-primary);
}

/*End Of Connections*/

.__react_component_tooltip {
  background: #222222c7 !important;
}

.profile .profile-view {
  margin: 1.5rem;
}

.profile .profile-view .a-side p {
  font-size: 1.3rem;
}

.profile .profile-view .form-a {
  text-align: left;
  text-transform: uppercase;
  border-radius: 7px;
  padding: 3rem;
  margin: 0rem 3rem 4rem 0rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.profile .profile-view .form-a h2 {
  color: #c03d31;
  font-size: 1.7rem;
}

.my-auto-complete {
  padding: 0 5px;
}

.profile .profile-view .form-a .title {
  color: #808080;
  font-size: 1.3rem;
  font-weight: 400;
}

.profile .profile-view .form-a .title-info {
  color: var(--color-text-grey-primary);
  font-size: 1.2rem;
  text-transform: normal;
  font-weight: 400;
}

#my-ggogl div:first-child {
  width: 90% !important;
}

.emaail-inprofiel {
  text-transform: initial;
}

.profile .profile-view .form-a .bio-info {
  font-size: 1rem;
  text-transform: none;
  width: 80%;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  line-height: 31px;
}

.profile .profile-view .form-a .main-bio-heading {
  color: var(--primary-blue-color);
  font-family: var(--primary-font-medium);
}

.profile .profile-view .form-a .title {
  font-family: var(--primary-font-medium);
}

.profile .profile-view .form-a .title-info {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  font-size: 20px;
}

.chat-view div.megs:last-child {
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.fl-pro-main {
  margin-left: 10px;
}

.bottom-img-row {
  margin-top: 10px;
  margin-left: 0px;
}

.property-main {
  height: 120px;
  width: 120px;
  overflow: hidden;
}

.property-main-adjust-height {
  height: 100%;
}
.image-preview-section {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.property-main-1 {
  height: 120px;
  width: 120px;
  margin-left: 15px;
}
.img-fluid-1 {
  height: 100%;
  width: 100%;
}
.dropzone {
  width: 100%;
  height: 100%;
  text-align: center;
}
.no-floorplan-img {
  font-size: 25px;
}

.no-floorplan {
  font-size: 16px;
}

.req-question-class {
  font-size: 18px;
}

.chat-view div.megs:last-child .message-div-chat {
  padding-bottom: 2.5rem;
}

.chat-view div.megs:last-child .message-div-chat:last-child {
  margin-bottom: 3.5rem;
}

.signup-wizard .form-cside .btn-2.confirmation-btn-after-signup {
  padding: 10px 25px;
  margin-top: 8em;
}

.user-list-parent {
  height: 55vh;
  overflow: auto;
  overflow-x: hidden;
}

.profile .profile-view .form-a .visa-icon svg {
  margin-top: 2rem;
  font-size: 2.3rem;
  color: #0066b2;
}

.profile .profile-view .form-a .edit-icon {
  float: right;
  font-size: 2rem;
  border: 2px solid var(--navbar-item-color);
  padding: 7px;
  border-radius: 50%;
  color: var(--navbar-item-color);
  cursor: pointer;
}

.profile .profile-view .form-a .edit-icon-active {
  background: var(--primary-blue-color);
  border: 2px solid var(--primary-blue-color);
  color: var(--white-color);
}

.profile .profile-view .form-a .top-one {
  border-bottom: 1px solid #a7a7a7;
}

.review-landlord-main-body .top-one {
  border-bottom: 1px solid #a7a7a7;
  width: 100%;
  margin: 0 1rem;
}

.pic-div img {
  margin: 0 5px;
}

.location-parent-div .location-heading-div {
  padding-top: 0;
}

.profile {
  overflow: hidden;
}

.profile .profile-view .form-a .title1 {
  color: #232221;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
}

.profile .profile-view .form-a .title2 {
  color: #232221;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
}

.profile .profile-view .form-a .title-info1 {
  color: var(--color-text-primary);
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 400;
  margin-top: 0.8rem;
}

.profile .profile-view .form-a span {
  color: #201f1e;
  text-transform: capitalize;
  padding-right: 1rem;
  font-family: var(--primary-font-regular);
  font-size: 17px;
}

.profile .profile-view .form-a .title3 {
  color: #8f8f8f;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.profile .profile-view .form-a .title-info2 {
  color: #504f4e;
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 400;
  margin-top: 0.8rem;
}

.spinner-grow {
  color: var(--primary-blue-color) !important;
}

.profile .profile-view .form-a .utilities-btn .utility-btn {
  border: 2px solid var(--primary-grey-color);
  background: var(--white-color);
  border-radius: 20px;
  padding: 0px 15px;
  color: var(--primary-grey-color);
  margin-right: 0.5rem;
}

.profile .profile-view .form-a .title-info3 {
  color: #232221;
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 700;
  margin-top: 0.8rem;
}

.profile .profile-view .form-a .title-info3 span {
  color: #504f4e;
  font-weight: 400;
}

.profile-section .tenant-logo .tea {
  border: 3px solid var(--red-color);
  padding: 1rem;
  font-size: 9rem;
  margin-right: 8rem;
  color: var(--red-icon-color);
}

.property-detail-parent .header-list-detail {
  padding: 0 50px;
}

.property-detail-parent .header-list-detail .header-row {
  background: var(--primary-blue-color);
  width: 100%;
  padding: 15px 0;
  text-align: center;
}

/* .carosel-card-height-home {
height: 10vh;
} */

button.btn-2:disabled,
.add-question:disabled {
  background: #1f58b5bf !important;
  cursor: no-drop;
}

.bold-td {
  font-weight: 900;
  vertical-align: middle !important;
}

.property-detail-parent .header-list-detail .header-row span.section-heading {
  font-family: var(--primary-font-medium);
  color: var(--white-color);
  text-transform: uppercase;
  padding: 0 15px;
  border-right: 1px solid var(--white-color);
  font-size: 18px;
}

.property-detail-parent
  .header-list-detail
  .header-row
  span.section-heading-active {
  font-family: var(--primary-font-bold);
}

.property-detail-parent
  .header-list-detail
  .header-row
  span.section-heading:last-child {
  border: none;
}

.property-detail,
.dashboard-parent {
  background: var(--background-color);
  padding-top: 2rem;
}

.tenant-dashboard p.user-name-dashboard {
  margin: auto 0;
  margin-left: 25px;
  font-size: 20px;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-demi-bold);
}

.user-detail-dashboard .tenant-dashboard-connection-status {
  font-family: var(--primary-font-demi-bold);
  /* color: var(--red-color); */
  font-size: 20px;
}

.property-detail-parent .header-list-detail .listing-body {
  background: var(--white-color);
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .image-section-detail {
  margin: 10px 5rem;
  border-bottom: 1px solid var(--border-primary-color);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .image-section-detail
  .edit-icon-detail,
.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section
  .edit-icon-detail,
.property-detail-parent
  .header-list-detail
  .listing-body
  .property-description-section
  .edit-icon-detail {
  float: right;
  color: var(--color-text-grey-primary);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .image-section-detail
  .property-image {
  margin: 15px;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .image-section-detail
  .property-image-multi {
  margin: 15px 5px;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section {
  margin: 10px 5rem;
  border-bottom: 1px solid var(--border-primary-color);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section
  .property-detail-value {
  font-family: var(--primary-font-regular);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section
  .title3 {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-medium);
  font-size: 17px;
  text-transform: uppercase;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-description-section
  .property-description-heading {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-medium);
  font-size: 17px;
  text-transform: uppercase;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section
  .title-info3
  span.value-bold {
  font-family: var(--primary-font-demi-bold);
  font-size: 20px;
  color: var(--primary-blue-color);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section
  .title-info3
  span {
  font-family: var(--primary-font-regular);
  font-size: 20px;
}

.tenant-table-sub-profile td {
  border-bottom: 1px solid var(--red-color);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-name-section
  .utilities-btn
  .utility-btn {
  border: 2px solid var(--primary-grey-color);
  background: var(--white-color);
  border-radius: 20px;
  padding: 0px 15px;
  color: var(--primary-grey-color);
  font-family: var(--primary-font-medium);
  margin-right: 0.5rem;
  margin-bottom: 2px;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-description-section {
  margin: 10px 5rem;
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-description-section
  .property-description-heading {
  font-family: var(--primary-font-medium);
  /* font-size: 16px; */
  color: var(--color-text-grey-tertiary);
}

.property-detail-parent
  .header-list-detail
  .listing-body
  .property-description-section
  .property-description-heading
  .property-description {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.property-detail-parent .header-list-detail .tenant-listing-body {
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
}

.property-detail-parent
  .header-list-detail
  .tenant-listing-body
  .search-filter-part {
  background: var(--color-background-grey);
  padding: 15px 2rem;
  justify-content: space-around;
  text-align: center;
  margin-right: 0;
  margin-left: 0;
}

.property-detail-parent
  .header-list-detail
  .tenant-listing-body
  .search-filter-part
  div {
  padding: 0 5px;
  margin: 2px 0;
}

.property-detail-parent
  .header-list-detail
  .tenant-listing-body
  .search-filter-part
  div.search-div {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.property-detail-parent
  .header-list-detail
  .tenant-listing-body
  .tenant-body-part {
  background: var(--white-color);
  padding-left: 3rem;
  padding-right: 3rem;
}
.description-section-edit {
  flex-flow: column;
}
.description-section-edit .edit-icon-detail {
  width: 100%;
  text-align: end;
  margin-right: 26px;
}
.description-section-edit .edit-icon-btn {
  width: 30px;
  height: 17px;
}
button.gm-ui-hover-effect {
  right: 5px !important;
}

.gm-ui-hover-effect img {
  height: 25px !important;
  width: 25px !important;
}

.tenant-body-part .custom-border-bottom {
  border-bottom: 1px solid var(--border-primary-color);
}

.tenant-body-part .custom-border-bottom:last-child {
  border-bottom: none;
  margin-bottom: 2rem;
}

.tenant-body-part .custom-table-invite-btn {
  padding-top: 31px;
  padding-bottom: 29px;
}

a.pro span:hover,
.showMore-btn:hover {
  color: white;
}

.show-disable {
  border-radius: 3px;
  cursor: no-drop !important;
  background-color: #1f58b5bf !important;
  font-size: var(--primary-font-medium);
  font-size: 16px;
  color: var(--white-color) !important;
  text-transform: uppercase;
  border: none;
  outline: none;
}

.cancel-subscription-style {
  cursor: pointer;
  font-size: 18px !important;
  text-decoration: underline;
  font-weight: 600;
  color: var(--primary-blue-color) !important;
}

.cancel-subscription-style-disable {
  cursor: no-drop;
  font-size: 18px !important;
  text-decoration: underline;
  font-weight: 600;
  color: #63666b !important;
}

.mailed-msg {
  font-weight: 600;
  font-size: 13px;
  text-transform: none !important;
}

.margin-auto-vertical {
  margin: auto 0;
}

.showMore-btn {
  border-radius: 3px;
  background-color: var(--red-color);
  font-size: var(--primary-font-medium);
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
}

.no-match {
  color: white;
  border-radius: 3px;
  padding: 5px 21px;
  text-transform: uppercase;
  margin: auto;
  text-align: center;
}

.tenant-body-part .custom-table-invite-btn button.invite-btn-table {
  height: 44px;
  width: 120px;
  border-radius: 3px;
  background-color: var(--red-color);
  font-size: var(--primary-font-medium);
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
}

.tenant-body-part .custom-table-cell-padding {
  padding: 32px 0px;
}

.tenant-body-part .helper-txt {
  font-family: var(--primary-font-medium);
  font-size: 16px;
  line-height: 22px;
  color: var(--color-text-grey-tertiary);
}

.tenant-body-part .helper-txt-content {
  font-family: var(--primary-font-demi-bold);
  font-size: 18px;
  line-height: 25px;
  color: var(--color-text-primary);
}

.tenant-body-part .helper-txt-content span {
  font-family: var(--primary-font-regular);
}

.tenant-body-part .tenant-table-title {
  font-family: var(--primary-font-medium);
  color: var(--color-text-primary);
  line-height: 26px;
  font-size: 19px;
}

.tenant-body-part .tenant-table-subtitle {
  font-family: var(--primary-font-regular);
  font-size: 21px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--color-text-primary);
}

.social-login-btn {
  width: 275px;
}

.tenant-body-part .tenant-table-profile svg {
  height: 36px;
  width: 36px;
  color: var(--red-color);
  margin-right: 19px;
}

.tenant-body-part .tenant-grand-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 104px;
  width: 104px;
  border: 3px solid var(--red-color);
}

.tenant-body-part .tenant-grand-icon svg {
  font-size: 50px;
  color: var(--secondary-color);
}

.tenant-listing-body .search-filter-part .custom-toggle-button {
  background: #fff;
  color: #b5b5b5;
  display: flex;
  justify-content: space-between;
  height: 43px;
}

.tenant-listing-body
  .search-filter-part
  .custom-toggle-button
  .custom-chevron-margin {
  margin-top: 5px;
}

.tenant-listing-body .search-filter-part,
.view-properties-body .dropdown-title {
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-tertiary);
}

.properties-dropdown-header {
  font-size: 15px;
  text-transform: uppercase;
  width: 250px;
}

.custom-tofrom-dropdown .input-group {
  width: 100px;
}

.custom-tofrom-dropdown span {
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 30px;
}

.dashboard-header-list-detail {
  padding: 0 50px;
}

.dashboard-header-list-detail .header-row {
  background: var(--red-color);
  width: 100%;
  padding: 15px 0;
  text-align: center;
}

.dashboard-header-list-detail .header-row span.section-heading-active {
  font-family: var(--primary-font-bold) !important;
}

.dashboard-header-list-detail .header-row span.section-heading {
  font-family: var(--primary-font-medium);
  color: var(--white-color);
  text-transform: uppercase;
  padding: 0 15px;
  border-right: 1px solid var(--white-color);
  font-size: 18px;
}

.dashboard-header-list-detail .header-row span.section-heading:last-child {
  border: none;
}

.dashboard-header-list-detail .requirement-body {
  background: var(--white-color);
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
}

.dashboard-header-list-detail .requirement-body .business-profile-section {
  margin: 10px 5rem;
  border-top: 1px solid var(--border-primary-color);
}

.dashboard-header-list-detail
  .requirement-body
  .business-profile-section
  .edit-icon-detail {
  float: right;
}

.dashboard-header-list-detail .requirement-body .heading-title {
  color: #bf3b2f;
  font-size: 17px;
  text-transform: uppercase;
  line-height: 23px;
  font-family: var(--primary-font-medium);
}

.dashboard-header-list-detail
  .requirement-body
  .business-profile-section
  .title-info3 {
  font-size: 19px;
  text-transform: uppercase;
  line-height: 26px;
  font-family: var(--primary-font-medium);
  color: var(--color-text-primary);
}

.nav-drop-down:focus {
  border: none;
  box-shadow: none;
}

#nav-drop-down-profile .dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

#nav-drop-down-profile .dropdown-menu .notification-body {
  /* min-height: 290px; */
  max-height: 290px;
  height: 100%;
  overflow: auto;
}

.dashboard-header-list-detail .requirement-body .business-profile-section p {
  font-size: 19px;
  line-height: 26px;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
}

.dashboard-header-list-detail
  .requirement-body
  .business-profile-section
  .title-info4 {
  font-size: 19px;
  line-height: 19px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.dashboard-header-list-detail
  .requirement-body
  .business-profile-section
  .title-info4
  span {
  font-family: var(--primary-font-medium);
}

.dashboard-header-list-detail
  .requirement-body
  .business-profile-section
  .title-down {
  font-size: 19px;
  line-height: 19px;
  font-family: var(--primary-font-medium);
  color: var(--color-text-primary);
}

.dashboard-header-list-detail .requirement-body .requirment-name-section {
  margin: 10px 5rem;
  border-top: 1px solid var(--border-primary-color);
}

.dashboard-header-list-detail
  .requirement-body
  .requirment-name-section
  .edit-icon-detail {
  float: right;
}

.dashboard-header-list-detail
  .requirement-body
  .requirment-name-section
  .title3 {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-medium);
  font-size: 16px;
  text-transform: uppercase;
}

.dashboard-header-list-detail
  .requirement-body
  .requirment-name-section
  .utilities-btn
  .utility-btn {
  border: 2px solid #333;
  background: var(--white-color);
  border-radius: 20px;
  padding: 0px 15px;
  color: #333;
  margin-right: 0.2rem;
  margin-bottom: 2px;
}

.dashboard-header-list-detail
  .requirement-body
  .requirment-name-section
  .utilities-btn
  .utility-btn:first-child {
  margin-left: 0;
}

.requirement-body .requirment-name-section .title-info3 span.value-bold {
  font-family: var(--primary-font-demi-bold);
  font-size: 20px;
}

.dropdown-item-list {
  padding: 5px !important;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.requirement-body .requirment-name-section .title-info3 span {
  font-family: var(--primary-font-regular);
  font-size: 20px;
}

.dashboard-header-list-detail .requirement-body .requirment-name-section .p {
  font-size: 19px;
  line-height: 31px;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
}

.dashboard-header-list-detail .connection-listing-body {
  background: var(--white-color);
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.dashboard-header-list-detail .connection-listing-body .filter-part-connections,
.dashboard-header-list-detail .connection-listing-body .connection-list-part {
  margin: 0 4rem;
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections {
  padding: 3rem 0 1.5rem 0;
  border-bottom: 1px solid var(--border-primary-color);
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .drop-down-custom {
  background: var(--white-color);
  border: 1px solid;
  border-radius: 4px;
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  .dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 5px;
  display: inline-block;
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  .active-drop-down-field {
  background: var(--dot-success);
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  p {
  margin: 0;
  text-transform: uppercase;
  font-family: var(--secondary-font-regular);
  color: var(--black-color);
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .dropdown-item {
  padding: 0.5rem 1.5rem;
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .selected-drop-down-field {
  background: #f2f2f2;
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .selected-drop-down-field
  p {
  color: var(--primary-blue-color);
}

.dashboard-header-list-detail
  .connection-listing-body
  .filter-part-connections
  .connection-options
  .warning-drop-down-field {
  background: var(--dot-warning);
}

.dashboard-header-list-detail .connection-part .connection-detail-name {
  font-family: var(--primary-font-regular);
  font-size: 19px;
  color: var(--color-text-primary);
}

.connection-list-part-tenant .connection-part-tenant {
  padding: 2rem 0;
}

.dashboard-header-list-detail
  .connection-part
  .connection-detail
  .connection-detail-name {
  font-family: var(--primary-font-medium);
  font-size: 21px;
  color: var(--color-text-primary);
}

.dashboard-header-list-detail .view-properties-body {
  width: 100%;
  /* padding: 15px 0; */
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.dashboard-header-list-detail .view-properties-body .search-filter-part {
  background: var(--color-background-grey);
  padding: 15px 2rem;
  justify-content: space-around;
  text-align: center;
  margin-right: 0;
  margin-left: 0;
}

.dashboard-header-list-detail .view-properties-body .search-filter-part div {
  padding: 0 5px;
  margin: 2px 0;
}

.view-properties-body .search-filter-part .custom-toggle-button {
  background: #fff;
  color: #b5b5b5;
  display: flex;
  justify-content: space-between;
  height: 43px;
}

.view-properties-body
  .search-filter-part
  .custom-toggle-button
  .custom-chevron-margin {
  margin-top: 5px;
}

.dashboard-header-list-detail .view-properties-body .view-properties-body-part {
  padding-left: 119px;
  padding-right: 119px;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list {
  overflow-y: scroll;
  height: 700px;
}

.map-parent-div {
  width: 100%;
  height: 700px;
}

.map-parent-div div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  top: 0% !important;
}

.map-parent-div button.gm-control-active[title="Toggle fullscreen view"] {
  display: none;
}

.map-parent-div .gmnoprint div {
  background: transparent !important;
}

.map-parent-div button.gm-control-active[title="Zoom in"],
.map-parent-div button.gm-control-active[title="Zoom out"] {
  border: 1px solid var(--red-color) !important;
  margin-top: -1px !important;
  height: 41px !important;
  background: var(--white-color) !important;
  border-radius: 4px !important;
}

.map-parent-div button.gm-control-active[title="Zoom in"] {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.map-parent-div button.gm-control-active[title="Zoom out"] {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part,
.view-properties-body .view-properties-body-part .location-list .location-part {
  background: var(--white-color);
  border: 1px solid #d1d1d1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  img {
  max-height: 128px;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-name {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  margin-bottom: 0px;
  font-size: 20px;
  margin-top: 15px;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-address {
  color: var(--navbar-item-color);
  font-family: var(--primary-font-regular);
  font-size: 17px;
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-area {
  font-family: var(--primary-font-regular);
}

.dashboard-header-list-detail
  .view-properties-body
  .view-properties-body-part
  .location-list
  .location-part
  .location-detail
  .location-area
  strong {
  font-family: var(--primary-font-demi-bold);
}

.post-property-panel {
  background: var(--background-color);
}

/*Tenant Post property*/

.post-property-panel .post-property-tenant-header .heading {
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
  font-size: 36px;
  letter-spacing: 0;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 84px;
  margin-right: 82px;
}

.btt {
  padding-bottom: 35rem !important;
}

.main-body {
  border-radius: 4px;
  padding: 70px;
}

.location,
.review-heading {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 25px;
  letter-spacing: 0;
  line-height: 34px;
  text-transform: uppercase;
  margin-left: 65px;
  /* padding-top: 55px; */
}

.second-text {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 51px;
  padding-top: 10px;
}

.review-heading-2 {
  font-family: var(--primary-font-regular);
  color: var(--primary-color);
}

.review {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 25px;
  letter-spacing: 0;
  line-height: 34px;
  text-transform: uppercase;
  margin-left: 64px;
  padding-top: 55px;
}

.review-business-profile {
  color: #bf3b2f;
  font-family: var(--primary-font-regular);
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-transform: uppercase;
  margin-left: 49px;
  margin-top: 10px;
}

.line-break {
  width: 730px;
  height: 1px;
  color: #000;
}

.grind-coffee {
  color: #201f1e;
  font-family: var(--primary-font-regular);
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-transform: uppercase;
  margin-left: 49px;
  margin-top: 20px;
}

.wd {
  width: 45%;
  margin-left: 67px;
  margin-top: 10px;
  margin-top: 17px;
}

.stst {
  color: var(--color-text-grey-secondary);
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 20px;
}

.red {
  border-radius: 3px;
  background-color: #a91622 !important;
  padding: 8px 30px 8px 30px;
  position: relative;
  left: 45%;
  bottom: 10px;
  font-family: var(--primary-font-medium);
}

.hg {
  height: 45px;
}

/*End Of Tenant Post Property*/

/*Required Property*/

.body-heading {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 25px;
  text-transform: uppercase;
  padding-top: 55px;
  padding-left: 10px;
  padding-top: 8px;
}

.main-body {
  border-radius: 4px;
  padding: 60px;
}

.gd {
  width: 35%;
  margin-left: 67px;
  margin-top: 10px;
  margin-top: 17px;
}

.gg {
  width: 35%;
  margin-left: 12px;
  margin-top: 10px;
  margin-top: 17px;
}

.gk {
  width: 41%;
  margin-left: 66px;
  margin-top: 10px;
  margin-top: 17px;
  text-transform: uppercase;
}

.button {
  border: 1px solid gray;
  border-radius: 8px;
  color: var(--red-color);
  border-color: var(--red-color);
  border-radius: 30px;
  margin: 2px;
  padding: 2px 12px 0px 12px;
  width: 82px;
  height: 26px;
  text-align: center;
  font-family: var(--primary-font-medium);
  font-size: 13px;
}

.button-1 {
  border: 1px solid gray;
  border-radius: 8px;
  color: var(--primary-blue-color);
  border-color: var(--primary-blue-color);
  border-radius: 30px;
  margin: 2px;
  padding: 2px 12px 0px 12px;
  height: 26px;
  text-align: center;
  font-family: var(--primary-font-medium);
  font-size: 13px;
}

.button-2 {
  border: 1px solid var(--navbar-item-color);
  border-radius: 8px;
  border-radius: 3px;
  background-color: var(--white-color);
  font-family: var(--primary-font-medium);
  border-radius: 30px;
  margin: 2px;
  padding: 2px 12px 2px 12px;
  color: var(--navbar-item-color);
  font-size: 13px;
}

.second-textt {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  padding-top: 10px;
  padding-left: 27px;
}

.square-footage {
  color: #757575;
  font-family: var(--secondary-font-regular);
  font-size: 14px;
  padding-top: 20px;
  text-transform: uppercase;
  padding-left: 27px;
}

.custom-file-msg {
  margin-top: 2rem !important;
}

.budget-sqft {
  color: #757575;
  font-family: var(--secondary-font-regular);
  font-size: 14px;
  padding-top: 20px;
  text-transform: uppercase;
  padding-left: 27px;
}

.butn-one {
  border-radius: 3px;
  background-color: var(--white-color);
  color: var(--secondary-color);
  padding: 8px 30px 8px 30px;
  border: 1px solid #d3dce6;
  font-family: var(--primary-font-medium);
  font-size: 16px;
  cursor: pointer;
}

.butn-two {
  border-radius: 3px;
  background-color: #a91622;
  padding: 8px 30px 8px 30px;
  color: var(--white-color);
  font-family: var(--primary-font-medium);
  font-size: 16px;
  cursor: pointer;
}

.state {
  color: var(--color-text-grey-secondary);
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  letter-spacing: 0.08px;
  line-height: 20px;
}

/*End Required Property*/

/*Landlord Post property*/

.post-property-panel .landlord-stepper,
.post-property-panel .tenant-stepper {
  background: var(--white-color);
  border: 2px solid #333;
  /* box-shadow: 0 0 0 1px #333, 0 1px 2px 0 #333; */
  border-radius: 5px;
  padding-bottom: 8rem;
}

.post-step {
  color: var(--primary-blue-color);
  font-weight: 500;
}

.post-property-panel .landlord-stepper .landlord-stepper-data-active,
.post-property-panel .tenant-stepper .tenant-stepper-data-active {
  color: #0e4196 !important;
}

.post-property-panel .landlord-stepper .landlord-stepper-data,
.post-property-panel .tenant-stepper .tenant-stepper-data {
  color: var(--primary-blue-color);
  font-family: var(--primary-font-medium);
  font-size: 18px !important;
  margin: auto 0;
  padding: 5px;
}

.post-property-panel .landlord-stepper .landlord-stepper-data .data-row,
.post-property-panel .tenant-stepper .tenant-stepper-data .data-row {
  flex-direction: row;
  justify-content: space-between;
}

.post-property-panel .landlord-stepper .landlord-stepper-data .data-row p,
.post-property-panel .tenant-stepper .tenant-stepper-data .data-row p {
  font-family: var(--primary-font-medium);
  color: #333;
  font-size: 19px;
}

.post-property-panel
  .landlord-stepper
  .landlord-stepper-data
  .stepper-check-icon,
.post-property-panel .tenant-stepper .tenant-stepper-data .stepper-check-icon {
  font-size: 1.5rem;
}

.post-property-panel .landlord-stepper .landlord-stepper-data .stepper-icon,
.post-property-panel .tenant-stepper .tenant-stepper-data .stepper-icon {
  margin-right: 5px;
  font-size: 1.5rem;
  color: var(--primary-blue-color);
}

.post-property-panel .post-property-landlord-header .crossred,
.post-property-panel .post-property-tenant-header .crossred {
  position: absolute;
  right: 2.5rem;
  z-index: 25;
  font-size: 40px;
  color: var(--color-text-grey-tertiary);
}

.post-property-panel .post-property-landlord-body .address-body {
  background: var(--white-color);
  width: 100%;
  padding: 60px;
  border-radius: 4px;
}

.post-property-panel
  .post-property-landlord-body
  .address-body
  .address-heading,
.post-property-panel
  .post-property-landlord-body
  .description-body
  .description-heading,
.post-property-panel
  .post-property-landlord-body
  .picture-body
  .picture-heading {
  font-family: var(--primary-font-regular);
  font-size: 25px;
  text-transform: uppercase;
}

.sub-post-title-font,
.post-property-panel .post-property-landlord-body .address-body .address-text,
.post-property-panel
  .post-property-landlord-body
  .description-body
  .description-text,
.post-property-panel .post-property-landlord-body .picture-body .picture-text {
  font-family: var(--primary-font-regular);
  font-size: 18px;
  color: var(--color-text-primary);
}

.post-property-panel .post-property-landlord-body .address-body .label-title,
.post-property-panel
  .post-property-landlord-body
  .description-body
  .label-title,
.post-property-panel .post-property-landlord-body .picture-body .label-title {
  font-family: var(--secondary-font-regular);
  font-size: 14px;
  /* color: rgba(0, 0, 0, 0.54); */
  color: #000;
  text-transform: uppercase;
}

.post-property-panel
  .post-property-landlord-body
  .description-body
  .show-description-text {
  font-family: var(--primary-font-regular);
  font-size: 14px;
  text-decoration: underline;
}

.post-property-panel .post-property-landlord-body .address-body .form-aside,
.post-property-panel .post-property-landlord-body .description-body .form-aside,
.post-property-panel .post-property-landlord-body .picture-body .form-aside {
  width: 100%;
}

.post-property-panel
  .post-property-landlord-body
  .address-body
  .form-aside
  .btn-2,
.post-property-panel
  .post-property-landlord-body
  .description-body
  .form-aside
  .btn-2,
.post-property-panel
  .post-property-landlord-body
  .picture-body
  .form-aside
  .btn-2 {
  background: var(--red-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 3px;
  font-weight: 400;
  /* padding: 15px 25px; */
  margin-top: 70px;
  text-transform: uppercase;
  cursor: pointer;
}

.post-property-panel
  .post-property-landlord-body
  .description-body
  .form-aside
  .btn-1,
.post-property-panel
  .post-property-landlord-body
  .picture-body
  .form-aside
  .btn-1 {
  font-family: var(--primary-font-medium);
  font-size: 16px;
  border: 1px solid #d3dce6;
  color: var(--red-color);
  /* border-radius: 5px; */
  background-color: #fff;
  /* padding: 15px 25px; */
  margin-top: 70px;
  text-transform: uppercase;
}

.post-property-panel .post-property-landlord-body .description-body,
.post-property-panel .post-property-landlord-body .picture-body {
  background: var(--white-color);
  width: 100%;
  padding: 60px;
  border-radius: 4px;
}

.post-property-panel .post-property-landlord-body .picture-body .img-add {
  position: relative;
  border: 2px var(--primary-blue-color) solid;
  height: 100px;
  width: 100px;
  padding: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
}

.post-property-panel .post-property-landlord-body .picture-body .img-add img {
  height: 100%;
}

.post-property-panel .post-property-landlord-body .picture-body .img-add-set {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 5px 5px;
}

.custom-propperty-img-style {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 1px var(--primary-blue-color) solid;
}

.post-property-panel
  .post-property-landlord-body
  .picture-body
  .img-add-set
  span.img-crossed-span {
  position: absolute;
  right: 5px;
}

.post-property-panel
  .post-property-landlord-body
  .picture-body
  .img-add
  .custom-input-add-img {
  position: absolute;
  height: 100%;
  top: 0;
}
.post-property-panel
  .post-property-landlord-body
  .picture-body
  .img-add
  .custom-input-add-img
  input {
  height: 100% !important;
  cursor: pointer;
}
.post-property-panel .post-property-landlord-body .picture-body .board {
  width: 500px;
  height: 200px;
  border: 2px dotted black;
  padding: 30px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  background-color: #255cb7;
}
.post-property-panel .post-property-landlord-body .picture-body .board:hover {
  border: 2px solid black;
}
.post-property-panel .post-property-landlord-body .picture-body .drag-text {
  font-weight: bold;
}
.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumbsContainer .thumb1 {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: auto;
  height: 200px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbsContainer .thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.thumbsContainer .imgon {
  display: block;
  width: auto;
  height: 100%;
}
.custom-input-add-img input {
  height: 100%;
}

/*Landlord Of Tenant Post Property*/

/*POST PROPERTY TENANT BUSSINESS PROFILE*/

.bussiness-profile-body {
  background: var(--white-color);
  width: 100%;
  padding: 60px;
  border-radius: 4px;
}

.bussiness-profile-body .business-profile {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: uppercase;
  font-size: 25px;
  text-align: left;
}

.bussiness-profile-body .tell-about-business {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
}

.bussiness-profile-body .back-btn {
  font-family: var(--primary-font-regular);
  font-size: 16;
  font-weight: 500;
  text-align: center;
  color: var(--secondary-color);
  border-radius: 3px;
  border: 1px solid #d3dce6;
  margin-bottom: 5px;
}

.bussiness-profile-body .next-btn {
  font-family: var(--primary-font-regular);
  font-size: 16;
  font-weight: 500;
  text-align: center;
  color: var(--white-color);
  border-radius: 3px;
  background: var(--red-color);
  border: none;
}

.bussiness-profile-body label {
  font-size: 15px;
  letter-spacing: 0.08px;
  color: var(--black-color);
  font-family: var(--secondary-font-regular);
}

.bussiness-profile-body .type-of-bussiness-select {
  box-sizing: border-box;
  border: 1px solid #d3dce6;
  border-radius: 4px;
  background-color: #ffffff;
  color: #120000;
  font-family: var(--secondary-font-regular);
  box-shadow: 0 0 0 1px rgba(102, 113, 123, 0.25),
    0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

.bussiness-profile-body .name-of-bussiness-input {
  box-sizing: border-box;
  border: 1px solid #d3dce6;
  border-radius: 4px;
  background-color: #ffffff;
  color: #120000;
  font-family: var(--secondary-font-regular);
  box-shadow: 0 0 0 1px rgba(102, 113, 123, 0.25),
    0 1px 2px 0 rgba(102, 113, 123, 0.21);
  font-family: var(--secondary-font-regular);
}

.stepper-panel {
  box-sizing: border-box;
  border-radius: 5px;
  height: 280px;
  width: 334px;
  border: 2px solid #750707;
}

.stepper-panel-text {
  color: #750707;
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-weight: 600;
}

.bussiness-profile-body .long-bussiness-input {
  margin-left: -15px;
  box-sizing: border-box;
  border: 1 px solid #d3dce6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(102, 113, 123, 0.25),
    0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

.bussiness-profile-body .long-bussiness-select {
  box-sizing: border-box;
  border: 1 px solid #d3dce6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(102, 113, 123, 0.25),
    0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

.bussiness-profile-body .how-many-location-input {
  box-sizing: border-box;
  border: 1 px solid #d3dce6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(102, 113, 123, 0.25),
    0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

.bussiness-profile-body .website-url-input {
  box-sizing: border-box;
  border: 1 px solid #d3dce6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(102, 113, 123, 0.25),
    0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

.tooltip-icon {
  margin-bottom: 3px;
  margin-left: 2px;
}

.data-tooltip {
  width: 230px;
  background: rgba(0, 0, 0, 0.62) !important;
  font-family: var(--primary-font-regular) !important;
}

/*END OF POST PROPERTY TENANT BUSSINESS PROFILE*/

.message-design .mxg-box {
  position: absolute;
  bottom: 0%;
  width: 100%;
  background-color: #f7f7f7;
}

.message-design .chat-view {
  height: 750px;
  overflow: auto;
  overflow-x: hidden;
}

.main-card .msg-logo {
  padding-left: 2rem;
}

.message-design .icon-modify {
  padding-left: 2rem;
}

.main-card .search-icon {
  position: absolute;
  right: 3rem;
}

.review-business span.bio-text {
  font-size: 14px;
  color: #605e5ee3;
  font-weight: 400;
}

.review-business span.subscription-detail {
  color: #605e5ee3;
  text-transform: none;
}

.review-business label {
  color: #605e5ee3;
  font-weight: 500;
}

.btn-1 {
  background: var(--white-color);
  /* border: 1px solid#DDE4EC; */
  color: #8a2d2d;
  border-radius: 3px;
  padding: 10px 25px;
  font-weight: 400;
  text-transform: uppercase;
}

.btn-2 {
  background: var(--red-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 3px;
  padding: 10px 25px;
  font-weight: 500;
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.review-business .subs-heading {
  margin-bottom: 40px;
}

.profile .profile-view .review-business .title {
  color: #808080;
  font-size: 1.3rem;
  font-weight: 400;
}

.profile .profile-view .review-business .title-info {
  color: var(--color-text-grey-primary);
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 400;
}

.profile .profile-view .review-business .bio-info {
  font-size: 1rem;
  text-transform: none;
  width: 80%;
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  line-height: 31px;
}

.profile .profile-view .review-business .edit-icon {
  float: right;
  font-size: 2rem;
  border: 2px solid var(--navbar-item-color);
  padding: 7px;
  border-radius: 50%;
  color: var(--navbar-item-color);
  cursor: pointer;
}

.profile .profile-view .review-business .edit-icon-active {
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
}

.profile .profile-view .review-business .top-one {
  border-bottom: 1px solid #a7a7a7;
}

.profile .profile-view .review-business .title1 {
  color: #232221;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
}

.profile .profile-view .review-business .title2 {
  color: #232221;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
}

.profile .profile-view .review-business .title-info1 {
  color: var(--color-text-primary);
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 400;
  margin-top: 0.8rem;
}

.profile .profile-view .review-business span {
  color: var(--color-text-primary);
  text-transform: capitalize;
  padding-right: 1rem;
  font-family: var(--primary-font-regular);
  font-size: 17px;
}

.profile .profile-view .review-business .title3 {
  color: #8f8f8f;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.profile .profile-view .review-business .title-info2 {
  color: #504f4e;
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 400;
  margin-top: 0.8rem;
}

.profile .profile-view .review-business .utilities-btn .utility-btn {
  border: 2px solid #333;
  background: var(--white-color);
  border-radius: 20px;
  padding: 0px 15px;
  font-family: var(--primary-font-medium);
  margin-right: 0.5rem;
}

.profile .profile-view .review-business .title-info3 {
  color: #232221;
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 700;
  margin-top: 0.8rem;
}

.profile .profile-view .review-business .title-info3 span {
  color: #504f4e;
  font-weight: 400;
}

.line-break {
  background-color: #a7a7a7;
}

.main-body-form-square {
  font-family: var(--secondary-font-regular);
  color: var(--color-text-grey-secondary);
  font-size: 16px;
  text-transform: uppercase;
}

.main-body-form {
  width: 100%;
  padding-left: 15px;
}

.main-body-form-square-hight {
  height: 45px;
}

.main-body-radio-buttons {
  padding-left: 15px;
}

.radio-buttons-lable-asking-agent {
  color: #757575;
  font-family: var(--secondary-font-regular);
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 8px;
}

.radio-buttons-lable-negotiable {
  color: #757575;
  font-family: var(--secondary-font-regular);
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 8px;
}

.input-field-lable {
  color: var(--color-text-grey-secondary);
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  padding-left: 20px;
}

/*** review landloard screen start css ***/

.review-landlord-main-body {
  padding: 60px;
  padding-bottom: 45px;
}

.review-landlord-heading {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 25px;
  text-transform: uppercase;
}

.review-landlord-icon {
  color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  float: right !important;
  padding-left: 24px;
}

.review-landlord-description-icon {
  color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  float: right !important;
  position: relative;
  left: 20px;
}

.review-landlord-anenue-heading {
  color: var(--primary-blue-color);
  font-family: var(--primary-font-regular);
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 700;
  padding-left: 15px;
}

.review-landlord-anenue-heading div.second {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
  color: var(--primary-grey-color);
  font-size: 21px;
}

.review-landlord-anenue-heading div.zip {
  font-weight: 400;
  color: var(--primary-grey-color);
  font-size: 18px;
}

.review-landlord-anenue-heading-line-break {
  width: 730px;
  height: 0px;
  border: 1px solid var(--border-primary-color);
  background-color: var(--border-primary-color);
  margin-top: 40px !important;
}

.review-landlord-description-heading {
  color: var(--red-color);
  font-family: var(--primary-font-medium);
  font-size: 20px;
  font-weight: 500;
}

.review-landlord-description-colm {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.review-landlord-parahraph-text {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 15px;
  padding-top: 10px;
}

.review-landlord-sqft-paragraph {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
  text-transform: uppercase;
}

.review-landlord-digits-paragraph {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 20px;
  padding-top: 10px;
}

.review-landlord-lease-paragraph {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-regular);
  font-size: 16px;
  padding-top: 20px;
  text-transform: uppercase;
}

.review-landlord-digits-months-paragraph {
  color: var(--color-text-primary);
  font-family: var(--primary-font-regular);
  font-size: 20px;
  padding-top: 10px;
}

.review-landlord-utility-text {
  color: var(--color-text-grey-tertiary);
  font-family: var(--primary-font-medium);
  font-size: 16px;
  padding-top: 35px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.review-landlord-floorplan-heading {
  color: var(--red-color);
  font-family: var(--primary-font-medium);
  font-size: 20px;
  padding-top: 30px;
  text-transform: uppercase;
}

.review-landlord-floorplan-image {
  padding-left: 0px !important;
  padding-top: 15px;
}

.review-landlord-detalis-utility-buttons {
  border: 1px solid #333;
  border-radius: 8px;
  color: #333;
  border-color: #333;
  border-radius: 30px;
  margin: 2px;
  padding: 2px 12px 0px 12px;
  width: 82px;
  height: 26px;
  text-align: center;
  font-family: var(--primary-font-medium);
  font-size: 13px;
}

.review-landlord-bottom-button-section {
  padding-top: 80px;
}

.signup-wizard .form-aside .visa-icon svg {
  margin-top: 2rem;
  font-size: 2.3rem;
  color: #0066b2;
}

.icon-checkbox-drop-down {
  margin-left: 5px;
  margin-bottom: 5px;
}

.checkbox-class {
  font-family: var(--primary-font-regular);
  color: #4a4a4a;
  margin-left: 10px !important;
}

.listing-page-detail-panel {
  overflow: hidden;
  background: var(--background-color);
}

.home-listing-slider .carousel-user {
  /* height: 299px; */
  position: relative;
  margin: 10px;
}

.listing-page-detail-panel .somerset-avenue-body {
  background: var(--white-color);
  width: 100%;
  padding: 70px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.detail-section-property-detail {
  border: 2px solid #d4c7c7;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-around;
}

.details-pro-sec-first {
  border-right: 1px solid #d4c7c7;
}

.details-pro-sec {
  margin: 10px;
  width: 100%;
  text-transform: uppercase;
}

.title-property-detail {
  font-size: 19px;
  font-family: var(--secondary-font-medium);
}

.listing-page-detail-panel .somerset-avenue-body.details-design {
  background: var(--white-color);
  width: 100%;
  padding: 25px 70px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.listing-page-detail-panel .somerset-avenue-body .somerset-heading {
  font-family: var(--primary-font-medium);
  font-size: 36px;
  text-transform: uppercase;
  word-break: break-word;
  text-align: center;
  color: var(--color-text-grey-primary);
}

.listing-page-detail-panel .somerset-avenue-body .somerset-description {
  font-family: var(--primary-font-medium);
  font-size: 25px;
  color: #bf3b2f;
  text-transform: uppercase;
  padding: 15px 0;
}

.listing-page-detail-panel .somerset-avenue-body .description-text {
  font-family: var(--primary-font-regular);
  font-size: 16px;
  color: var(--color-text-primary);
  /* max-width: 900px; */
  line-height:normal !important;
}

.utility-detail-list {
  margin-bottom: 5px;
  font-size: 15px;
}

.listing-page-detail-panel .somerset-avenue-body .somerset-plot-detail {
  background-color: #6d7278;
  max-width: 284px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
  border-radius: 5px;
}

.cus-padd-btn {
  padding: 0 10px;
}

.somer-plot-detail {
  border: 2px solid #a8000075;
  max-width: 284px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  border-radius: 5px;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .somerset-plot-detail
  .detaile-heading {
  font-family: var(--primary-font-medium);
  font-size: 25px;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .somerset-plot-detail
  .detaile-info {
  font-family: var(--primary-font-medium);
  font-size: 32px;
  color: #ffffff;
  text-transform: uppercase;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .somerset-plot-detail
  .detaile-info2 {
  font-family: var(--primary-font-regular);
  font-size: 25px;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  margin-bottom: 0;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .somerset-plot-detail
  span.detaile-price {
  font-family: var(--primary-font-demi-bold);
  font-size: 32px;
  color: #ffffff;
  text-transform: uppercase;
}

.listing-page-detail-panel .somerset-avenue-body .connect-landloard {
  /* background-color: #A91622; */
  max-width: 284px;
  margin: 0 auto;
  /* padding: 15px 0;
text-align: center; */
  border-radius: 3px;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .connect-landloard
  .detaile-info2 {
  font-family: var(--primary-font-medium);
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0px;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  background-color: #a91622;
}

.listing-page-detail-panel .somerset-avenue-body .Floorplan-description,
.listing-page-detail-panel .somerset-avenue-body .Location-body {
  font-family: var(--primary-font-medium);
  font-size: 25px;
  color: #bf3b2f;
  text-transform: uppercase;
  padding: 15px 0;
}

.somerset-avenue-body .Floorplan-images {
  margin: 5px;
}

.listing-page-detail-panel .somerset-avenue-body .Amenities-detail {
  max-width: 284px;
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 5px;
  padding-top: 15px;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .Amenities-detail
  .detaile-heading {
  font-family: var(--primary-font-medium);
  font-size: 25px;
  color: #bf3b2f;
  text-transform: uppercase;
}

.listing-page-detail-panel
  .somerset-avenue-body
  .Amenities-detail
  ul.list-of-amenities
  li {
  font-family: var(--primary-font-regular);
  font-size: 25px;
  color: var(--color-text-primary);
  line-height: 30px;
}

.list-of-amenities {
  margin: 0;
}

.listing-page-detail-panel .main-success-wrapper {
  background-color: var(--primary-blue-color);
  position: relative;
}

.listing-page-detail-panel .main-success-wrapper .success-body {
  padding: 60px 70px;
}

.listing-page-detail-panel
  .main-success-wrapper
  .success-body
  .success-heading {
  font-family: var(--primary-font-regular);
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 30px 0px;
}

.listing-page-detail-panel
  .main-success-wrapper
  .success-body
  .success-detaile-info {
  font-family: var(--primary-font-medium);
  font-size: 21px;
  color: #ffffff;
}

.listing-page-detail-panel .main-success-wrapper .road-sign-right-side-content {
  position: absolute;
  background-color: #ffffff;
  right: 2rem;
  top: -0.5rem;
  z-index: 4444;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  padding: 90px 70px;
}

.road-sign-right-side-content .detaile-heading {
  font-family: var(--primary-font-regular);
  font-size: 40px;
  color: #000000;
  text-transform: uppercase;
}

.listing-page-detail-panel .road-sign-right-side-content ul.list-of-success li {
  font-family: var(--primary-font-medium);
  font-size: 24px;
  color: var(--color-text-grey-primary);
  line-height: 48px;
}

.SimilarListingSlider-body {
  background: var(--white-color);
  width: 100%;
  padding: 70px 90px;
}

.SimilarListingSlider-body .SimilarListingSlider-heading {
  font-family: var(--primary-font-regular);
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-text-grey-primary);
}

.home-listing-slider .slick-slide {
  margin: 2px !important;
}

.SimilarListingSlider-body .carousel-user {
  height: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  background: var(--white-color);
  border-radius: 7px;
}

.SimilarListingSlider-body .slick-slide {
  margin: 15px;
}

.SimilarListingSlider-body .card-section {
  padding: 1rem;
}

.SimilarListingSlider-body .carousel-user h4 {
  font-size: 1.2rem;
  color: var(--navbar-item-color);
  font-weight: 400;
  font-family: var(--primary-font-regular);
}

.SimilarListingSlider-body .carousel-user p {
  font-size: 13px;
  color: var(--navbar-item-color);
  font-weight: 400;
  font-family: var(--primary-font-regular);
}

.SimilarListingSlider-body .carousel-user h5 {
  font-size: 1.2rem;
  color: var(--navbar-item-color);
  font-weight: 400;
  font-family: var(--primary-font-regular);
}

/* .Floorplan-images {
margin: 5px;
} */

.pop-up-property-detaile-btn {
  color: var(--white-color);
  border-radius: 3px;
  padding: 10px 10px;
  background: var(--red-color);
  text-transform: uppercase;
  margin-right: 10px;
}

/* ************************************************

About us Panel styling start  

****************************************************/

.about-us-panel-body .our-story-body {
  padding: 75px 0;
}

.about-us-panel-body .our-story-body .left-side-content,
.about-us-panel-body .our-services-body .left-side-content,
.about-us-panel-body .our-mission-body .left-side-content {
  text-align: center;
  max-width: 573px;
  margin: 0 auto;
}

.about-us-panel-body .our-story-body .left-side-content h1,
.about-us-panel-body .our-services-body .left-side-content h1,
.about-us-panel-body .our-mission-body .left-side-content h1 {
  font-family: var(--primary-font-demi-bold);
  font-size: 50px;
  color: var(--secondary-color);
  position: relative;
}

/* .about-us-panel-body .our-story-body .left-side-content h1::after, .about-us-panel-body .our-services-body .left-side-content h1::after, .about-us-panel-body .our-mission-body .left-side-content h1::after {
content: "";
position: absolute;
top: 105%;
width: 147px;
height: 4px;
left: 0;
right: 0;
margin: 0 auto;
border-radius: 50px;
background-color: var(--secondary-color);
} */

.about-us-panel-body .our-story-body .left-side-content p,
.about-us-panel-body .our-services-body .left-side-content p,
.about-us-panel-body .our-mission-body .left-side-content p {
  font-family: var(--primary-font-regular);
  font-size: 20px;
  color: var(--color-text-primary);
  margin-top: 40px;
}

.about-us-panel-body .our-story-body .Right-side-content,
.about-us-panel-body .our-services-body .Right-side-content,
.about-us-panel-body .our-mission-body .Right-side-content {
  max-width: 293px;
  margin: 0 auto;
}

.about-us-panel-body .our-story-body .Right-side-content img,
.about-us-panel-body .our-services-body .Right-side-content img,
.about-us-panel-body .our-mission-body .Right-side-content img {
  width: 100%;
}

.about-us-panel-body .our-story-body,
.about-us-panel-body .our-services-body,
.about-us-panel-body .our-mission-body {
  padding: 100px 0;
}

.about-us-panel-body .our-services-body {
  background: #e2dfdf87;
}

/* ************************************************

Forget Password styling start  

****************************************************/

.forgetPassword-wizard {
  background: var(--background-color);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgetPassword-wizard .forgetPassword-wizard-body {
  padding: 3rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.forgetPassword-wizard .forgetPassword-wizard-body .form-aside h3 {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-weight: 400;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

/* ************************************************

Change Password styling start  

****************************************************/

.changePassword-wizard {
  background: var(--background-color);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.changePassword-wizard .changePassword-wizard-body {
  padding: 3rem;
  background: var(--white-color);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.changePassword-wizard .changePassword-wizard-body .form-aside h3 {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-weight: 400;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.container {
display: block;
position: relative;
padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 14px;
color: #757575;
font-family: var(--secondary-font-regular);
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* Hide the browser's default radio button */

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 3px;
  left: 13px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--primary-blue-color);
}

/* On mouse-over, add a grey background color */

.container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

.container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid var(--primary-blue-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container.container-label {
  color: black;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}

.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--primary-blue-color);
}

.view-profile-parent {
  background: var(--background-color);
  padding: 2.6rem 0;
  border-radius: 5px;
}

.scroll-remove-class {
  overflow-y: hidden;
}

.view-profile-body {
  background: var(--white-color);
  margin: 0 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
}

/* profile section start */

.view-profile-parent .profile-image-div {
  margin-top: 62px;
  margin-bottom: 65px;
}

.view-profile-parent .profile-image-div .profile-image {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 2px solid var(--red-color);
  margin-left: 115px;
}

.view-profile-parent .profile-text {
  margin-top: 82px;
  margin-bottom: 86px;
}

.view-profile-parent .profile-text .profile-header-name {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
  margin-left: -40px;
}

.view-profile-parent .profile-text .profile-sub-header-name {
  font-size: 30px;
  text-transform: capitalize;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
  margin-left: -40px;
}

/* profile section end */

/*Bio profile section start */

.view-profile-parent .bio-profile-top-border {
  border-top: 1px solid var(--border-primary-color);
  margin-left: 112px;
  margin-right: 112px;
}

.view-profile-parent .bio-profile-heading {
  text-transform: uppercase;
  margin-top: 30px;
  color: var(--primary-blue-color);
  font-family: var(--primary-font-regular);
  font-size: 25px;
  font-weight: 500;
  margin-left: 114.5px;
}

.view-profile-parent .bio-profile-description {
  margin-top: 9px;
  margin-left: 114.5px;
  margin-right: 114.5px;
  text-align: justify;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.view-profile-parent .bio-profile-bottom-border {
  border-top: 1px solid var(--border-primary-color);
  margin-left: 112px;
  margin-right: 112px;
  margin-top: 59px;
}

/* Bioe profile section end */

.view-profile-parent .view-listing-section {
  background: var(--white-color);
  border-radius: 5px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
}

.view-profile-parent .view-listing-section .avenue-day-listed-row {
  flex-direction: row;
  /* justify-content: space-around; */
  margin-left: -3px;
}

.view-profile-parent
  .view-listing-section
  .avenue-day-listed-row
  .listing-avenue-text {
  font-size: 25px;
  font-weight: bold;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: uppercase;
  margin: auto 0;
}

.view-profile-parent
  .view-listing-section
  .avenue-day-listed-row
  .listing-day-text {
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-tertiary);
  font-size: 18px;
  margin: auto 0;
  margin-left: 1rem;
}

.view-profile-parent .view-listing-section .sqft-psf-row {
  flex-direction: row;
  margin-left: -3px;
  /* justify-content: space-around; */
}

.view-profile-parent
  .view-listing-section
  .sqft-psf-row
  .listing-1500-sqft-text {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  font-size: 20px;
  padding-bottom: 1rem;
}

.verified-icon {
  height: 20px;
  /* width: 25px; */
  margin-left: 5px;
}

.view-profile-parent .view-listing-section .sqft-psf-row .listing-60-psf-text {
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  font-size: 20px;
  text-transform: uppercase;
}

.per-sqft {
  margin-left: 48px;
}

.view-profile-parent .view-listing-section .listing-view-btn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  color: var(--white-color);
  background: var(--red-color);
  text-align: center;
  margin: auto;
  padding: 10px 25px;
  border: none;
  text-transform: uppercase;
  margin-bottom: 5px;
}

/* tenent leslee profile section */

.view-profile-parent .leslee-img {
  height: 130px;
  width: 130px;
}

button#change:disabled {
  cursor: no-drop;
  background: #5d83c1 !important;
}

.disable-btn {
  cursor: no-drop;
}

.image-div {
  height: 250px;
  width: 250px;
  overflow: hidden;
}

.leslee-img img {
  width: 100%;
  border: 2px var(--primary-blue-color) solid;
  border-radius: 50%;
  position: relative;
  padding: 0px;
}

.view-profile-parent .user-name .leslee-profile-header-name {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
}

.view-profile-parent .user-name .tenent-profile-sub-header-name {
  font-size: 30px;
  text-transform: capitalize;
  color: var(--color-text-grey-primary);
  font-family: var(--primary-font-regular);
}

.view-profile-parent .leslee-invite-btn {
  border-radius: 3px;
  background: var(--red-color);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-family: var(--primary-font-regular);
  color: var(--white-color);
  border: none;
  padding: 10px 25px;
  text-transform: uppercase;
  margin-top: 1rem;
}

.view-profile-parent .tenant-logo .tea-mug-tenant {
  height: 130px;
  width: 130px;
}

/* tenent profile section end */

.view-profile-parent .bio-profile-border {
  border-top: 1px solid var(--border-primary-color);
  margin-left: 7rem;
  margin-right: 7rem;
}

/* business profile section */

.view-profile-parent .form-a-business-profile {
  margin-left: 8rem;
  margin-top: 30px;
}

.view-profile-parent .form-a-business-profile .business-profile-heading {
  font-size: 25px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--primary-blue-color);
  text-transform: uppercase;
}

.view-profile-parent .form-a-business-profile .title-grind-cofee {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.view-profile-parent .form-a-business-profile .title-grind-cofee-webiste {
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.view-profile-parent .form-a-business-profile .title-owner {
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: capitalize;
}

.view-profile-parent .form-a-business-profile .title-cofee-shop {
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: capitalize;
}

.view-profile-parent .form-a-business-profile .title-cofeeshop-info {
  font-size: 20px;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
  text-transform: capitalize;
}

/* business profile section */

/* Requirement sectiion */

.view-profile-parent .form-a-requirement-section {
  margin-left: 7.3rem;
  margin-top: 30px;
}

.view-profile-parent .form-a-requirement-section .requirement-profile-heading {
  font-size: 25px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--primary-blue-color);
  text-transform: uppercase;
}

.view-profile-parent .form-a-requirement-section .soft-required-text {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-tertiary);
}

.view-profile-parent .form-a-requirement-section .soft-required-text-info {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.view-profile-parent .form-a-requirement-section .Budget-sqft-text {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-tertiary);
}

.view-profile-parent .form-a-requirement-section .Budget-sqft-text-info {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-primary);
}

.view-profile-parent .form-a-requirement-section .utilities-heading {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--primary-font-regular);
  color: var(--color-text-grey-tertiary);
}

.view-profile-parent .form-a-requirement-section .utilities-btn .utility-btn {
  padding: 0px 15px;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--primary-font-regular);
  color: var(--primary-grey-color);
  text-align: center;
  border-radius: 15px;
  border: 1px solid var(--primary-grey-color);
  margin-left: 0.5rem;
  background: var(--white-color);
}

/* end requirement section */

.view-profile-parent .bio-margin-left {
  margin-left: 6.1rem;
}

.to-from-input {
  padding: 5px;
}

.social-icons-parent {
  display: flex;
  justify-content: flex-end;
}

.social-icons-parent span {
  margin: 10px;
}

/*******************************************

Start Styling SideBar

********************************************/

button.cross-btn-navadmin {
  display: none;
}

.active-flyer {
  border: 2px solid var(--primary-color);
}

.filter-search-btn {
  text-align: center;
  background: white;
  padding: 10px 25px;
  font-family: var(--primary-font-regular);
  border-radius: 5px;
  color: #000;
}

.filter-search-btn:hover {
  border: 1px solid var(--primary-blue-color);
}

.list-drop {
  word-break: break-all;
  white-space: break-spaces;
}

.send-email-button {
  background: var(--red-color);
  color: white;
  padding: 7px;
  border-radius: 5px;
}

.suspend-button {
  background: var(--red-color);
  color: white;
  width: 100%;
  padding: 7px;
  text-align: center;
  border-radius: 5px;
}

button.menu-btn.menu-btn-link {
  display: none;
  background: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-family: var(--primary-font-medium);
}

.admin-margin-left {
  margin-left: 260px;
}

.admin-navbar-style {
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 4.2rem;
  background: #f2f4f4;
}

.SidebarLink.active,
.subnav-link.active {
  background: var(--white-color);
  color: var(--primary-color);
  letter-spacing: 0.9px;
}

.admin-dashboard-card-height {
  height: 580px;
  max-height: 580px;
}

.sidebar {
  width: 260px;
  color: #727e8c;
  background: #f2f4f4;
  border: #dfe3e7;
  border-right: 1px solid #dfe3e7;
  /* width: 170px;
background: var(--primary-color);
color: var(--white-color); */
  height: 100%;
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 0;
  z-index: 999999;
  overflow: auto;
}

.height-100 {
  height: 100%;
}

.sidebar .redhanded-text-set,
.without_sidebar .redhanded-text-set {
  text-align: center;
  margin-top: 1rem;
  padding-left: 15px;
}

.redhanded-text-set {
  display: flex;
  justify-content: space-around;
}

.sidebar .redhanded-text-set .brand-logo,
.without_sidebar .redhanded-text-set .brand-logo {
  width: 35px;
}

.sidebar .redhanded-text-set h3 {
  margin: 0 0 0 8px;
}

.without_sidebar .redhanded-text-set h3 {
  margin: 0 0 0 8px;
  color: #727e8c;
}

.InfoContainer {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.UserImage {
  width: 35px;
  height: 35px;
  background: var(--white-color);
  border-radius: 50%;
  color: var(--primary-color);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.userStatus {
  color: white;
  margin: auto;
  margin-left: 6px;
}

.Info {
  padding-left: 8px;
  font-size: 21px;
  font-family: var(--primary-font-regular);
}

.SidebarLink {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  text-decoration: none;
  padding: 8px 5px 8px 15px;
  transition: 0.5s;
}

.table-heading-report {
  color: var(--red-color);
  font-family: var(--primary-font-regular);
  text-transform: uppercase;
}

.report-headinng {
  padding: 25px 5px 8px 15px;
  color: var(--primary-color);
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
}

.report-data-des {
  padding: 0px 5px 0px 15px;
  /* color: var(--primary-color); */
  font-family: var(--primary-font-medium);
  text-align: center;
  font-size: 20px;
}

.SidebarLink:hover,
.subnav-link:hover {
  text-decoration: none;
  background: var(--white-color);
  color: var(--primary-color);
  letter-spacing: 0.9px;
}

.AnchorContent {
  display: flex;
  align-items: center;
}

.SidebarLabel {
  margin-left: 10px;
}

/* .sidebar.disappear {
left: 0;
} */

.subnav-link {
  position: relative;
  display: flex;
  /* color: white; */
  justify-content: flex-start;
  font-size: 12px;
  align-items: center;
  list-style: none;
  text-decoration: none;
  padding: 8px 5px 8px 30px;
}

/* .disappear .SidebarLabel {
display: none;
}
.disappear .Info{
display: none;
}
.disappear .signOutContent{
font-size: 34px;
}
.disappear .AnchorContent{
font-size: 34px;
} */

.scrolled-navbar {
  position: fixed;
  top: 0;
  z-index: 99999;
  background-color: #fff;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.signOutContent {
  display: flex;
  color: white;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 5px 8px 15px;
  transition: 0.5s;
  cursor: pointer;
}

.signOutContent:hover {
  background: var(--white-color);
  color: var(--primary-color);
  letter-spacing: 0.9px;
}

/****************************************

Admin Panel Tenant Dashboard Style

*****************************************/

.admin-main-parent {
  background: #f2f4f4;
}

.admin-tenant-dashboard-header-list-detail {
  margin: 0 auto;
  padding: 0 58px;
}

.admin-tenant-dashboard-header-list-detail .connection-listing-body {
  background: var(--white-color);
  width: 100%;
  padding: 15px 0;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.admin-tenant-dashboard-header-list-detail .search-filter-body {
  background: var(--red-color);
  padding: 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
}

.admin-tenant-dashboard-header-list-detail
  .connection-listing-body
  .connection-list-part {
  margin: 0 4rem;
}

.admin-tenant-dashboard-header-list-detail
  .search-filter-body
  .custom-toggle-button {
  background: #fff;
  color: #b5b5b5;
  display: flex;
  justify-content: space-between;
}

.admin-tenant-dashboard-header-list-detail
  .search-filter-body
  .custom-toggle-button
  .custom-chevron-margin {
  margin-top: 5px;
}

.admin-tenant-dashboard-header-list-detail
  .search-filter-body
  input.search-bar-nav {
  height: 42px;
}

.main-login-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-body-inner {
  padding: 15px 20px;
}

.login-close-icon {
  font-size: 22px !important;
}

.login-modal-fields {
  border: 1px solid #948383 !important;
  border-radius: 0px !important;
  padding: 20px 12px !important;
}

.login-modal-social-fields {
  border: none !important;
  border-radius: 0px !important;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
  background-color: #000000;
}

.login-body-field {
  border-radius: 5px;
}

.login-separation {
  position: relative;
  border-bottom: 2px solid #b6b6b6;
  margin-top: 40px;
}

.separation-text {
  height: 40px !important;
  margin: 0px;
  position: absolute;
  left: 45%;
  bottom: -19px;
  width: 40px !important;
  background: #fff;
  border: 2px solid #b6b6b6 !important;
  text-align: center;
  border-radius: 20px;
  padding: 5px 6px;
  font-size: 17px;
  font-weight: 600;
}

.btn-login-1 {
  background: var(--white-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 5px;
  background-color: #dc4e41;
  padding: 10px 26px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-login-2 {
  background: #3b5998;
  /* border: 1px solid#DDE4EC; */
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--white-color);
}

.btn-login-3 {
  background: #007ab9;
  /* border: 1px solid#DDE4EC; */
  color: var(--red-color);
  border-radius: 5px;
  padding: 10px 32px;
  font-weight: bold;
  color: var(--white-color);
  text-transform: uppercase;
}

.btn-login-4 {
  background-color: var(--red-color);
  /* border: 1px solid#DDE4EC; */
  color: var(--white-color);
  border-radius: 5px;
  padding: 10px 26px;
  font-weight: bold;
  text-transform: uppercase;
}

.admin-dashboard-container {
  padding-top: 5rem;
}

.admin-dashboard-container .dashboard-greetings .card,
.admin-dashboard-container .dashboard-visit .card,
.admin-dashboard-container .dashboard-users-success .card,
.admin-dashboard-container .dashboard-users-danger .card,
.admin-dashboard-container .dashboard-revenue-growth .card,
.admin-dashboard-container .dashboard-order-summary .card {
  margin-bottom: 1.7rem;
  border: 0 solid #dfe3e7;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
}

.admin-dashboard-container .dashboard-greetings .card .card-header,
.admin-dashboard-container .dashboard-visit .card .card-header,
.admin-dashboard-container .dashboard-users-success .card .card-header,
.admin-dashboard-container .dashboard-revenue-growth .card .card-header,
.admin-dashboard-container .dashboard-order-summary .card .card-header {
  padding: 1.4rem 1.7rem;
  border: 0 solid #dfe3e7;
  background-color: transparent;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.padding-left-nav-parent {
  padding-left: 260px;
}

.nav-admin-right-icon {
  position: absolute;
  right: 2%;
}

.admin-dashboard-container
  .dashboard-users-danger
  .badge-circle.badge-circle-lg {
  height: 48px;
  width: 48px;
}

.admin-dashboard-container
  .dashboard-users-success
  .badge-circle.badge-circle-lg {
  height: 48px;
  width: 48px;
}

.admin-dashboard-container
  .dashboard-users-success
  .badge-circle-light-success {
  background-color: rgba(57, 218, 138, 0.17);
  color: #39da8a;
}

.admin-dashboard-container .dashboard-users-danger .badge-circle-light-danger {
  background-color: rgba(255, 91, 92, 0.17);
  color: #ff5b5c;
}

.admin-dashboard-container .dashboard-users-success .badge-circle {
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  align-items: center;
}

.admin-dashboard-container .dashboard-users-danger .badge-circle {
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  align-items: center;
}

.admin-dashboard-container .dashboard-greetings .discription {
  padding-top: 2rem;
  margin: 0 auto;
}

.admin-dashboard-container .dashboard-greetings .discription p {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.admin-dashboard-container .dashboard-visit .visit-card-set {
  padding: 4.9rem 1.25rem;
}

.reports-dashboard-body .card {
  margin-bottom: 2.2rem;
  border: 0 solid #dfe3e7;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
}

.reports-dashboard-body .card .card-header {
  padding: 1.4rem 1.7rem;
  border: 0 solid #dfe3e7;
  background-color: transparent;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.landlord-connect-heading {
  text-transform: uppercase;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  /* color: var(--red-color); */
}

#invitation-modal .modal-content {
  width: 600px;
}

.terms-conditions-parent {
  background-color: var(--color-background-grey);
}

.terms-conditions-body-right {
  background: #ffffff;
  box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
  padding: 30px;
  min-height: 650px;
}

.terms-conditions-parent .terms-conditions-body-right .divider-line {
  background: #e0e0e0;
  height: 1px;
  margin: 20px 0;
}

.terms-conditions-body-left ul {
  list-style-type: none;
  padding: 0;
}

.terms-conditions-body-left ul li {
  padding: 10px 0 10px 15px;
  font-size: 20px;
}

.terms-conditions-body-left ul li:hover {
  background: #f9f9f9;
}

.sub-terms-heading {
  font-size: 18px;
  font-family: "AvenirNextMedium";
  font-weight: 500;
}

.terms-listing {
  font-size: 20px;
  font-weight: 700;
}

.terms-conditions-body-left ul li.active-tab {
  background: #f9f9f9;
  border-left: 3px solid var(--primary-color);
}

.cursor-pointer {
  cursor: pointer;
}

.terms-conditions-parent .terms-conditions-body-right p span,
.terms-conditions-parent .terms-conditions-body-right ul li span {
  font-weight: 700;
}

.carousel-user .myClassname {
  border-radius: 12px;
}

.carousel-user .video-icon {
  opacity: 0.8;
  font-size: 2rem;
  cursor: pointer;
}

.login-body-inner .error-message-generic p {
  width: 275px;
  text-align: left;
}

.second-top-bar-bg-gradiant {
  background: #ffffff;
  background: -webkit-linear-gradient(left, #ffffff, var(--primary-color));
  background: -moz-linear-gradient(left, #ffffff, var(--primary-color));
  background: linear-gradient(to right, #ffffff, var(--primary-color));
}

.drop-item-style {
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.sort-filter {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold !important;
  white-space: break-spaces;
  font-family: var(--secondary-font-regular);
  /* font-style: italic; */
  color: #000;
  border: 1px solid var(--border-primary-color);
  border-radius: 4px;
}

.navbar-link {
  font-size: 12px !important;
}

.b-right {
  border-right: 1px solid white !important;
}

.red-handed-search-icon {
  color: #a91622;
  font-size: 25px;
}
.cart {
  position: absolute;
  bottom: 33px;
  right: 23px;
  top: -1px;
}
.hr-noti {
  margin-bottom: 0px;
}

.notifi-time {
  position: absolute;
  bottom: -16px;
  right: 6px;
  font-size: 14px;
  color: gray;
}

.red-handed-bell-icon {
  position: relative;
}

.red-handed-read-dot-icon {
  font-size: 11px;
  position: absolute;
  right: -2px;
  color: var(--primary-color);
  top: 5px;
}

.red-handed-read-dot-icon-seen-unseen {
  font-size: 11px;
  margin-right: 5px;
}

.icon-notify {
  margin: auto;
  border-left: 1px solid #797979;
  padding: 10px;
  /* width: 20%; */
}

.content-notify {
  width: 80%;
}

.notifyStatus {
  margin-left: 1rem;
  font-size: 13px;
}

.notifyName {
  color: var(--black-color);
  font-family: var(--primary-font-demi-bold);
}

.red-handed-dot-icon {
  font-size: 11px;
  position: absolute;
  right: -2px;
  color: var(--primary-blue-color);
  top: -4px;
}

.border-b-0 {
  border-bottom: 0px !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.bg-transperant {
  background-color: transparent !important;
}

.search-container-parent {
  position: relative;
}

.search-container-child {
  position: absolute;
  top: 11px;
  right: 12px;
}

@media (min-width: 992px) {
  .h-55 {
    height: 55px !important;
  }
}

@media (min-width: 1200px) {
  .px-40 {
    padding: 0px 8px !important;
  }
}

.upgrade-btn {
  background-color: var(--d-blue);
  border-color: var(--d-blue);
  border-radius: 7px;
  margin-right: 10px;
  width: fit-content;
  margin-left: 15px;
}

.notification-count {
  position: absolute;
  top: -10px;
  left: 18px;
  font-size: 11px;
  font-weight: bold;
  background: var(--red-color);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-body .dropdown-item {
  white-space: break-spaces !important;
}

.height_adjust {
  max-height: 700px;
  height: 100%;
  overflow: auto;
}

.object_fit_set {
  object-fit: cover;
}

.imp_text_img {
  color: var(--red-color);
  font-style: italic;
  font-size: 14px;
}

.height_adjust_listing {
  padding-top: 5rem !important;
}

.demographic-icon-margin {
  margin-right: 8px;
}

.demographics_table thead {
  background-color: #d2d2d2;
}

.demographics_table {
  border-collapse: separate;
  border-spacing: 0 15px;
  border-radius: 3px;
}

.demographics_table tr {
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  font-family: var(--primary-font-regular);
  font-size: 19px;
  color: var(--color-text-primary);
}

.demographics_table td,
.demographics_table th {
  border: 0 !important;
}

.display-none {
  display: none !important;
}

.signage-header {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 100%;
  box-shadow: 0 0 15px 1px rgb(0 0 0 / 7%);
}

.signage-header-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signage-card-img {
  border: 3px solid black !important;
  opacity: unset !important;
  background: unset !important;
  background-color: black !important;

  padding-left: 2px;
  margin-right: 5px;
}

.signage-logo {
  max-width: 100%;
  display: block;
  height: auto !important;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-left: 30px;
}

@media only screen and (min-width: 1600px) {
  .signage-logo {
    margin: unset !important;
  }
}

.email-envelope {
  font-size: 16px;
  color: #5caffd !important;
  margin-right: 5;
}

.text-navbar:hover envelope {
  color: #7cbefc !important;
}

.text-navbar :hover {
  color: #6c757d !important;
}

.pointer {
  cursor: pointer;
}

.signage-header-info {
  padding: 0px 46px;
  font-size: 14px;
  font-family: "Montserrat", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  margin-bottom: 0;
}

.envelope {
  margin-right: 5px;
  color: #1187f6;
}

.position-relative {
  position: relative;
  /* top: 100px; */
}

.position-relative-signange {
  position: relative;
  top: 100px;
}

.position-relative {
  position: relative;
  /* top: 100px; */
}

.end-commas {
  color: #007bff !important;
  font-size: 3vw;
  position: relative;
  top: 1.5vh;
  line-height: 1vh;
}

.start-commas {
  color: #007bff !important;
  font-size: 3vw;
  position: absolute;

  top: -20px;
  left: -1vw;
}

.top-100 {
  top: 100px;
}

.footer-height {
  height: 60px !important;
  display: flex !important;
  justify-content: center !important;
  background-color: black !important;
}

.bottom-bar {
  font-size: 13px;
  line-height: 1.4em;
  color: #eeeeee;
  font-family: "Roboto", Helvetica, Arial, Verdana, sans-serif;
}

.card-h1 {
  color: #000000;
  font-family: "Montserrat", Sans-serif;
  font-size: 30px;
  font-weight: 600;
}

.h-80 {
  height: 80%;
}

.carousel-template-div {
  height: 80%;
  margin: auto;
  /* display: flex; */
  justify-content: center;
}

.card-text {
  color: #000000;
  font-family: "Lato", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  width: 93%;
}

.signage-section {
  position: relative;
  top: 110px;
  background-image: url("https://technaticx.com/dev/landlord/wp-content/uploads/2022/02/Picture6.jpg");
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto !important;
  width: 100%;
  position: relative;
  /* top: 110px; */
}

.signage-section-first-parent {
  height: 500px;
  background-color: transparent;
  background-image: linear-gradient(30deg, #a80000 60%, #ffffff 50%);
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.signage-section-data {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.signage-section-data-first-child {
  display: flex;
  margin: 0px 125px;
  position: relative;
  top: 126px;
  align-items: center;
}

.signage-img {
  width: 117px;
  height: 121px !important;
}

.signage-header-div {
  color: white;
  margin-left: 42px;
}

.signage-header-div .text-1 {
  font-size: 43px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin: 0px;
  color: #ffffff;
  margin-bottom: 2px;
}

.text-navbar {
  font-weight: 500;
  color: #000;
  font: 14px / 18px "Montserrat", Helvetica, Arial, Verdana, sans-serif !important;
  font-family: "Montserrat", Helvetica, Arial, Verdana, sans-serif !important;
  font-style: normal !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
  font-stretch: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.signage-header-div .text-2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  margin: 10px 0px;
}

.signage-detail-section {
  position: relative;
  top: 122px;
  margin-left: 17px;
  width: 65%;
  font-size: 18px;
  font-weight: 600;
  color: white;
  font-family: "Lato", sans-serif;
  display: flex;
}

.window-signage-parent {
  display: flex;
  justify-content: center;
}

.download-btn-icon {
  margin-right: 5px;
  font-size: 18px;
  width: auto !important;
}

.download-button-icon {
  text-decoration: none !important;
  margin-right: 5px;
  font-size: 18px;
  width: auto !important;
}
.card-left-border {
  box-shadow: 29px 29px 60px 0px rgb(0 0 0 / 20%);
  margin-top: 3rem;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-radius: 0;
  border-left: 5px solid #a80000;
}

.window-signage-parent section {
  box-shadow: 29px 29px 60px 0px rgb(0 0 0 / 20%);
  border-left: 6px solid #b20002;
  margin-top: 3rem;
  /* top: 110px; */
  background-image: url("https://technaticx.com/dev/landlord/wp-content/uploads/2022/02/4.jpg");
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px !important;
  width: 90%;
  position: relative;
}

.window-signage-parent section div.first-child {
  height: 400px;
  background-color: #ffffff;
  opacity: 0.9;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.window-signage-parent section div.sec-child {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
}

.window-signage-parent section div.sec-child .table-style {
  max-width: 100%;
  font-size: 16px;
}

.window-signage-parent section div.sec-child .table-style tr {
  display: flex;
  align-items: flex-start;
}

.window-signage-parent section div.sec-child .table-style tr td:first-child {
  width: 70%;
  margin: 0px 60px;
}

.window-signage-parent
  section
  div.sec-child
  .table-style
  tr
  td.width-custom-table {
  width: 30%;
}

.signage-detail-header {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
}

.signage-detail-text-1 {
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
}

.signage-detail-text-2 {
  color: #1187f6;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
  line-height: 32px;
}

.card1-bg-image {
  background-image: url("../../../src/assets/images/card1-bg.jpg");
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto !important;
  width: 100%;
  position: relative;
  background-attachment: fixed;
}

.download-btn {
  background-color: #a80000;
  border: none;
  color: white;
  padding: 15px 32px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}
.download-button {
  text-decoration: none !important;
  background-color: #a80000;
  border: none;
  color: white !important;
  padding: 15px 32px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.card2-bg-image {
  background-image: url("../../../src/assets/images/card2-bg.jpg");
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto !important;
  width: 100%;
  position: relative;
  background-attachment: fixed;
}

.card3-bg-image {
  background-image: url("../../../src/assets/images/card3-bg.jpg");
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto !important;
  width: 100%;
  position: relative;
  background-attachment: fixed;
}

.card4-bg-image {
  background-image: url("../../../src/assets/images/card4-bg.jpg");
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto !important;
  width: 100%;
  position: relative;
  background-attachment: fixed;
}

.bg-color-opacity {
  background-color: rgba(255, 255, 255, 0.9);
}

.signage-navbar {
  height: 110px;
  position: fixed;
  z-index: 400;
  width: 100%;
  transition: all 0.1s ease 0s;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.signage-footer {
  background-color: #1a1c20;
  height: 65px;
  position: relative;
  margin: auto;
}

.font-fm {
  font-family: "Montserrat", sans-serif !important;
  font-family: "Roboto", sans-serif !important;
}

.signage-footer-first-parent {
  margin: 0px 76px;
  position: relative;
  top: 25px;
}

.signage-footer-text {
  color: white;
  font-size: 15px;
}

/* media query for signage page  */
@media (max-width: 830px) and (min-width: 280px) {
  .signage-logo {
    margin-left: 5px !important;
    width: 50% !important;
  }

  .email-div {
    display: none !important;
  }

  .slider-img-text {
    flex-direction: column !important;
  }

  .signage-section-data-first-child {
    display: flex;
    margin: 0px !important;
    position: relative;
    top: 70px;
    align-items: left;
  }

  .signage-header-div {
    margin-left: 0px;
  }

  .signage-detail-section {
    /* position: relative;
top: 122px; */
    margin-left: 0px;
    /* width: auto; */
    font-size: 10px;
    font-weight: 600;
    color: white;
    font-family: "Lato", sans-serif;
    display: flex;
  }

  .slider-text {
    display: block;
    position: relative;
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 12px;
  }
}

/* *********************Landing Page CSS******************************* */

.sign-up-btn {
  background-color: black !important;
}

.sign-up-login-btn {
  height: 40px !important;
  width: 90px !important;
  border-radius: 5px !important;
  text-transform: none !important;
  font-size: medium !important;
}

.bg-section-1 {
  background-image: url("../../assets/images/sec1Bg.png");
  height: 100vh;
  min-width: 100%;
  max-width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
  padding-top: 0px;
}

.font-size-60 {
  font-size: 62px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-15 {
  font-size: 15px !important;
  font-family: "Montserrat", sans-serif;
}

.font-size-35 {
  font-size: 36px !important;
}

.font-size-30 {
  font-size: 33px !important;
}

.font-size-45 {
  font-size: 50px !important;
}

.bg-section-2 {
  background-image: url("../../assets/images/sec2BG.png");
  min-height: 100vh;
  max-height: 150vh;
  min-width: 100%;
  max-width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
  position: relative;
  z-index:-1;
}

.bg-section-4 {
  background-image: url("../../assets/images/sec4Bg.png");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-section-3 {
  background-image: url("../../assets/images/sec3Bg.png");
  min-height: 100vh;
  min-width: 100%;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
}

.main-parent {
  overflow-x: hidden !important;
}

.h-60 {
  height: 55px !important;
}

.w-60 {
  width: 60% !important;
}

.w-53 {
  width: 53% !important;
}

.line-height-30 {
  line-height: 30px;
}

.font-17 {
  font-size: 17px;
}

.btn-light-bg {
  background-color: white !important;
  border-color: white !important;
  font-weight: 500 !important;
}

.btn-velvet-bg {
  background-color: #a80000 !important;
  border-color: #a80000 !important;
  color: white;
}

.height-width {
  height: 50px;
  width: 150px;
  border-radius: 7px !important;
}

.cus-padding-btn-style {
  padding: 15px 25px !important;
  border-radius: 7px !important;
}

.sec1Bg {
  background-color: #08395d;
  border-radius: 10px;
  /* height: 85px; */
  padding: 0px 30px;
}

.btn-padding {
  padding: 0.375rem 1.2rem !important;
}

.font-500 {
  font-weight: 500 !important;
}

.navbar-icon-text {
  color: #a80000 !important;
  font-size: xx-large;
  font-weight: bold;
}

.footer-div {
  background-color: black;
  color: white;
  /* bottom: vh !important; */
}

.icon-color {
  background-color: #a80000;
  color: white;
  border-radius: 50%;
  height: 1.5vw;
  width: 1.5vw;
}

.icon-color-2 {
  background-color: white;
  color: #a80000;
  border-radius: 50%;
  height: 1.5vw;
  width: 1.5vw;
}

.card-width {
  min-width: 300px !important;
}

.arrow-div-right {
  right: 15px;
}

.arrow-color {
color: #3a3939 !important;
border-radius: 50%;
background-color: white;
box-shadow: 0px 2px 5px #ccc;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.arrow-div {
  padding-top: 10px;
  padding-bottom: 10px;
  /* width: 19%; */
  background: #f6f6f680;
  height: 175vh;
  color: #3a3939;
  position: absolute;
  top: 0;
  z-index: 1;
  font-size: 40px;
}

.text-blue {
  color: #00acff !important;
}

.comma-blue {
  color: #007bff !important;
}

.text-lightblue {
  color: #88afcc !important;
}

.inverted-commas {
  font-size: 37px;
  font-weight: bold;
  padding: 0;
}

mark {
  background-color: white;
  color: black;
}

.small-carousel {
  transform: scale(0.95) !important;
}

.no-visibility {
  visibility: hidden;
}

.slick-dots {
  position: absolute;
  background-color: transparent !important;
  bottom: 7px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #458aee !important;
}

.slick-dots li button:before {
  font-family: "slick";
  border: 1px solid #458aee !important;
  font-size: 1vw !important;
  border: 1px #458aee !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-dark-blue {
  color: #08395d !important;
  font-weight: bolder !important;
}

.muted-text {
  font: normal normal normal 20px/49px;
  letter-spacing: 0px;
  color: #808080;
}

.position-top {
  position: relative;
  top: 3vh !important;
}

.column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.img-size {
  width: 72px;
  height: 74px;
}

.sec5-card-position {
  position: absolute !important;
}

.height-13vh {
  height: 13vh !important;
}

.font-outfit {
  font-family: outfit !important;
}

.height-15vh {
  height: 15vh !important;
}

.margin-right-2vh {
  margin-right: 2vh !important;
}

.border-radius-2vh {
  border-radius: 2vh !important;
}

.height-9vh {
  height: 9vh !important;
}

.height-11vh {
  height: 11vh !important;
}

.footer-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.footer-link {
  margin: 0% 1% 0% 1%;
  word-wrap: normal;
}

/* ********************Responsiveness of Landing Page************************ */
@media (max-width: 992px) {
  .footer-flex {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: baseline;
    width: 18%;
    height: 33vh !important;
  }

  .footer-div {
    height: 33vh !important;
  }

  .margin-left-5vw {
    margin-left: 3vw !important;
  }
}

@media (max-width: 800px) and (min-width: 350px) {
  .margin-left {
    margin-left: 15px !important;
  }

  /* .slick-slide {
width: 248px !important;
} */

  .sec5-card-position {
    position: inherit !important;
  }

  .img-size {
    width: 32px;
    height: 34px;
  }

  .margin-top-bottom {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .h1-font-size {
    font-size: larger;
  }

  .h4-font-size {
    font-size: medium;
  }

  .h5-font-size {
    font-size: small;
  }

  .font-smaller {
    font-size: smaller;
  }

  .min-height-auto {
    min-height: auto !important;
  }

  .card-lower-height {
    height: auto !important;
  }

  .arrow-div {
    background: unset !important;
  }

  .sec1Bg {
    /* height: 72px; */
  }

  .w-60 {
    width: 85% !important;
  }

  .margin-padding-0 {
    margin: 0px !important;
    padding: 0px !important;
  }

  .margin-top-padding-0 {
    margin-top: 5px !important;
    padding: 0px !important;
  }

  .line-height-30 {
    line-height: unset !important;
  }

  .vertical-flex {
    display: flex !important;
    flex-direction: column;
    width: auto !important;
  }

  .width-margin {
    width: 100% !important;
    margin: 0% !important;
  }

  .horizontal-flex {
    display: flex !important;
  }

  .align-unset {
    align-self: unset !important;
  }

  .h-60 {
    height: 45px !important;
  }

  .font-small {
    font-size: small;
  }

  .font-medium {
    font-size: medium;
  }

  .column {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .column-flex {
    display: flex;
    flex-direction: column !important;
  }

  .heading1 {
    font-size: large;
    margin-top: 109px;
  }

  .section2-heading1 {
    font-size: larger;
    width: 100% !important;
    margin-top: 5rem !important;
    margin-bottom: 5px !important;
  }

  .section2-heading5 {
    font-size: small;
  }

  .w-53 {
    width: 100% !important;
  }

  .navbar-items {
    /* height: 100px !important; */
    display: flex;
    flex-direction: column;
  }

  .form-div {
    display: flex !important;
    flex-direction: row !important;
  }

  .bg-section-2 {
    height: auto !important;
  }

  .carousel-body {
    height: auto !important;
    padding: 0% !important;
  }

  .inverted-commas {
    font-size: 26px;
  }

  .arrow-div {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    font-size: 40px;
  }
}

/*******************Responsiveness of Landing Page***********************/
@media (max-width: 992px) {
  .footer-flex {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: baseline;
    width: 18%;
    height: 33vh !important;
  }

  .footer-div {
    height: 33vh !important;
  }
}

@media (max-width: 800px) and (min-width: 350px) {
  .margin-left {
    margin-left: 15px !important;
  }

  /* .slick-slide {
width: 248px !important;
} */

  .sec5-card-position {
    position: inherit !important;
  }

  .img-size {
    width: 32px;
    height: 34px;
  }

  .margin-top-bottom {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .h1-font-size {
    font-size: larger;
  }

  .h4-font-size {
    font-size: medium;
  }

  .h5-font-size {
    font-size: small;
  }

  .font-smaller {
    font-size: smaller;
  }

  .min-height-auto {
    min-height: auto !important;
  }

  .card-lower-height {
    height: auto !important;
  }

  .arrow-div {
    background: unset !important;
  }

  /* .sec1Bg {
height: 72px;
} */

  .w-60 {
    width: 60% !important;
  }

  .margin-padding-0 {
    margin: 0px !important;
    padding: 0px !important;
  }

  .margin-top-padding-0 {
    margin-top: 5px !important;
    padding: 0px !important;
  }

  .line-height-30 {
    line-height: unset !important;
  }

  .vertical-flex {
    display: flex !important;
    flex-direction: column;
    width: auto !important;
  }

  .width-margin {
    width: 100% !important;
    margin: 0% !important;
  }

  .horizontal-flex {
    display: flex !important;
  }

  .align-unset {
    align-self: unset !important;
  }

  .h-60 {
    height: 45px !important;
  }

  .font-small {
    font-size: small;
  }

  .font-medium {
    font-size: medium;
  }

  .column {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .column-flex {
    display: flex;
    flex-direction: column !important;
  }

  .heading1 {
    font-size: large;
    margin-top: 109px;
  }

  .section2-heading1 {
    font-size: larger;
    width: 100% !important;
    margin-top: 5rem !important;
    margin-bottom: 5px !important;
  }

  .section2-heading5 {
    font-size: small;
  }

  .section2-heading6 {
    font-size: 3vw;
    margin-top: 2rem !important;
  }

  .w-53 {
    width: 100% !important;
  }

  .navbar-items {
    /* / height: 100px !important;/ */
    display: flex;
    flex-direction: column;
  }

  .form-div {
    display: flex !important;
    flex-direction: row !important;
  }

  .bg-section-2 {
    height: auto !important;
  }

  .carousel-body {
    height: auto !important;
    padding: 0% !important;
  }

  .inverted-commas {
    font-size: 26px;
  }

  .arrow-div {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    font-size: 40px;
  }
}

@media (min-width: 1630px) {
  .line-height-6vh {
    line-height: 6vh !important;
  }

  /* div.testimonial h5 {
    font-size: 4rem !important;
  } */

  .carousel-user.small-carousel p.testimonial-text-data {
    font-size: 25px;
    line-height: 2;
  }

  .sub-testimonial-info {
    font-size: 2rem !important;
  }

  .margin-logo {
    margin: 0vh 2vh 1vh 2vh !important;
  }

  .footer-flex {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: auto !important;
  }

  .footer-link {
    margin: 0% 1% 0% 1%;
    word-wrap: normal;
  }

  .font-2rem {
    font-size: 2rem !important;
  }

  .font-2vh {
    font-size: 2vh !important;
  }

  .font-3vh {
    font-size: 3vh !important;
  }

  .font-4vw {
    font-size: 4vw !important;
  }

  .font-3vw {
    font-size: 3vw !important;
  }

  .font-3vh {
    font-size: 3vh !important;
  }

  .font-2vw {
    font-size: 2vw !important;
  }

  .font-4vh {
    font-size: 4vh !important;
  }

  .font-size-larger {
    font-size: larger !important;
  }

  .border-radius-4 {
    border-radius: 4% !important;
  }

  .margin-x {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }

  .padding-margin {
    padding: 1% 0% 2% 2%;
  }

  .sign-up-login-btn {
    height: auto !important;
    width: auto !important;
    border-radius: 10px !important;
  }

  .height-4rem {
    height: 4rem !important;
  }

  .height-9vh {
    height: 9vh !important;
  }

  .height-15vh {
    height: 15vh !important;
  }

  .height-11vh {
    height: 11vh !important;
  }

  .height-8rem {
    height: 8rem !important;
  }

  .height-5rem {
    height: 5rem !important;
  }

  .height-12vw {
    width: 20vw !important;
  }

  .height-35rem {
    height: 35rem !important;
  }

  .w-88 {
    width: 78% !important;
  }

  .w-11rem {
    width: 11rem !important;
  }
}

@media (max-width: 1100px) {
  .font-1vw-1100 {
    font-size: 1vw !important;
  }

  .arrow-div {
    background: unset !important;
  }
}

.font-normal-1vh {
  font-size: 1vh !important;
}

.font-normal-2vh {
  font-size: 2vh !important;
}

.font-normal-5vh {
  font-size: 5vh !important;
}

.font-normal-6vh {
  font-size: 6vh !important;
}

.font-normal-7vh {
  font-size: 8vh !important;
}

.width-normal-11vw {
  width: 11vw !important;
}

.w-60vw {
  width: 60vw !important;
}

.line-height-4vh {
  line-height: 4vh !important;
}

@media (max-width: 1100px) {
  .font-1vw-1100 {
    font-size: 1vw !important;
  }
}

.font-normal-1vh {
  font-size: 1vh !important;
}

.font-normal-2vh {
  font-size: 2vh !important;
}

.font-normal-5vh {
  font-size: 5vh !important;
}

.font-normal-1_5vh {
  font-size: 1.5vh !important;
}

.font-normal-6vh {
  font-size: 6vh !important;
}

.width-normal-11vw {
  width: 11vw !important;
}

.w-60vw {
  width: 60vw !important;
}

.font-3vh {
  font-size: 3vh !important;
}

.font-4vh {
  font-size: 4vh !important;
}

.text-black {
  color: black !important;
}

.testimonial-card-height {
  height: 60vh !important;
}

@media (min-width: 1200px) {
  .testimonial-card-height {
    height: 56vh !important;
  }
}

@media (min-width: 1300px) {
  .testimonial-card-height {
    height: 45vh !important;
  }
}

@media (min-width: 2700px) {
  .testimonial-card-height {
    height: 40vh !important;
  }
}

@media (min-width: 2980px) {
  .testimonial-card-height {
    margin-top: 5vh !important;
  }
}

@media (min-width: 4000px) {
  .testimonial-card-height {
    margin-top: 8vh !important;
  }
}

.testimonial-card-height-text {
  font-size: 2.5vh !important;
}

@media (max-width: 1100px) {
  .testimonial-card-height {
    height: 50vh !important;
  }

  .testimonial-card-height-text {
    font-size: 2vh !important;
  }

  .testimonial-text-data-sec5 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (min-width: 1600px) {
  .footer-margin {
    margin: 0 3vw 0 3vw !important;
  }

  .footer-link-margin {
    margin: 0 1vw 0 1vw !important;
  }
}

@media (min-width: 4000px) {
  .footer-margin {
    margin: 0 9vw 0 9vw !important;
  }

  .footer-link-margin {
    margin: 0 1.5vw 0 1.5vw !important;
  }
}

.margin-top-1vh {
  margin-top: 1vh !important;
}

.user-img {
  width: 10vh !important;
  height: 10vh !important;
}

.font-2_5vh {
  font-size: 2.5vh !important;
}

.font-1_5vh {
  font-size: 1.5vw !important;
}

.font-1_3vh {
  font-size: 1.3vw !important;
}

.font-3_5vh {
  font-size: 3.5vh !important;
}

.footer-elemnt {
  position: relative;
  bottom: 0;
}

.footer-icon {
  font-size: 2vw;
  color: #a80000 !important;
  font-weight: bold;
}

.footer-p {
  font-size: 0.6vw !important;
}

.footer-link {
  font-size: 1vw !important;
  text-decoration: none !important;
  color: white;
}

.font-4vw {
  font-size: 4vw !important;
}

.margin-left-2vw {
  margin-left: 2vw !important;
}

.margin-left-5vw {
  margin-left: 3vw !important;
}

/*Open App Modal Custom CSS starts*/
.fade.openApp-modal-sp {
  display: none !important;
}

.backdropClassNameOpenApp.show {
  opacity: 0 !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .backdropClassNameOpenApp.show {
    opacity: 0.5 !important;
  }

  .fade.openApp-modal-sp {
    display: block !important;
  }

  .fade.openApp-modal-sp .modal-header .close {
    font-size: 2.5rem;
  }

  .sticky-notification-appStore {
    padding: 10px 0;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-left: 0;
    border-right: 0;
  }

  .openApp-modal-sp .no-content-header {
    padding: 14px 16px;
  }

  .notification-logo-section {
    display: flex;
    align-items: center;
  }

  .openApp-logo {
    margin-right: 12px;
  }

  .openApp-logo h6 {
    color: var(--primary-color);
    text-transform: uppercase;
    font-family: var(--tertiary-font-semi-bold);
    font-size: 12px;
  }

  .app-name-desc {
    font-size: 12px;
  }

  .app-name-desc span {
    opacity: 1 !important;
    color: #aea9a9;
  }

  .app-name-heading {
    margin-bottom: 0px;
    font-weight: bold;
  }

  .app-store-button {
    border: none;
    border-radius: 18px;
    padding: 5px 18px;
    background-color: var(--primary-color);
  }

  .main-wrapper-appStore {
    margin-top: 4rem;
    text-align: center;
  }

  .company-main-title {
    text-align: center;
  }

  .main-desc {
    padding: 0 10px;
    opacity: 1 !important;
    color: #aea9a9;
  }

  .btn-get-app {
    border: none;
    width: 84%;
    padding: 12px 18px;
    background-color: var(--primary-color);
  }

  .link-section button {
    background: transparent;
    color: var(--primary-color);
    font-weight: bold;
  }
}

/*Open App Modal Custom CSS ends*/

/*Dashboard Analytics Custom CSS starts*/
.page-heading {
  text-align: left;
  font-family: "MontserratMedium";
  letter-spacing: 0px;
  color: #000000;
  font-size: 20px;
  padding-bottom: 28px;
}

.analytics-user-profile {
  padding: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  justify-content: center;
}

.landlord-analytics-main {
  background: #f6f6f6;
  padding: 40px 0;
}

.analytics-section {
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.analytics-main-heading {
  border-bottom: 2px solid #f6f6f6;
  margin-bottom: 1rem;
}

.analytics-main-heading h2 {
  text-align: left;
  font-family: "MontserratMedium";
  letter-spacing: 0px;
  color: #000000;
  font-size: 20px;
  margin-bottom: 15px;
}

.analytics-section .anayltyics-container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

/* .analytics-section .analtics-card .analytic-card-body {
margin: auto;
flex: none;
} */

.analytics-section .analytic-card-body .analytic-card-body-data {
  font-family: "MontserratBold";
  letter-spacing: 0px;
  color: #255cb7;
  text-transform: uppercase;
  font-size: 60px;
}

.analytics-section .analtics-card {
  border-radius: 5px;
  background: #f6fbff 0% 0% no-repeat padding-box;
  border: 1px solid #5baffd;
  max-height: 204px;
  margin-right: 14px;
}

.analytics-section .analtics-card .analytic-card-body.card-body {
  padding: 14px;
}

.analytics-card-header.card-header {
  padding: 14px;
  border-bottom: 1px solid #e1e1e1;
  background: #f6fbff 0% 0% no-repeat padding-box;
}

.analytic-main-heading {
  display: flex;
  align-items: baseline;
}

.analytic-main-heading .svg-inline--fa {
  color: #000000;
}

.analytic-main-heading h6 {
  font-family: "MontserratMedium";
  letter-spacing: 0px;
  color: #000000;
  text-align: left;
  font-size: 16px;
  padding-right: 10px;
}

.analytic-sub-heading {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
  font-family: "MontserratRegular";
}

/* .analytic-card-body.card-body {
padding: 10px;
} */

.analytics-section .analtics-card.bottom-accord-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #edebe9;
  max-height: unset;
}
.analtics-card.bottom-accord-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #edebe9;
  max-height: unset;
}
.analytic-recommendation {
  display: flex;
  flex-wrap: wrap;
}
/* .analytics-section .analtics-card.bottom-accord-card {
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #EDEBE9;
max-height: unset;
} */
.accordion-heading-sp {
  background-color: #fff;
  letter-spacing: 0px;
  color: #000000;
  font-family: "MontserratRegular";
  font-size: 16px;
  border-bottom: none;
  padding: 0;
}
.accordion-heading-sp.accord-main-text:hover {
  opacity: inherit !important;
  font-weight: bold !important;
}
.accordion-heading-sp.accord-main-text {
  padding: 14px;
}

.accordion-heading-sp p {
  background-color: #a80000;
  padding: 7px 14px;
  font-family: "MontserratRegular";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 1rem;
}

.my-accordion-btn .card {
  border: none;
}

.my-accordion-btn .card-body {
  padding: 0;
}

.accordion-heading-sp:hover {
  /* opacity: 80%; */
}

.accordion-heading-sp span {
  float: right;
}

.accordion-heading-sp .arrow-down {
  width: 15px;
}

.my-accordion-btn {
  text-align: left;
}

.state-list {
  list-style: none;
  margin: 0;
  padding: 0 14px;
  /* padding: 0; */
}

.recommendation-property-list-item {
  border-bottom: 1px solid #edebe9;
  padding: 14px 0;
}

.recommendation-property-list-item .collapse.show {
  background-color: #f6f6f6;
}

.rules-item-sp {
  /* text-align: left; */
  font-family: "MontserratRegular";
  font-size: 16px;
  letter-spacing: 0px;
  margin-bottom: 0;
  color: #255cb7;
  padding: 14px;
}

.textarea-section-sp {
  text-align: center;
}

.textarea-heading-sp {
  text-align: center;
}

.textarea-section {
  width: 65%;
  margin: 0 auto;
}

.analytics-textarea-sp {
  width: 100%;
}

.profile-image {
  width: 100px;
  height: 100px;
  padding-right: 10px;
}
.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-name {
  font-size: 18px;
  font-family: "MontserratMedium";
}
.user-type {
  font-size: 16px;
  font-family: "MontserratMedium";
  color: #5baffd;
}
.back-button-section {
  width: 42px;
  height: 50px;
}
.back-button-section img {
  width: 100%;
  cursor: pointer;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .textarea-section {
    width: 85%;
    margin: 0 auto;
  }

  .page-heading {
    text-align: center;
  }

  .analytics-main-heading h2 {
    text-align: center;
  }

  .analytics-section .anayltyics-container {
    padding: 0 20px 20px 15px;
  }

  .analytics-section .analtics-card {
    margin-right: 0;
  }
  .accordion-heading-sp {
    font-size: 14px;
  }
  .accordion-heading-sp p {
    font-size: 14px;
  }
}

/*Dashboard Analytics Custom CSS Ends*/

/*Sign Products Custom CSS Starts*/
.sign-posts-section {
  padding: 3rem 0;
}

.posts-main-wrapper {
  background-color: #f4f4f4;
  padding: 20px;
}

.main-container {
  padding: 0 30px;
  /* max-width: 816px; */
  margin: 0 auto;
}

.posts-main-header {
  padding: 15px 15px 5px 15px;
  border: 1px solid #cccccc;
  text-align: center;
  box-shadow: inset 1px -1px 37px 8px rgb(122 116 122 / 14%);
}

.posts-main-header h1 {
  font-size: 24px;
  font-weight: bold;
  color: #08395d;
}

.posts-card-section {
  margin-top: 1rem;
}

.posts-card {
  /* box-shadow: 1px 1px 4px 1px #ccc; */
  font-size: 16px;
  background-color: #ffffff;
  padding: 0;
  text-align: center;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0 4rem 2rem 4rem;
}

/* .go-to-details-link:hover {
opacity: 80%;
} */

/* .posts-card:nth-child(3n) {
margin-right: 0;
} */

/* .posts-card-title h3 {
font-size: 20px;
color: #007bff;
font-weight: bold;
overflow: hidden;
} */

.upper-content {
  /* padding: 10px; */
  height: 35vh;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: auto;
}

.primary-address {
  padding: 1rem;
  text-align: left;
}

.posts-card-img-section {
  height: 100%;
  /* border-bottom: 3px solid #a80000; */
  position: relative;
}

.posts-card-img-section img {
  width: 100%;
  height: 100%;
}

.price-area-section-bottom {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.primary-address h6 {
  margin: 5px 0;
}

.primary-address p {
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}

.price {
  color: rgb(101, 185, 51);
  font-weight: 600;
  font-size: 18px;
}

.view-details {
  font-size: 16px;
  color: #255cb7;
  text-decoration: underline;
}
.property-img {
  display: block;
}

.text-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000;
}
.property-products:hover .text-overlay {
  opacity: 1;
}
.description-text {
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 14px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .posts-main-header h1 {
    font-size: 20px;
  }

  .main-container {
    padding: 0 10px;
  }

  .posts-card {
    margin: 0 0 1rem 0;
    font-size: 15px;
  }

  .primary-address h6 {
    font-size: 15px;
  }

  .primary-address p {
    font-size: 15px;
    color: #007bff;
    font-weight: 400;
  }
}

/*Sign Products Custom CSS Ends*/

/*Sign Details Custom CSS Starts*/
.sign-details-section {
  padding: 3rem 0;
  background-color: #f4f4f4;
}

.details-main-wrapper {
  padding: 20px;
  border-bottom: 1px solid lightgray;
}

.photo-box {
  background: #ffffff;
  text-align: center;
  border: 8px solid #ffffff;
  box-shadow: 1px 1px 4px 1px #ccc;
  padding: 1rem;
  border-radius: 10px;
}

.photo-box p {
  font-size: 18px;
}

.upper-photo {
  margin-bottom: 1rem;
}

.upper-photo img {
  width: 100%;
}

.photo-box-btn-section {
  display: flex;
  justify-content: space-around;
}

.photo-box-btn {
  background-color: #000000;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 15px;
  border: none;
}

.photo-box-btn:hover {
  background-color: var(--primary-color);
}

.photo-outline {
  padding: 10px;
  width: 100%;
  border: 5px solid var(--primary-color);
  height: fit-content;
  border-radius: 10px;
}

.photo-desc-card {
  background: #ffffff;
  text-align: center;
  border: 8px solid #ffffff;
  box-shadow: 1px 1px 4px 1px #ccc;
  padding: 1rem;
  border-radius: 10px;
}

.desc-card-title h1 {
  color: #333333;
  font-weight: bold;
  font-size: 30px;
}

.features-list {
  margin: 0;
  padding: 0 1rem;
  color: #333333;
  text-align: left;
}

.features-list-item {
  line-height: 30px;
  font-size: 16px;
}

.quantity-cart-total {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.quantity label {
  font-size: 16px;
}

.quantity input {
  position: relative;
  opacity: 1;
  cursor: pointer;
  width: unset;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #666666;
  margin-left: 1rem;
}

/*Hide number input arrows starts*/
/* Chrome, Safari, Edge, Opera */
.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity input[type="number"] {
  -moz-appearance: textfield;
}

/*Hide number input arrows ends*/

.cart-total {
  border-radius: 5px;
  padding: 10px;
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  background: #cbcbcb;
}

.cart-total span {
  font-size: 18px;
}

.total-price {
  font-size: 20px;
  font-weight: bold;
}

.add-to-cart-btn-section {
  text-align: center;
  margin-top: 3rem;
}

.add-to-cart-btn,
.view-cart-btn {
  padding: 15px 65px;
  font-size: 24px;
  font-weight: bold;
}
.view-cart-sec {
  margin-left: 20px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .photo-box {
    padding: 0.5rem;
  }

  .photo-box p {
    font-size: 16px;
  }

  .photo-outline {
    margin-top: 2rem;
  }

  .photo-desc-card {
    padding: 0.5rem;
  }

  .desc-card-title h1 {
    font-size: 26px;
  }

  .features-list {
    padding: 0 0 0 1rem;
  }

  .features-list-item {
    font-size: 14px;
  }

  .quantity-cart-total {
    flex-direction: column;
  }

  .quantity {
    margin-bottom: 1rem;
  }

  .quantity label {
    font-size: 14px;
  }

  .cart-total {
    padding: 8px;
  }

  .cart-total span {
    font-size: 14px;
  }

  .total-price {
    font-size: 16px;
  }

  .add-to-cart-btn-section {
    margin-top: 2rem;
  }

  .add-to-cart-btn {
    padding: 10px 56px;
    font-size: 20px;
  }
}

/*Sign Details Custom CSS Ends*/

/*Emailer Template Custom CSS Starts*/
.emailer-main {
  padding: 3rem 0;
}

.emailer-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

.emailer-comm-spacing {
  padding: 0 30px;
}

.greeting-text {
  font-family: "MontserratBold";
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
}

.email-desc-section {
  margin: 30px 0;
}

.email-content-section p {
  font-size: 16px;
  color: #000000;
  font-family: "MontserratRegular";
  line-height: 28px;
  padding: 20px 0 0 0;
  letter-spacing: 0px;
}

.redhanded-red-txt {
  font-family: "MontserratBold";
  color: #a80000;
}

.learn-more-text {
  text-align: center;
  font-family: "MontserratBold";
  letter-spacing: 0px;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
  padding: 20px 3rem;
}

.blue-bg-banner {
  padding: 20px 0;
}

.unsure-section {
  margin-bottom: 50px;
}

.unsure-section h2 {
  text-align: center;
  font-family: "MontserratBold";
  letter-spacing: 0px;
  color: #a80000;
  font-size: 23px;
}

.unsure-section p {
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-family: "MontserratBold";
}

.video-card {
  padding: 0 10px;
}

.video-card:nth-child(3n) {
  padding-right: 0;
}

.video-card img {
  width: 100%;
  height: 100%;
}

/*Emailer Template Custom CSS Ends*/

/*Landlord Quiz Custom CSS Starts */
.main-heading-blue p {
  font-size: 24px;
  font-family: MontserratRegular;
  font-weight: 600;
  letter-spacing: 0;
  color: #255cb7;
}
.signup-section .container-fluid {
  max-width: 1320px;
}
.modal-header-sp .confirm-modal-sp.modal-header {
  padding: 20px;
  background-color: #f6f6f6;
}
.generic-heading .generic-heading-confirm-sp {
  text-transform: uppercase;
  background: unset;
  color: #000000;
  font-size: 20px;
  font-family: "MontserratMedium";
}
.confirm-modal-sp .close {
  color: #a80000;
  font-size: 2rem;
  opacity: 1;
  padding: 13px;
}
.description-modal-sp .modal-body {
  padding: 40px;
}
.description-modal-sp .desc-msg-sp {
  font-size: 16px;
  font-family: "MontserratRegular";
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 600;
}
.description-modal-sp .confirm-list {
  font-family: "MontserratRegular";
  padding: 0;
  margin: 0;
  letter-spacing: 0px;
  line-height: 1;
  font-weight: 600;
}
.confirm-list li {
  color: #000000;
  font-family: "MontserratRegular";
  letter-spacing: 0px;
  font-size: 16px;
  margin-left: 1.3rem;
}
.confirm-list li::marker {
  font-size: 25px;
  color: #255cb7;
}
.description-modal-sp .cursor.btn-blue-color.btn-blue-color {
  padding: 10px 32px;
  border-radius: 6px;
}
.description-modal-sp .btn-blue-border-1.pop-up-property-detaile-btn {
  padding: 10px 32px;
  border-radius: 6px;
  color: #fff !important;
  background-color: #a80000 !important;
  border-color: transparent !important;
}
@media (min-width: 576px) {
  .admin-prop-desc .modal-dialog {
    max-width: 650px;
  }
}
.landlord-quiz-checkbox input {
  margin-right: 10px;
}
.landlord-btn-dv .btn-red-color {
  font-weight: 600;
  letter-spacing: 1px;
  border: 2px solid #a80000;
  color: #fff !important;
  background: #a80000 !important;
}

/*Landlord Quiz Custom CSS Starts */

/* rahul css starts */

.d-none {
display:none !important;
}

.cursor__pointer {
  cursor: pointer !important;
}

.leasing__modal .close {
  color: #a90001 !important;
}

.leasingPlan__diagram img {
  width: 80%;
}

.landlord__plan {
height:30rem;
overflow-y:scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #255cb7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.small__text {
  color: #a80000;
  text-transform: uppercase;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.modal__bg {
  background-image: url("../../assets/images/landlord__bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 0px);
}

.sm-modal .modal__screen {
  width: 35rem !important;
}

.onemorestep .modal__screen {
  width: 60rem !important;
}

.modal__bg .modal__screen {
  background: #fff;
  width: 36rem;
  height: 36rem;
  /* height: calc(100vh - 100px); */
  position: relative;
  box-shadow: 0px 0px 30px #0000004d;
  border-radius: 5px;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.modal__bg .modal__screen .modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.modal__bg .modal__screen .modal__header img {
  cursor: pointer;
}

.modal__bg .modal__screen .modal__header .header__logo h3 {
  font-size: 30px !important;
  font-weight: 700;
}

.modal__bg .modal__screen .modal__header .steps__count p {
  color: #5baffd;
}

/* .modal__bg .modal__screen .modal__body .modal__content {
padding:1rem;
} */

.modal__bg .modal__screen .modal__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 0px 30px #00000029;
  border-radius: 0px 0px 5px 5px;
  padding: 1rem;
  background: #fff;
  z-index: 99;
}

.modal__bg .modal__screen .modal__footer button {
  width: auto;
  min-width: 180px;
  background: #a91622 !important;
  border-color: #a91622 !important;
  box-shadow: none !important;
  font-size: 14px;
}

.modal__bg .modal__screen *:not(i) {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.3;
}

.modal__bg .modal__screen .main-heading-blue * {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.modal__bg .modal__screen .steps__block .font-weight-bold,
.modal__bg .modal__screen .steps__block .font__bold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 14px;
}

.modal__bg .modal__screen .steps__block {
padding: 0 2rem;
overflow-x: inherit;
overflow-y: auto;
max-height: 25rem;
}

.modal__bg .modal__screen .steps__block .navbar-header-title {
  font-size: 26px !important;
  font-weight: 700;
}

.modal__bg
  .modal__screen
  .steps__block.technical__skills
  .landlord-quiz-checkbox
  label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__bg
  .modal__screen
  .steps__block.technical__skills
  .landlord-quiz-checkbox
  label:hover {
  padding: 1rem 1.5rem;
  transition: all 0.5s;
}

.modal__bg .modal__screen .landlord-quiz-checkbox input[type="checkbox"] {
  display: none;
}

.modal__bg .modal__screen .landlord-quiz-checkbox label {
  border: 1px solid #5baffd;
  display: block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #255cb7;
  transition: all 0.5s;
  font-size: 14px;
  width: 80%;
  margin: 10px auto;
}

.modal__bg .modal__screen .landlord-quiz-checkbox label:hover {
  color: #fff;
  background: #5baffd;
}

.modal__bg .modal__screen .landlord-quiz-checkbox label i {
  margin-right: 5px;
  font-size: 12px;
}

.modal__bg .modal__screen .landlord-quiz-checkbox.steps__section label {
  display: flex;
  align-items: flex-start;
}

.modal__bg .modal__screen .landlord-quiz-checkbox.steps__section .number {
  font-size: 2rem;
  font-weight: bold;
  margin-right: 1rem;
  line-height: 1;
}

.modal__bg
  .modal__screen
  .landlord-quiz-checkbox
  input[type="checkbox"]:checked
  + label {
  color: #fff;
  background: #5baffd;
}

.modal__bg .modal__screen .landlord-quiz-checkbox .retailer__childs label {
  border: 1px solid #8d8d8d;
  color: #8d8d8d;
}

.modal__bg
  .modal__screen
  .landlord-quiz-checkbox
  .retailer__childs
  label:hover {
  background: #255cb7;
  color: #fff;
}

.modal__bg
  .modal__screen
  .landlord-quiz-checkbox
  .retailer__childs
  input[type="checkbox"]:checked
  + label {
  background: #255cb7;
}

.modal__bg .modal__screen .landlord-quiz-checkbox ul {
  list-style: none;
  padding-left: 0;
}

.modal__bg .modal__screen .landlord-quiz-checkbox ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.modal__bg .modal__screen .landlord-quiz-checkbox ul li i {
  margin-right: 5px;
  margin-top: 2px;
}

.modal__bg .modal__screen .landlord-quiz-checkbox ul li i.fa-times-circle {
  color: #a80000;
}

.modal__bg .modal__screen .landlord-quiz-checkbox ul li i.fa-check-circle {
  color: #087e00;
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  color: #8d8d8d;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 8px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: #fff;
  color: #000000;
  box-sizing: border-box;
  border: 1px solid #edebe9;
  border-radius: 5px;
  font-weight: 500 !important;
}

.did-floating-input:focus {
  outline: none;
}

.did-floating-input:focus ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
  color: #5baffd;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
  color: #5baffd;
}

.select-did-floating-input-sp ~ .did-floating-label {
  font-size: 13px;
}

.did-floating-input.select-did-floating-input-sp:focus ~ .did-floating-label {
  top: -8px !important;
  font-size: 13px;
}

.radio__block {
  display: flex;
  align-items: center;
}

.radio__block input {
  margin-right: 10px;
}

.radio__block label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
}

.radio__block label .small__text {
  font-size: 14px !important;
  font-weight: 700;
}

.linkText {
  color: #255cb7;
  text-decoration: underline !important;
  cursor: pointer;
}

.linkText-red {
  /* text-align: center; */
  color: #A80000;
  text-decoration: underline;
}

.text-underline {
text-decoration:underline;
}

.leasing__plan .leasing__block {
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.leasing__plan .leasing__block img {
  width: 30px;
  margin-bottom: 10px;
}

.font__bold {
  font-weight: 800 !important;
}


.font__semibold {
font-family: "MontserratSemiBold";
}

.font__bold__700 {
  font-weight: 700;
}

.plans__section {
  margin-bottom: 3rem;
}

.plans__section .plans__block input[type="radio"] {
  position: absolute;
  right: 10%;
  top: 20%;
}

.plans__section .plans__block input[type="radio"] + label {
border: 2px solid #edebe9;
border-radius: 5px;
width: 100%;
padding: 1rem;
cursor: pointer;
box-shadow: 2px 4px 7px 0px #ccc;
background:#f7f7f7;
}

/* .plans__section .plans__block input[type='radio'] + label:hover {
background: #ECF6FF;
box-shadow: 0px 0px 20px #00000029;
border: 2px solid #255CB7;
border-radius: 5px;
width: 100%;
padding: 1rem;
cursor: pointer;
} */

.plans__section .plans__block input[type="radio"]:checked + label {
  background: #ecf6ff;
  box-shadow: 0px 0px 20px #00000029;
  border: 2px solid #255cb7;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}

.payment__section .payment__method {
  padding: 1rem;
  border: 2px solid #edebe9;
  border-radius: 5px;
}

.payment__section .payment__method.cards {
  min-height: 24.5rem;
}

.payment__section .payment__method .top__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment__section .payment__method .top__block .input__here input {
  margin-right: 5px;
}

.payment__section .payment__method .top__block .input__here label {
  margin-bottom: 0;
}

.payment__section .payment__method .top__block .images__here img {
  margin-right: 10px;
}

.payment__section .payment__method .bottom__block {
  margin-top: 1rem;
}

.payment__section .right__section .grey__block {
  background: #f6f6f6;
  border-radius: 5px;
  margin-bottom: 20px;
}


.payment__section .right__section .grey__block ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.payment__section .right__section .grey__block ul li {
  display: flex;
  align-items: flex-start;
  color: #717171;
  font-size: 13px;
  margin-bottom: 5px;
}

.payment__section .right__section .grey__block .circle {
  width: 15px;
  text-align: center;
  background: #717171;
  border-radius: 50%;
  margin-right: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  margin-top: 2px;
}

.payment__section .right__section .grey__block .circle i {
  font-size: 10px;
}

.payment__section .right__section .grey__block .text {
  width: 90%;
}

.payment__section .right__section .total__block {
  background: #f6fbff;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.payment__section .right__section .total__block .purchased__plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #717171;
}

.payment__section .right__section .total__block .purchased__plan .total {
  color: #255cb7;
  font-weight: bold;
}

.payment__section .right__section .total__block hr {
  margin: 7px 0;
}

.reviews__sections .review__block {
  border: 2px solid #edebe9;
  margin: 0;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
  margin-bottom: 2rem !important;
}

.reviews__sections .review__block .user__block {
  background: #ecf6ff;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
}

.reviews__sections .review__block .user__block p {
  color: #5baffd;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 2rem;
}

.reviews__sections .review__block .date {
  position: absolute;
  right: 0;
  top: 0;
}

.reviews__sections .review__block .top__heading .invited {
  color: green;
  font-size: 13px;
  font-weight: normal;
}

.reviews__sections .review__block .top__heading .invited i {
  margin-right: 5px;
}

.reviews__sections .review__block .stars p {
  color: #ffc66e;
  font-size: 20px;
}

.progress__counter {
  padding: 0 2rem;
  margin-bottom: 1rem;
}

.progress__counter .progress {
  height: 2px;
}

.progress__counter .progress .progress-bar {
  background-color: #5baffd;
}


.leasing__plan.fullscreen {
width: 100% !important;
margin: 0;
border-radius:0;
height:100%;
}

.leasing__plan.fullscreen .steps__block {
height: calc(100vh - 170px);
max-height:calc(100vh - 170px);
}

.leasing__plan.fullscreen .accordian__section .accordian__body {
max-height:100%;
}

.leasing__section .right__img {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 23rem;
}

.leasing__section .landlord-quiz-checkbox label {
  width: 100% !important;
}

.leasing__section .font-italic {
  font-style: italic !important;
}

.accordian__section .arrow-down {
  width: 15px;
}

.accordian__section .card-header {
  display: flex;
  justify-content: space-between;
  border: none;
  background: #f6f6f6;
  cursor: pointer;
  margin-bottom: 1rem;
}

.accordian__section .card-header .accordian__heading {
  font-weight: 600 !important;
}

.accordian__section .card-header.active {
  background: #255cb7;
  color: #fff;
  pointer-events:none;
}

.accordian__section .card-header .down__arrow {
  color: #00000029;
}

.accordian__section .card-header.active .down__arrow {
  transform: rotate(180deg);
  color: #fff;
}

.accordian__section .card-body {
  padding: 0;
}

.accordian__section .accordian__body {
  max-height: 12rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.accordian__section .accordian__body ol {
  padding-left: 20px;
}

.accordian__section .accordian__body ol li {
  margin-bottom: 1rem;
}

.redtextcolor {
  color: #a80000 !important;
}

/* detail page css */

.dtl__header {
  box-shadow: 0px 5px 10px #0000001a;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 3rem;
  padding-right: 3rem;
}

.dtl__header .dtl__headercontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dtl__header .dtl__headercontent *:not(i) {
  font-family: "Montserrat", sans-serif !important;
}

.dtl__header .dtl__headercontent .dtl__logo {
  display: flex;
  align-items: center;
}

.dtl__header .dtl__headercontent .dtl__logo .navbar-header-title {
  font-size: 30px !important;
  margin-bottom: 0;
  font-weight: 700;
}

.dtl__header .dtl__headercontent .dtl__logo img {
  margin-right: 10px;
  cursor: pointer;
}

.dtl__header .dtl__headercontent .dtl__next p {
  margin-bottom: 0;
  margin-right: 10px;
}

.dtl__header .dtl__headercontent .dtl__next p span {
  color: #000;
  margin-left: 5px;
}

.dtl__header .dtl__headercontent .dtl__next {
  display: flex;
  align-items: center;
}

.dtl__header .dtl__headercontent .dtl__next button {
  width: 8rem;
  background: #a91622 !important;
  border-color: #a91622;
}

.dtl__content .left__section .bottom__button button {
  background: #a91622 !important;
  border-color: #a91622;
}

.dtl__content .virtual__tour.left__section {
padding-left:0;
padding-right:3rem;
}

/* .dtl__content {
margin-top:2rem;
} */

.dtl__content .left__section {
  padding-left: 3rem;
  /* padding-right: 3rem; */
  margin-top: 0rem;
}

.dtl__content .left__section *:not(i) {
  font-family: "Montserrat", sans-serif !important;
  font-size:14px;
}

.dtl__content ul {
  list-style: none;
  padding-left: 0;
}

.dtl__content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.dtl__content ul li i {
  margin-right: 5px;
  margin-top: 5px;
}

.dtl__content ul li i.fa-times-circle {
  color: #a80000;
}

.dtl__content ul li i.fa-check-circle {
  color: #087e00;
}

.dtl__content .left__section .navbar-header-title {
  font-size: 30px !important;
}

.dtl__content .left__section .content__block h3 {
  margin-bottom: 1rem;
  /* margin-top: 2rem; */
  font-family: "Montserrat", sans-serif !important;
  font-size:30px;
}

.dtl__content .left__section .content__block h3 span {
  text-transform: capitalize !important;
  font-weight: 800;
}

.dtl__content .left__section .price__block {
  border: 2px solid #edebe9;
  border-radius: 5px;
  padding: 1rem;
  /* margin-top: 2rem; */
  width: 17rem;
  box-shadow: 0px 0px 15px #00000029;
}

.dtl__content .left__section .onetimefee__img {
  width: 130px;
  /* margin-top: 2rem; */
  margin-left: 2rem;
}

.dtl__content .left__section .price__block p {
  color: #a80000;
  font-size: 16px;
}

.dtl__content .left__section .price__block p span {
  color: #a80000;
  font-weight: bold;
  font-size:20px;
}

.dtl__content .left__section .bottom__button {
  margin-top: 1.5rem;
}

.dtl__content .left__section .bottom__button .linkText {
  color: #255cb7 !important;
  text-decoration: underline !important;
  font-size:14px;
}

.right__section.professional__photo {
background-image: url("../../assets/images/pro__banner.png");
height: calc(100vh - 183px);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.right__section.virtual__tour {
  background-image: url("../../assets/images/virtual__tour.png");
  height: calc(100vh - 183px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right__section.window__signage {
  background-image: url("../../assets/images/forlease__bg.png");
  height: calc(100vh - 183px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right__section.feature__listing {
  background-image: url("../../assets/images/featured__listing.png");
  height: calc(100vh - 183px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.thankyou__page *:not(i) {
  font-family: "Montserrat", sans-serif !important;
}

.thankyou__page .right__bannertext {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 8rem;
}

.thankyou__page .right__bannertext h3 {
  line-height: 1.5;
  margin-bottom: 1rem;
}

.thankyou__page .right__bannertext .banner__btn {
  display: flex;
}

.thankyou__page .right__bannertext .banner__btn button {
  display: flex;
  align-items: center;
  background-color: #000;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
  text-align: left;
  margin-right: 10px;
}

.thankyou__page .right__bannertext .banner__btn button.appleLogo:hover {
  background-color: #255cb7;
}

.thankyou__page .right__bannertext .banner__btn button.playstoreLogo:hover {
  background-color: #a80000;
}

.thankyou__page .right__bannertext .banner__btn button img {
  width: 20px;
  margin-right: 10px;
}

.thankyou__page .right__bannertext .banner__btn button p {
  margin-bottom: 0;
  font-size: 12px;
}

.thankyou__page .right__bannertext .banner__btn button h5 {
  margin-bottom: 0;
  font-size: 15px;
}

.thankyou__page .rightbanner__img {
  background-image: url("../../../src/assets/images/rightBanner.png");
  height: 35rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.thankyou__page .rightbanner__img img {
  width: 75%;
  position: absolute;
  left: -20%;
  top: 15%;
}

.thankyou__page .right__bannertext p {
  margin-bottom: 1.5rem;
}

.order__summary {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f6f6f6;
}

.order__summary .order__summarytable table tr td {
  border: 0px;
  border-bottom: 1px solid #0078c1;
}

.order__summary .order__summarytable table tr:last-child td {
  border: 0px;
}

.order__summary .order__summarytable table .not__order {
  color: #717171 !important;
  font-weight: normal !important;
  margin-right: 1rem;
}

.order__summary .order__summarytable table .upgrade {
  color: #a80000 !important;
}

.order__summary .order__summarytable table .learn__more {
  color: #255cb7;
  text-decoration: underline !important;
  font-size: 13px;
  margin-left: 24px;
  cursor: pointer;
}

.order__summary .order__summarytable table .total {
  color: #255cb7;
  font-weight: bold;
}

.howtolog {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #fff;
}

.redhanded__network {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url("../../assets/images/redhanded__network.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 3rem;
  padding-right: 3rem;
}

.redhanded__network .right__text {
  color: #fff;
}

.redhanded__network .right__text h4 {
  line-height: 1.5;
  margin-bottom: 2rem;
}

.redhanded__network .right__text button {
  width: 8rem;
  margin-top: 2rem;
  background: #a91622 !important;
  border-color: #a91622 !important;
  box-shadow: none !important;
  font-size: 14px;
}

.tabs__section .tabs__list ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #255cb7;
  width: 29rem;
  margin: 3rem auto;
  border-radius: 40px;
  padding-left: 0;
  overflow: hidden;
}

.tabs__section .tabs__list ul li {
  color: #255cb7;
  display: block;
  flex: 50% 1;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.tabs__section .tabs__list ul li.active {
  background-color: #255cb7;
  color: #fff;
}

.circular__progessbar .CircularProgressbar {
  /* width: 15rem; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.circular__progessbar svg path {
  stroke-width: 1px;
}

.circular__progessbar .CircularProgressbar .CircularProgressbar-path {
  stroke: #5baffd;
}

.circular__progessbar .CircularProgressbar .CircularProgressbar-text {
  fill: #255cb7 !important;
  font-weight: 500;
  font-size: 8px !important;
  transform: translate(0px, -30px);
}

.circular__progessbar .CircularProgressbar .CircularProgressbar-background {
  fill: transparent;
}

.loading__text {
  position: absolute;
  bottom: 14%;
  text-align: center;
  width: 90%;
}

.leasing__plan {
  width: 65rem !important;
}

.tenant__review .tenant__reviewblk {
  border: 2px solid #edebe9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.tenant__review .reviewblk__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tenant__review .heading__text {
  margin-bottom: 0;
  font-weight: 700 !important;
  margin-bottom: 10px;
  font-size: 14px;
}

.utilities__block .utilities__required span {
display: inline-block;
min-width:7rem;
background:#5BAFFD;
color:#fff;
padding:5px 15px;
border-radius:5px;
text-align: center;
margin-right:10px;
font-size:14px;
margin-bottom:10px;
}

.location__block {
  margin-top: 20px;
}

.map__section .tab-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-top: 0;
}

.map__section .nav-tabs .nav-link.active {
  color: #a80000 !important;
}

.map__section .nav-tabs .nav-link {
  cursor: pointer;
}

.map__iframe {
  position: relative !important;
}

.map__iframe .redhanded-map-style {
  position: absolute !important;
  width: 100% !important;
  height: 15rem !important;
  bottom: 0% !important;
}

/* chatbox start */

.msger *:not(i) {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}

.msger input:focus {
  outline: none;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 30%;
  margin: 25px 10px;
  height:calc(100vh - 160px);
  border-radius: 5px;
  box-shadow: 0 15px 15px -5px rgb(0 0 0 / 20%);
  position: fixed;
  right: 0;
  z-index: 999999;
  top: 8%;
}

.msger .msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #08395d;
  color: #fff;
}

.msger .msger-header p {
  margin-bottom: 0;
  font-weight: 700;
}

.msger .msger-header span {
  font-size: 14px;
}

.msger .msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.msger .msger-chat .date-block {
  background: #255cb7;
  color: #fff;
  max-width: 6rem;
  text-align: center;
  padding: 3px;
  margin: 1rem auto;
  border-radius: 10px;
  font-size: 13px;
}

.msger .msger-chat .date-block p {
  margin-bottom: 0;
}

.msger .msger-chat::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.msger .msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.msger .msg:last-of-type {
  margin: 0;
}

.msger .msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msger .msg-img i {
  font-size: 25px;
  color: #8d8d8d;
}

.msger .msg-bubble {
  max-width: 350px;
  padding: 15px;
  border-radius: 15px;
  position: relative;
  min-width: 10rem;
  margin-top: 2rem;
}

.msger .msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: absolute;
  top: -30px;
  color: #717171;
}

.msger .msg-info-name {
  margin-right: 10px;
  font-weight: bold;
  font-size: 13px;
}

.msger .msg-info-time {
  font-size: 0.85em;
  color: #afafaf;
}

.msger .left-msg .msg-bubble {
  border-bottom-left-radius: 0;
  background: #8d8d8d;
  color: #fff;
}

.msger .left-msg .msg-bubble .msg-text {
  word-break: break-all;
  font-size: 12px;
}

.msger .right-msg {
  flex-direction: row-reverse;
}

.msger .right-msg .msg-bubble {
  background: #5baffd;
  color: #fff;
  border-bottom-right-radius: 0;
}

.msger .right-msg .msg-bubble .msg-text {
  word-break: break-all;
  font-size: 12px;
}

.msger .right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger .msger-inputarea {
  display: flex;
  /* padding: 10px; */
  background: #8d8d8d;
}

.msger .msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger .msger-input {
  flex: 1;
  background: #fff;
  margin: 10px;
}

.msger .msger-send-btn {
  background: #a80000;
  color: #fff;
  font-weight: bold;
  width: 8rem;
}

.msger .msger-chat {
  background-color: #fcfcfe;
}


/* chatbox end */

.plan__expire {
background: #FFE8E8;
display: inline-block;
font-size: 14px;
padding: 5px 10px;
border-radius: 5px;
color: #A80000;
}

.chatgpt__top {
background: #d2e2fc;
display: inline-block;
font-size: 16px;
padding: 5px 10px;
border-radius: 5px;
color: #255cb7;
margin-bottom: 0;
}

.chat__box textarea {
min-height:9rem;
}

/* admin css */

.table__block table {
min-width:1100px;
}

.table__block table tr th,
.table__block table tr td {
white-space:nowrap;
}

.pagination__block {
margin-top: 1rem;
display: flex;
justify-content: flex-end;
align-items: center;
}

.pagination__block .pagination li {
padding: 0;
margin-right: 5px;
border: 0;
}

.pagination__block .pagination li a {
color: #000;
border: 1px solid #EDEBE9;
border-radius: 5px;
min-width: 40px;
display: flex;
justify-content: center;
align-items: center;
}

.pagination__block .pagination .page-item.active .page-link {
color: #fff;
background-color: #255CB7;
border-color: #255CB7;
border-radius: 5px;
}

.pagination__block .pagination .page-link:focus {
box-shadow:none;
}

.virtualspace__upload .file__input {
display:none;
}

.virtualspace__upload .upload__icon {
border: 1px solid #EDEBE9;
border-radius: 10px;
padding: 15px;
background: #f7f7f7;
width: 50%;
margin-top:2rem;
}

.virtualspace__upload .upload__icon h6 {
font-size: 16px;
margin-bottom: 2rem !important;
}

.virtualspace__upload .upload__icon img {
width:30px;
cursor: pointer;
}

.button__icon {
width:20px;
margin-right:10px;
}

/* admin css ends */


/* homepage new design start */
/* 
.px-4.head {
display:none;
} */
  
  .new__home .banner__background {
  background:url('../../assets/images/homebanner.png');
  background-size: contain;
  background-repeat: no-repeat;
  height:calc(100vh - 0px);
  background-position: right;
  background-color: #F6F6F6;
  padding:1rem 2rem;
  }

  
  .new__home .header__section.header__fixed {
  position: fixed;
  width: 100%;
  left: 0;
  background: #fff;
  top: 0;
  padding: 10px 3rem;
  z-index: 999;
  box-shadow:7px 1px 9px 0px #ccc;
  }


  .new__home nav {
  box-shadow:none;
  background:transparent;
  padding:0;
  }
  
  .new__home .navbar-header-title {
  font-size:32px;
  font-family: "MontserratSemiBold";
  padding:0 !important;
  margin-right: 2rem;
  }
  
  .new__home nav .main__menu {
  justify-content:flex-end;
  display: flex;
  width: 100%;
  margin-left: 4rem;
  }

  .new__home nav .main__menu a {
  margin-right:4rem;
  }
  
  .new__home .search__box {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px;
  height:40px;
  width:15rem !important;
  position: relative;
  margin-right:2rem;
  }
  
  .new__home .banner__background .content__block h3 {
  margin-top: 6rem;
  margin-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif !important;
  font-size: 32px;
  text-transform: capitalize !important;
  font-weight: 600;
  }
  
  .new__home .banner__background .content__block p {
  margin-bottom: 1.5rem; 
  }
  
  .new__home .banner__background .banner__filter {
  background: #fff;
  padding: 20px;
  margin-top: 3rem;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  }
  
  .new__home .banner__background .banner__filter .filter__block {
  flex-basis:33%;
  }
  
  .new__home .banner__background .banner__filter .filter__block label {
  color: #666;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  margin-bottom: 5px;
  display:block;
  }
  
  .new__home .banner__background .banner__filter .filter__block .input__block {
  display:flex;
  }
  
  .new__home .banner__background .banner__filter .filter__block input {
  background: #EDEBE9;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  margin-right:10px;
  width:100%;
  height:40px;
  }
  
  .new__home .banner__background .banner__filter .filter__block input::placeholder {
  color:#000;
  }
  
  .new__home .banner__background .banner__filter .filter__block select {
  background: #EDEBE9;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  margin-right:10px;
  width:100%;
  height:40px;
  color:#000;
  }

  .new__home .banner__background .banner__filter .nested__dropdown {
    background: #EDEBE9;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: #000;
    margin-right:10px;
    width:100%;
    height:40px;
    color:#000;
  }

  .new__home .banner__background .banner__filter .nested__dropdown .input-group {
  background: transparent;
  border: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
  }

  .new__home .banner__background .banner__filter .nested__dropdown .input-group .fa-chevron-down {
    right: 20px;
    position: absolute;
    color: #999;
    top: 14px;
  }
  
  .new__home .banner__background .banner__filter .filter__block select::placeholder {
  color:#000;
  }
  
  .browse__space {
  padding:3rem 2rem;
  }

  .key__features {
    padding:4rem 2rem;
    background: #F6F6F6;
  }

  .key__features .feature__card {
    padding: 1.5rem;
    background: #fff;
    text-align: center;
    border: 1px solid #EDEBE9;
    border-radius:5px;
    margin-bottom:1rem;
  }

  .key__features .feature__card:hover {
  background: linear-gradient(135deg, #5BAFFD 0%, #255CB7 100%) 0% 0%;
  cursor: pointer;
  color:#fff;
  }


  .key__features .feature__card:hover img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(273deg) brightness(200%) contrast(200%);
  }

  .key__features .feature__card img {
  width:70px;
  }

  .key__features .feature__card h4 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  margin:1rem auto;
  }
  
  .rednwhite__heading {
  margin-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif !important;
  font-size: 32px;
  text-transform: capitalize !important;
  }
  
  .rednwhite__heading span {
  font-size: 32px;
  font-family: "MontserratSemiBold";
  padding: 0 !important;
  color:#A80000;
  text-transform: uppercase;
  font-weight: 600;
  }
  
  
  .slider__section .slick-dots {
  bottom:-25px !important;
  }
  
  .slider__section .slick-dots li button:before {
  opacity: 1;
  color: #737373;
  display:none;
  }
  
  .slider__section .slick-dots li.slick-active {
  /* border: 2px solid #255cb7;
  border-radius: 50%;
  width: 22px;
  height: 22px; */

  border-radius: 50%;
  border: 1px solid #255cb7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  }
  
  .slider__section .slick-dots li button {
  color: #737373;
  width: 10px;
  height: 10px;
  background: #737373;
  border-radius: 50%;
  }

  .slider__section .slick-dots li.slick-active button {
  background: #255cb7;
  }
  
  .slider__section .slick-dots li.slick-active button:before {
  color: #255cb7 !important;
  opacity: 1 !important;
  width: 5px;
  height: 5px;
  top: 0px;
  left: 2px;
  }
  
  .browse__space .slider__item {
  border: 1px solid #7171714D;
  border-radius: 5px;
  position: relative;
  }
  
  .browse__space .slider__item .img__block {
  overflow: hidden;
  transition: all .5s;
  display: flex;
  min-height: 270px;
  max-height:270px;
  }
  
  .browse__space .slider__item .img__block img {
  transform: scale(1);
  transition: all .5s;
  width:100%;
  }
  
  .browse__space .slider__item .img__block:hover img {
  transform: scale(1.2);
  transition: all .5s;
  }
  
  
  .browse__space .slider__item .img__block .price__pm {
  position: absolute;
  bottom: 50%;
  background: #EDEBE9;
  padding: 5px;
  color: #255CB7;
  left: 6%;
  border-radius: 5px;
  font-size:13px;
  z-index:2;
  }
  
  .browse__space .slider__item .img__block .new {
  position: absolute;
  background: #a80000;
  padding: 4px 10px;
  color: #fff;
  left: 6%;
  border-radius: 5px;
  font-size: 13px;
  top: 5%;
  z-index:2;
  }
  
  .browse__space .slider__item .slider__content {
  padding:1rem;
  }

  .browse__space .slider__item .slider__content .space__add {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  .browse__space .slider__item .slider__content h5 {
  background-color: #255cb7 !important;
  border-color: #255cb7 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px;
  display: inline-block;
  }
  
  .browse__space .slider__item .slider__content .bottom__block {
  border-top: 1px solid #7171714D;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
  }
  
  .browse__space .slider__item .slider__content .bottom__block span:nth-child(2) {
  color:#7171714D;
  }
  
  .advantage__section {
  background:url("../../assets/images/advantage__bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3rem 2rem;
  position: relative;
  height: 31rem;
  margin-bottom:7rem;
  }
  
  .advantage__section h3 {
  margin-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif !important;
  font-size: 32px;
  text-transform: capitalize !important;
  color: #fff;
  text-align: center;
  }
  
  .advantage__section h4 {
  margin-bottom: 1.5rem;
  font-family: "MontserratSemiBold";
  text-transform: capitalize !important;
  color: #fff;
  text-align: center;
  font-size:16px;
  }
  
  .advantage__section .play__icon {
  margin:2rem auto;
  cursor: pointer;
  }
  
  .advantage__section .checkout__text {
  margin-bottom: 12rem;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #fff;
  text-align: center;
  }
  
  .advantage__section p {
  margin-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #fff;
  text-align: center;
  }
  
  .advantage__section .white__box {
  background: #fff;
  box-shadow: 0px 25px 99px #00000029;
  border-radius: 5px;
  position: absolute;
  bottom: -18%;
  padding:1rem;
  }
  
  .advantage__section .white__box p {
  font-size: 14px;
  color: #000;
  text-align: left;
  }
  
  .advantage__section .white__box .specs__block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 1rem; */
  }
  
  .advantage__section .white__box .specs__block .specs__img img {
  margin-right:10px;
  width:50px;
  }

  .video__block iframe video {
  width:100% !important;
  height:100% !important;
  display:none;
  }
  
  .how__works {
  margin-top:7rem;
  padding:3rem 2rem;
  }
  
  .how__works  .work__block {
  position: relative;
  }
  
  .how__works  .work__block .work__content {
  position: absolute;
  bottom: 10px;
  color: #fff;
  left: 20px;
  }

  .new__home .how__works .work__block.block1 {
  background:url("../../assets/images/howitworks1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height:20rem;
  border-radius:5px;
  }

.new__home .how__works .work__block.block2 {
background:url("../../assets/images/howitworks2.png");
background-size: cover;
background-repeat: no-repeat;
background-position: center;
min-height:20rem;
border-radius:5px;
}

.new__home .how__works .work__block.block3 {
background:url("../../assets/images/howitworks3.png");
background-size: cover;
background-repeat: no-repeat;
background-position: center;
min-height:20rem;
border-radius:5px;
}

.new__home .how__works .work__block.block4 {
background:url("../../assets/images/howitworks4.png");
background-size: cover;
background-repeat: no-repeat;
background-position: center;
min-height:20rem;
border-radius:5px;
}
  

  
  
  .client__says {
  background:url("../../assets/images/client__say.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3rem 2rem;
  color:#fff;
  }
  
  
  .client__says .user__details {
  display:flex;
  justify-content: flex-start;
  align-items:flex-start;
  margin-top:3rem;
  }
  
  .client__says .user__details .user__img {
  width:100px;
  height:100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right:10px;
  border-radius:5px;
  overflow: hidden;
  }
  
  .client__says .user__details .user__img img {
  width:100%;
  }
  
  .client__says .user__details .user__content h5 {
  font-size:15px;
  }
  
  .client__says .user__details .user__content .play__btn {
  display:flex;
  cursor: pointer;
  }
  
  .client__says .user__details .user__content .play__btn img {
  width:20px;
  margin-right:10px;
  }
  
  .client__says .testimonail__item {
  width:80% !important;
  margin-top:2rem;
  }
  
  .client__says .testimonail__item p {
  font-size:13px;
  }
  
  .footer__section .footer__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  }
  
  .footer__section .footer__block .footer__logo h3 {
  font-size:24px;
  }
  
  .footer__section .footer__links ul {
  list-style:none;
  padding-left:0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:0;
  }
  
  .footer__section .footer__links ul li {
  margin-right:2rem;
  font-size:14px;
  }
  
  .footer__section .footer__icons a img {
  filter: invert(53%) sepia(0%) saturate(0%) hue-rotate(34deg) brightness(93%) contrast(94%);
  margin-right:10px;
  }
  
  .footer__section .copyright__text p {
  color: #666;
  text-align: center;
  font-size: 12px;
  }

  .leasing__modal .modal-dialog {
  width: 50vw !important;
  max-width: 50vw !important;
  margin: 5rem auto !important;
  }

  .leasing__modal .modal-content {
  height:30rem !important;
  }

  .login__modal .modal-dialog {
  width: 100vw;
  max-width: 100vw;
  margin-top: 0;
  margin-bottom: 0;
  }

  .login__modal .modal-body {
  padding:0;
  }

  .login__modal .modal-content {
  border-radius: 0;
  height: 100vh;
  }

  .login__modal .modal-header {
  padding: 0;
  border: 0;
  height: 0;
  }

  .login__modal .modal-header .close {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 999;
  color: #A80000;
  opacity: 1;
  }

  .login__modal .form__content {
  display: flex;
  }

  .login__modal .form__content .left__block {
  background:url("../../assets/images/login__bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height:100vh;
  width:50%;
  padding:2rem;
  }

  .login__modal .form__content .navbar-header-title {
  font-size:32px;
  }

  .login__modal .form__content .right__block {
  height:100vh;
  width:50%;
  padding:5rem;
  }

  .login__modal .form__content .right__block .form__heading {
  position: relative;
  }

  .login__modal .form__content .right__block .form__heading .back__arrow {
  position: absolute;
  top: 10px;
  cursor: pointer;
  }

  .login__modal .form__content .right__block .form__section {
  width: 80%;
  margin: 2rem auto;
  }

  .login__modal .form__content .right__block .form__button {
  margin-top:20px;
  margin-bottom:20px;
  }

  .login__modal .form__content .right__block .form__button button {
  width:100%;
  }

  .login__modal .form__content .or__connect p {
  font-size:13px;
  font-weight:600;
  text-align: center;
  font-family: "MontserratSemiBold";
  }

  .login__modal .forgotpw {
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size:13px;
  }

  .login__modal .forgotpw .checkbox__block {
  display:flex;
  align-items: center;
  }

  .login__modal .forgotpw .checkbox__block label {
  margin-bottom:0;
  margin-left:5px;
  }

  .login__modal .form__social .socail__block button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  color:#fff;
  margin-bottom:2rem;
  padding:0;
  font-size:13px;
  }

  .login__modal .form__social .socail__block button img {
  margin-right:10px;
  width:40px;
  }

  .login__modal .form__social .socail__block {
  border-radius:5px;
  }

  .login__modal .form__social .socail__block.gp {
  background: #F44336;
  }

  .login__modal .form__social .socail__block.fb {
    background: #3B5998;
    }

    .login__modal .form__social .socail__block.li {
      background: #058ED5;
      }

      .login__modal .form__social .socail__block.apple {
        background: #717171;
        }
  
  /* homepage new design end */



  /* new landloard dashboard design starts */

  .old-landlord-dashboard {
  display:none;
  }

  .new-landlord-dashboard {
  display:block;
  }

  .term__sheet  .terms__block .record__deal {
  display: flex;
  align-items: self-end;
  justify-content: center;
  height: 100%;
  }

  .term__sheet  .terms__block .record__red p {
  text-align: center;
  color:#A80000;
  text-decoration:underline;
  }

  .term__sheet  .terms__block .record__blue {
  text-align: center;
  }

  .term__sheet .plan__ended {
  display: flex;
  flex-direction: column;
  align-items: end;
  }

  .term__sheet .plan__ended .plan__expire {
    max-width: 22rem;
  }

  .term__sheet .plan__ended .blue__button {
  background-color: #255cb7 !important;
  border-color: #255cb7 !important;
  color: #fff;
  min-width: 10rem;
  padding: 10px;
  border-radius: 5px;
  font-family: "Montserrat",sans-serif!important;
  }

  .term__sheet .bottom__counts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  }


  /* new landloard dashboard design ends */



  @media (min-width: 2000px) and (max-width: 4000px)  {

    .new__home {
      zoom:150%;
    }

    .login__modal .form__content .right__block {
      zoom:150%;
    }
    
    .new__home .banner__background {
      background-size: 50%;
      background-repeat: no-repeat;
      height: 45rem;
      background-position: right;
    }

  }

  @media (min-width: 4000px) and (max-width: 6000px) {
    
    .new__home {
      zoom:200%;
    }

    .login__modal .form__content .right__block {
    zoom:200%;
    }

    .new__home .banner__background {
      background-size: 50%;
      background-repeat: no-repeat;
      height: 45rem;
      background-position: right;
    }

  }

@media (max-width: 991px) {
  .modal__bg .modal__screen {
    width: 95% !important;
  }

  .fullscreen.modal__screen {
  width:100% !important;
  }

  .modalblock .modal-dialog {
    max-width: 95% !important;
  }

  .leasing__section {
    margin: 0;
  }

  .dtl__content .left__section .onetimefee__img {
    margin-left: 0;
  }

  .tenant__main {
    display: block !important;
  }

  .tenant__main .tenant__listing {
    width: 100% !important;
    max-width: 100% !important;
  }

  .term__sheet .right__block .property__dtl {
    display: block !important;
  }


  .term__sheet .right__block .heading__here {
    display: block !important;
  }

  .space__detail .scrolling__btns {
  right:11% !important;
  }

  .new__home .header__section .navbar-header-title {
  font-size:24px;
  }

  .new__home nav .main__menu {
  background: #fff;
  padding: 1rem;
  margin-left:0;
  }

  .new__home nav .main__menu a {
  margin-right:0;
  }

  .new__home nav .navbar-collapse {
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 4rem;
  transition: none;
  }

  .new__home .search__box {
  margin-bottom: 10px;
  }

  .new__home .banner__background {
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  background-position: right;
  background-color: #F6F6F6;
  padding: 1rem 2rem;
  }

  .new__home .banner__background .content__block {
  padding: 15px;
  background: #ffffff8c;
  margin-top: 2rem;
  }

  .new__home .banner__background .content__block h3 {
  margin-top: 0rem;
  font-size: 24px;
  }

  .new__home .banner__background .content__block h3 span {
  font-size: 24px !important;
  }

  .new__home .banner__background .banner__filter {
  display: block;
  }

  .new__home .advantage__section .white__box {
    bottom: -24%;
  }

  .new__home .how__works .work__block {
  margin-bottom: 3rem;
  }

  .new__home .footer__section .footer__block {
    display: block;
    text-align: center;
    }

    .new__home .footer__section .footer__links ul {
    display:block;
    }

    .new__home .footer__section .footer__links ul li {
    margin-right: 0;
    font-size: 14px;
    margin-bottom: 10px;
    }

    .new__home .banner__background .banner__filter .filter__block {
    margin-bottom:10px;
    }

}

@media (max-width: 767px) {

  .new__home .banner__background .content__block button {
    margin-bottom:1rem;
  }

  .browse__space .slider__item .img__block .price__pm {
  bottom:55%;
  }

  .new__home .banner__background .banner__filter .filter__block .input__block {
  display:block;
  }

  .client__says .testimonail__item {
  width:100%;
  }

  .new__home .banner__background .banner__filter .filter__block .input__block input {
  margin-right:0;
  margin-bottom:1rem;
  }

  .new__home .banner__background .banner__filter .filter__block .input__block select {
  margin-right:0;
  margin-bottom:1rem;
  }

  .new__home .advantage__section {
  height: auto;
  margin-bottom: 0;
  padding-bottom: 4rem;
  }
  
  .new__home .advantage__section .checkout__text {
  margin-bottom:1rem;
  }

  .new__home .advantage__section .white__box {
  position: relative;
  bottom: -15px;
  }

  .new__home .advantage__section .white__box .specs__block {
  margin-bottom:1rem;
  }

  .new__home .how__works {
  margin-top:0;
  }

  .msger {
    width: 75%;
    height: 28rem;
    top: 8%;
    right: 0;
  }

  .mobile__bottomspace {
    margin-bottom: 2rem;
  }

  .mobile__topspace {
    margin-top: 2rem;
  }

  .modal__bg .modal__screen .modal__header,
  .modal__bg .modal__screen .steps__block,
  .modal__bg .modal__screen .landlord-quiz-checkbox label {
    padding: 12px;
  }

  .main-heading-blue p {
    font-size: 20px;
  }

  .modal__bg .modal__screen .modal__header .header__logo h3,
  .modal__bg .modal__screen .steps__block .navbar-header-title {
    font-size: 22px !important;
  }

  .accordian__section {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .payment__section .right__section .grey__block {
    margin-top: 1rem;
  }

  .reviews__sections .review__block {
    margin-bottom: 1rem !important;
  }

  .reviews__sections .top__heading {
    text-align: center;
  }

  .reviews__sections .review__block .date {
    text-align: center;
  }

  .dtl__header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .dtl__header .dtl__headercontent {
    display: block;
  }

  .dtl__header .dtl__headercontent .dtl__logo {
    margin-bottom: 1rem;
  }

  .dtl__header .dtl__headercontent .dtl__next {
    justify-content: space-between;
  }

  .dtl__content .left__section {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .dtl__content .left__section .bottom__button {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .dtl__content .left__section .bottom__button button {
    display: block;
    margin-bottom: 1rem;
  }

  /* .right__section.virtual__tour,
  .right__section.professional__photo,
  .right__section.window__signage,
  .right__section.feature__listing {
    height: 15rem;
  } */

  .term__sheet *:not(i) {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    letter-spacing: 0;
  }

  .term__sheet .right__block .property__img {
    margin-bottom: 1rem;
    width: 100% !important;
    height: auto !important;
  }

  .term__sheet .right__block .top__details {
    display: block !important;
  }

  .term__sheet .term__button button {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .term__sheet .terms__block .record__deal {
  justify-content: flex-start;
  }
  
  .term__sheet .terms__block .record__red p {
  text-align: left;
  }

  .term__sheet .plan__ended {
  align-items: start;
  }

  .term__sheet .plan__ended .blue__button {
  margin-top:1rem !important;
  }

  .term__sheet .bottom__counts {
  display:block;
  }
}

.card__number {
  padding-left: 7px;
  padding-right: 7px;
  width: 7rem;
  margin-left: 10px;
}

.plan__name {
  font-weight: 600 !important;
  color: #255cb7;
}

.faq__section {
  padding-top: inherit;
}

.faq__section .short__decs p {
  margin-bottom: 5px;
}

.dv-btn {
  display: flex;
}

.faq__section .short__decs p {
  color: #5baffd;
}
/* .card-floating-col-sp{
display: flex;
}
.card-logo-sp{
height: 30px;
width: 30px !important;
}
.card-floating-label-sp input{
padding-left: 0;
padding-right: 0;
} */
/* .business-req-modal-screen{
max-height: 380px;
top: 50%;
} */

.business-req-modal-screen {
  height: 20rem !important;
  /* margin-top:6rem !important; */
}

.business-req-modal-screen.congratulations {
  height: 16rem !important;
}

.business-req-modal-screen.congratulations .steps__block {
  height: 12rem !important;
}

.business-req-modal-screen .steps__block {
  height: 7rem !important;
}

.modal__bg .modal__screen .btn-primary {
  color: #fff !important;
  background-color: #a91622 !important;
  border-color: #a91622 !important;
  box-shadow: none !important;
  /* font-size:12px; */
}

.like__icon {
  /* width: 4rem;
height: 4rem;
margin: 1rem auto;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
background: #255CB7; */
  padding-top: 15px;
  padding-bottom: 15px;
}

.like__icon img {
  width: 60px;
}

.about__business input,
.about__business select {
  font-size: 14px;
}

.utilities__block {
  padding-left: 0;
}

.utilities__block .cursor {
  border-radius: 5px;
  background: #f6f6f6;
  border-color: #f6f6f6;
  margin-bottom: 10px;
  display: inline-flex;
  min-width: 5rem;
  min-height: 2rem;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.utilities__block .cursor.button-1 {
  background: #5baffd;
  border-color: #5baffd;
  color: #fff;
}

.requirement-label-content {
  font-family: "MontserratSemiBold";
  font-size: 13px;
  font-weight: 700 !important;
}

.custom__blue {
  font-size: 13px;
  margin-bottom: 10px;
  width: 9rem;
}

.custom__button {
  font-size: 13px;
  margin-bottom: 10px;
}

.text-editor-modal-body {
  height: 596px;
  overflow-y: scroll;
}

.gpt-textarea-sp {
  width: 100%;
  height: 200px;
}

.intro-text-font {
  font-family: "MontserratSemiBold";
  font-size: 18px;
}

.term__sheet {
  background: #f6f6f6;
}


.term__sheet .top__heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.term__sheet .top__heading h3 {
font-size:20px;
font-family: "MontserratSemiBold";
}

.term__sheet .right__block .top__details {
display: flex;
justify-content: flex-start;
align-items: center;
padding: 1rem;
background: #fff;
border-radius: 10px;
border: 1px solid #EDEBE9;
}

.term__sheet .right__block .top__details .term__button {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
}

.term__sheet .right__block .top__details .term__button button {
margin-bottom: 1rem;
margin-right: 0;
display: flex;
align-items: center;
justify-content: center;
}

.term__sheet .right__block .top__details .term__button button img {
width:20px;
margin-right:5px;
}

.term__sheet .right__block .property__img {
  width: 15rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 2rem;
}

.term__sheet .right__block .property__img img {
  width: 100%;
  max-width: 100%;
  height:100%;
}

.term__sheet .right__block .property__dtl {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.term__sheet .right__block .property__dtl p {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif !important;
}

.term__sheet .right__block .property__dtl p.font__bold {
  font-weight: 700 !important;
}

.term__sheet .right__block .property__dtl p .primary-blue-color {
  font-weight: 700 !important;
}

.term__sheet .right__block .heading__here {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #edebe9;
  padding-bottom: 10px;
}

.term__sheet .right__block .heading__here h3 {
  font-size: 20px;
}

.term__sheet .right__block .heading__here img {
  width: 15px;
  cursor: pointer;
}

.term__sheet .right__block .terms__block {
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  margin-top: 1rem;
}

/* .term__sheet .right__block .terms__block .bottom__block .content__here {
  padding: 10px;
  border: 1px solid #edebe9;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
} */

.term__sheet .right__block .terms__block .bottom__block .content__here {
padding: 10px;
border-bottom: 1px solid #edebe9;
margin-top: 5px;
margin-bottom: 5px;
position: relative;
}

.term__sheet .right__block .terms__block .bottom__block .terms__actionsbtn {
  position: absolute;
  right: 5px;
  top: 5px;
}

.term__sheet .right__block .terms__block .bottom__block .terms__actionsbtn img {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.term__sheet .right__block .terms__block .bottom__block .content__here p {
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}

.term__sheet
  .right__block
  .terms__block
  .bottom__block
  .content__here
  .font__bold {
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
}

.term__sheet .right__block .terms__block .action__button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.5rem;
}

.term__sheet .right__block .terms__block .action__button i.down {
  transform: rotate(180deg);
}

.term__sheet .right__block .terms__block .action__button img {
  margin-right: 10px;
  cursor: pointer;
}

.term__sheet .term__button .red__button {
  background-color: #a80000 !important;
  border-color: #a80000 !important;
  color: #fff;
  min-width: 10rem;
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  font-family: "Montserrat",sans-serif!important;
}

.term__sheet .term__button .blue__button {
  background-color: #255cb7 !important;
  border-color: #255cb7 !important;
  color: #fff;
  min-width: 10rem;
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  font-family: "Montserrat",sans-serif!important;
}

.term__sheet .disclaimer__text {
  margin-top: 2rem;
}

.term__sheet .disclaimer__text p {
  opacity: 0.5;
  font-size: 14px;
}

.blue__btn {
background-color: #255cb7 !important;
border-color: #255cb7 !important;
color: #fff;
border-radius: 5px;
height: 40px;
font-size: 13px;
padding: 5px 15px;
min-width: 8rem;
}

.red__btn {
background-color: #A80000 !important;
border-color: #A80000 !important;
color: #fff;
border-radius: 5px;
height: 40px;
font-size: 13px;
padding: 5px 15px;
min-width: 8rem;
}

.space__detail.mar-top-detail {
margin-top:30px !important;
}

.space__detail .space__disc .left__block h4 {
font-family: "MontserratSemiBold";
font-size: 16px;
font-weight: 700;
margin-bottom:1rem;
}

.space__detail .space__disc .content__block {
margin-bottom:1rem;
}

.space__detail .space__disc .content__block span {
font-family: "MontserratSemiBold";
font-size: 15px;
font-weight: 700;
}

.space__detail .space__disc .content__block p {
margin-bottom:0;
}

.space__detail .right__block .grey__block {
background: #F6F6F6 0% 0% no-repeat padding-box;
border: 1px solid #E5E5E5;
border-radius: 5px;
}

.space__detail .right__block .grey__block .user__dts {
border-bottom: 1px solid #E5E5E5;
margin: 1.5rem;
padding-bottom: 1rem;
}

.space__detail .right__block .grey__block .user__dts h6 {
margin-bottom:0;
}

.space__detail .right__block .grey__block .user__dts span {
color: #255cb7;
}

.space__detail .right__block .grey__block .connect__me {
margin: 1.5rem;
text-align: center;
}

.space__detail .right__block .grey__block .connect__me .blue__button {
background-color: #255cb7 !important;
border-color: #255cb7 !important;
color: #fff;
min-width: 7rem;
padding: 10px;
border-radius: 5px;
margin-right: 15px;
}

.space__detail .right__block .grey__block .connect__me .light__bluebtn {
background:#007adf;
color: #fff;
min-width: 7rem;
padding: 10px;
border-radius: 5px;
margin-right: 15px;
}

.space__detail .slider__block {
margin-bottom:1rem;
}

.space__detail .slider__block .image-gallery-fullscreen-button,
.space__detail .slider__block .image-gallery-play-button {
display:none;
}

.space__detail .slider__block .image-gallery-content .image-gallery-slide .image-gallery-image {
object-fit: cover;
}

.space__detail .slider__block .image-gallery-thumbnail {
display: inline-block;
border: 4px solid transparent;
transition: border .3s ease-out;
width: 140px;
background: transparent;
padding: 0;
border-radius: 10px;
overflow: hidden;
}

.space__detail .slider__block .image-gallery-thumbnail .image-gallery-thumbnail-image {
height: 100px;
}

.space__detail .slider__block .image-gallery-thumbnail.active,
.space__detail .slider__block .image-gallery-thumbnail:focus {
outline: none !important;
border: 2px solid #A80000 !important;
}

.space__detail .image-gallery-slide-wrapper {
position: static;
border-radius: 10px;
overflow: hidden;
}

.space__detail .image-gallery-left-nav .image-gallery-svg, 
.space__detail .image-gallery-right-nav .image-gallery-svg {
height: 30px;
width: 30px;
}

.space__detail .image-gallery-left-nav, 
.space__detail .image-gallery-right-nav {
padding: 0;
bottom: 45px;
top:auto;
transform: none;
width: 2rem;
height: 2rem;
background: #00000075;
margin-left: 10px;
margin-right: 10px;
}

.space__detail .scrolling__btns {
position: absolute;
top: 4%;
right: 8%;
}

.space__detail .scrolling__btns .circle__block {
background: #fff;
width: 3rem;
height: 3rem;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
cursor: pointer;
margin-left:auto;
position: relative;
z-index:2;
}

.space__detail .scrolling__btns .circle__block:hover {
background:#5BAFFD;
}

.space__detail .scrolling__btns .circle__block:hover img {
filter: invert(100%) sepia(0%) saturate(7471%) hue-rotate(129deg) brightness(99%) contrast(100%);
}

.space__detail .scrolling__btns .circle__block:hover + .text__block {
opacity:1;
/* margin-left:-150px; */
transition: all 0.5s;
}

.space__detail .scrolling__btns .text__block {
  color: #000000;
  width: auto;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 25px 0 0 25px;
  padding: 20px;
  margin-top: -43px;
  margin-right: 35px;
  transition: all 0.5s;
  opacity: 0;
}

.space__detail .amenities__section .amenties__block span {
background: #5BAFFD;
padding: 10px;
color: #fff;
margin-right: 10px;
border-radius: 5px;
margin-top: 10px;
display: inline-block;
}

.space__detail .virtual__tour {
border-radius: 10px;
overflow: hidden;
}

.space__detail .property__details .table__block {
margin-top:2rem;
}

.space__detail .property__details .table__block table .table__heading {
background: #A80000;
color: #fff;
border-radius: 5px 5px 0 0;
margin: 0;
padding: 10px;
}

.avatar-upload {
position: relative;
width: 130px;
margin: auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: -5px;
  z-index: 1;
  bottom: 0px;
}

.avtar__section input {
  display: none;
}

.avatar-upload .avatar-edit label {
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  cursor: pointer;
  display: block;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-preview {
  width: 9rem;
  height: 9rem;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); */
}

.avatar-upload .avatar-preview > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.modalblock * {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}

.modalblock .modal-dialog {
  max-width: 70%;
}

.modalblock.addterms .modal-dialog {
  max-width: 40%;
}

.modalblock.dislike__modal .modal-dialog {
  max-width: 40%;
}

.modalblock .modal-header {
  background: #f6f6f6;
  padding: 10px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalblock .modal-header .close {
  padding: 0;
  margin: 0;
  color: #a80000;
  opacity: 1;
  font-size: 35px;
}

.modalblock .modal-header h2 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.modalblock .modal-body {
  padding: 1rem;
  max-height: 30rem;
  overflow-y: scroll;
  overflow-x: auto;
}

.delete__modal__body {
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalblock .qna__block {
  margin-bottom: 2rem;
}

.modalblock .qna__block .question__block p {
  font-weight: 600;
  font-size: 14px;
}

.modalblock .qna__block .answer__block .slider__sec {
  margin-top: 2rem;
}

.slider__sec .rangeslider-horizontal {
  background: transparent
    linear-gradient(
      90deg,
      #268919 0%,
      #7e940d 25%,
      #0077be 50%,
      #ef730e 74%,
      #e80502 100%
    )
    0% 0% no-repeat padding-box;
  zoom: 80%;
  width: 90%;
}

.slider__sec .rangeslider-horizontal {
  height: 7px;
  border-radius: 10px;
}

.slider__sec .rangeslider-horizontal .rangeslider__fill {
  background: transparent;
}

.slider__sec .rangeslider-horizontal .rangeslider__handle {
  background: #255cb7;
}

.slider__sec .rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;
}

.slider__sec .rangeslider .rangeslider__handle {
  border: transparent;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: none;
}

.slider__sec .rangeslider-horizontal .rangeslider__handle {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #255cb7;
}

.modalblock .qna__block .answer__block textarea {
  width: 100%;
  border: 1px solid #edebe9;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.modalblock .qna__block .answer__block textarea::placeholder {
  font-size: 14px;
  color: #000;
}

.modalblock .modal__button {
  text-align: center;
}

.modalblock .modal__button button {
  width: auto;
  min-width: 180px;
  background: #a91622 !important;
  border-color: #a91622 !important;
  box-shadow: none !important;
  font-size: 14px;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

.modalblock .modal__button button.red__btn {
  background: #a80000 !important;
}

.modalblock .modal__button button.blue__btn {
  background: #255cb7 !important;
}
.modalblock .range__slider input[type="range"] {
  width: 60%;
}

.brochure__block * {
  font-family: "Montserrat", sans-serif;
}

.brochure__block.container {
  /* max-width:85%; */
  margin: 10px;
  width: 1300px !important;
  max-width: 1300px !important;
}

/* .brochure__block .brochure__logo {
background:url('../images/brocher/top__bg.png');
background-position: right;
background-size: cover;
background-repeat: no-repeat;
padding:2rem;
} */

.brochure__block .brochure__logo img {
  width: 1250px;
}

.brochure__block .main__area {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #fff;
  margin: 0;
  display: flex;
  min-height: 30rem;
}

.brochure__block .main__area .left__section {
  width: 600px;
  padding-left: 15px;
  padding-right: 15px;
}

.brochure__block .main__area .one__img {
  height: 470px;
}

.brochure__block .main__area .one__img .image__block {
  height: 100%;
}

.brochure__block .main__area .two__img .image__block {
  height: 260px;
  border-radius: 5%;
  margin-bottom: 15px;
}

.brochure__block .main__area .two__img .image__block img {
  width: 100%;
}

.brochure__block .main__area .left__section .left__images .three__img {
  display: flex;
  flex-wrap: wrap;
}

.brochure__block .main__area .three__img .image__block:nth-child(1) {
  flex: 100%;
  height: 300px;
}

.brochure__block .main__area .three__img .image__block:nth-child(1) img {
  width: 100%;
}

.brochure__block .main__area .three__img .image__block:nth-child(2) {
  flex: 45%;
}

.brochure__block .main__area .three__img .image__block:nth-child(3) {
  flex: 45%;
}

.brochure__block .main__area .four__img {
  display: flex;
  flex-wrap: wrap;
}

.brochure__block .main__area .four__img .image__block {
  width: 250px;
  max-width: 250px;
  max-height: 250px;
  margin: 10px;
}

.brochure__block .main__area .right__content {
  width: 700px;
  padding-left: 15px;
  padding-right: 15px;
}

.brochure__block .main__area .image__block {
  box-shadow: 0px 0px 30px #00000029;
  border: 5px solid #ffffff;
  border-radius: 10%;
  overflow: hidden;
  padding: 0;
  min-height: 295px;
  display: flex;
  justify-content: center;
  background: #fff;
  margin: 10px;
}

.brochure__block .redheading {
  font-size: 20px !important;
  color: #a80000 !important;
  font-weight: 800 !important;
}

.brochure__block .heading__block h4 {
  font-size: 24px;
  color: #000;
  font-weight: 800;
}

.brochure__block .main__area .image__block img {
  border-radius: 5%;
}

.brochure__block .main__area .dot__icon {
  width: 8px;
  margin-top: -4px;
}

.brochure__block .main__area .bottom__block {
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.brochure__block .main__area .bottom__block p {
  color: #000;
  /* font-size:16px; */
}

.brochure__block .footer__section {
  background: #255cb7;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
  margin: 0;
  /* margin-top:-4rem; */
  display: flex;
}

.footer__section .footer__left {
  width: 600px;
  padding-left: 15px;
  padding-right: 15px;
}

.footer__section .footer__right {
  width: 700px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.brochure__block .footer__section .qr__img {
  width: 105px;
  position: absolute;
  right: 15px;
  top: -85px;
}

.brochure__block .footer__section .footer__content {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.brochure__block .footer__section .footer__content p {
  color: #fff;
  margin-bottom: 0;
  font-size: 12px;
}

/* multi-step form */

.multistep__blocks {
  margin-top: 1rem;
  /* margin-bottom: 2rem; */
}

.multistep__blocks
  .ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.multistep__blocks
  .ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.multistep__blocks
  .ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #255cb7;
}

.multistep__blocks
  .ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.multistep__blocks
  .ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #255cb7;
  font-size: 14px;
  font-weight: 600;
}

.multistep__blocks
  .ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 14px;
}

.multistep__blocks
  .ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.multistep__blocks
  .ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.multistep__blocks
  .ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #8d8d8d;
}

.multistep__blocks
  .ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.multistep__blocks
  .ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #8d8d8d;
}

.multistep__blocks .ant-steps.ant-steps-dot .ant-steps-item-tail::after {
  width: calc(100% - 0px);
  height: 3px;
  margin-inline-start: 0px;
}

.multistep__content h4 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 20px;
}

.multistep__content h4 span {
  color: #5baffd;
  font-weight: 700;
  margin: 0 5px;
}

.multistep__content .ant-steps.ant-steps-dot .ant-steps-item-icon, 
.multistep__content .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
width: 12px;
height: 12px;
margin-top:-2px;
}

/* .glowing__textarea {
border: 1px solid #f89f9f;
box-shadow: -1px 1px 5px 2px #facbcb;
} */

.glowing__textarea {
  border: 1px solid #f89f9f;
  box-shadow: -1px 1px 5px 2px #facbcb;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff;
  }
  to {
    box-shadow: 0px 0px 0px 0px #facbcb, -2px 2px 10px 3px #facbcb;
  }
}

.toggle__wrapper {
 display: flex;
}

.toggle__wrapper .toggle__btn input {
display:none;
}

.toggle__wrapper .toggle__btn label {
border: 1px solid #5BAFFD;
padding: 10px;
width: 5rem;
display: flex;
justify-content: center;
align-items: center;
color: #5BAFFD;
}

.toggle__wrapper .toggle__btn label.create__label {
border-radius:5px 0 0 5px;
}

.toggle__wrapper .toggle__btn label.share__label {
border-radius:0 5px 5px 0;
}

.toggle__wrapper .toggle__btn input:checked + label {
background: #5BAFFD;
color:#fff;
}

.toggle__text p {
text-align: center;
font-weight:500;
}



/* Niral start */

.tenant__main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.tenant__margin {
  margin-right: 10px;
}

.tenant__main .tenant__listing {
border: 1px solid #edebe9;
border-radius: 5px;
opacity: 1;
padding: 15px;
min-height: 140px;
margin-bottom: 1rem;
background-color: #fff;
display: flex;
flex: 45%;
max-width: 49%;
position: relative;
}

.tenant__main .tenant__listing:nth-child(odd) {
margin-right:1rem;
}

.tenant__main .tenant__listing h2 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 700;
}

.tenant__main .tenant__listing p {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
}

.tenant__main .tenant__listing .tennat__content {
  margin-left: 10px;
  width: 85%;
}

.tenant__main .tenant__listing .tenant__img {
  width: 10%;
}

.tenant__main .tenant__listing .tenant__img img {
  width: 100%;
}

/* .tenant__main .tenant__listing .tenant__radio {
  position: relative;
} */

.tenant__main .tenant__listing .tenant__radio input + label {
position: absolute;
width: 100%;
height: 100%;
display: block;
left: 0;
top: 0px;
cursor: pointer;
}

.tenant__main .tenant__listing .tenant__radio input {
  position: absolute;
  /* top: -10%; */
}

.modalblock.tenant .modal-dialog {
  max-width: 55%;
  margin: 3rem auto;
}

.image__block {
  position: relative;
}

.image__block .Enlarge__icon img {
  right: 0%;
  position: absolute;
  top: 0%;
  width: 40px;
}

.right__img {
  position: relative;
}

.right__img .Enlarge__icon img {
  right: 0%;
  position: absolute;
  top: 0%;
  width: 40px;
}

.modalblock.leasingplan__dashboard.modal-dialog {
  max-width: 50%;
}

.tenantlist__modal .modal-dialog {
max-width:70%;
}

.modalblock.leasingplan__dashboard .modal-header .close {
  padding: 0;
  margin: 0;
  color: #a80000;
  opacity: 1;
  font-size: 35px;
  position: absolute;
  top: 2%;
  right: 2%;
}

.no-header .modal-header {
padding:0;
}

.no-header .modal-body {
margin-top:40px;
}

/* Niral end */

.signage__bg * {
  font-family: "Montserrat", sans-serif;
}

.signage__bg {
  background: #a71d21;
  padding: 1rem;
  width: 750px;
  margin: auto;
}

.signage__bg .heading__block {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.signage__bg .heading__block h3 {
  font-size: 6rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}

.signage__bg .black__bg {
  background: #000;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.signage__bg .black__bg h3 {
  font-size: 5rem;
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}

.whitebg__section {
  background: #fff;
  padding: 2rem;
  display: flex;
}

.whitebg__section .qr__img {
  padding: 1rem;
}

.whitebg__section .qr__img img {
  width: 300px;
  max-width: 300px;
}

.whitebg__section .right__content {
  padding: 1rem;
}

.whitebg__section .right__content p {
  font-size: 4rem;
  margin-bottom: 25px;
  line-height: 45px;
  font-weight: 600;
}

.whitebg__section .right__content p span {
  color: #a71d21;
}

.whitebg__section .border__block {
  border: 1px solid #000;
  padding: 20px;
  display: inline-block;
}

.whitebg__section .border__block p {
  margin-bottom: 0;
  /* letter-spacing: 5px; */
}

.footer__text {
  background: #fff;
  padding: 2rem;
  border-top: 3px solid #000;
}

.footer__text h3 {
  font-size: 6rem;
  color: #a71d21;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}

.sma__modal .modal-dialog {
  max-width: 70%;
}

.sma__modal .image__block {
/* position: relative;
padding: 1rem;
border: 1px solid #ccc; */
min-height: 312px;
border: 1px solid #f0f0f0;
border-radius: 5px;
display: flex;
text-align: center;
overflow: hidden;
}

.sma__modal .details__blk {
display: flex;
justify-content: space-between;
align-items: center;
}

/* .sma__modal .image__block .download__icon {
position: absolute;
z-index: 2;
width: 2.5rem;
height: 2.5rem;
background: #a80000;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
top: 10px;
right: 10px;
} */

.sma__modal .image__block .download__icon img {
/* filter: invert(99%) sepia(5%) saturate(0%) hue-rotate(153deg) brightness(117%)
  contrast(100%); */
width: 60px;
}

.sma__modal .step__block .heading__here p {
color: #a80000;
font-family: "MontserratSemiBold";
font-size: 16px;
font-weight: 700 !important;
margin-bottom:0;
}

.sma__modal .step__block ul {
list-style:none;
padding-left:0;
}

.sma__modal .step__block ul li {
display: flex;
align-items: center;
border: 1px solid #5baffd;
padding: 5px;
border-radius: 5px;
margin: 10px auto;
color: #255CB7;
font-size: 14px;
}

.sma__modal .step__block ul li .number {
background: #A80000;
color: #fff;
width: 2rem;
height: 2rem;
display: inline-flex;
justify-content: center;
align-items: center;
border-radius: 50%;
font-weight: 600;
margin-right: 2%;
}

.sma__modal .step__block ul li span {
width:90%;
}

.clipboard__section .copy__icon {
width: 2.5rem;
height: 2.5rem;
/* background: #a80000; */
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
position: absolute;
right: 20px;
top: 8px;
cursor: pointer;
}

.media__tool .modal-dialog {
max-width:40%;
}


.media__tool .modal-header {
border: none;
background: transparent;
}

.media__tool .modal-header .modal-title {
margin:auto;
color:#000;
}

.media__tool .main__content {
display:flex;
justify-content: center;
align-items: center;
margin-top:20px;
margin-bottom:20px;
}

.media__tool .pos-relative {
text-align: center;
margin-right: 1rem;
margin-left: 1rem;
}

.media__tool .main__content .download-my-social-image {
border: 1px solid #EDEBE9;
border-radius: 30px;
/* margin-right: 20px; */
overflow: hidden;
}

.media__tool .main__content .download-my-social-image:hover {
background: transparent linear-gradient(135deg, #5BAFFD 0%, #255CB7 100%) 0% 0% no-repeat padding-box;
cursor: pointer;
}

.media__tool .main__content .download-my-social-image:hover .broc-image img {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(273deg) brightness(200%) contrast(200%);
}

.media__tool .main__content .broc-image img {
width:55px;
}

.media__tool .media__name {
font-weight:bold;
font-size:13px;
text-align: center;
margin-bottom:0;
margin-top:10px;
}

.clipboard__section .copy__icon img {
/* filter: invert(99%) sepia(5%) saturate(0%) hue-rotate(153deg) brightness(117%)
  contrast(100%); */
width: 60px;
}

.clipboard__section .input__block {
position: relative;
}

.clipboard__section textarea {
border: 1px solid #707070;
border-radius: 5px;
padding: 10px;
resize: none;
height: 55px;
width: 100%;
color: #5BAFFD;
text-decoration: underline;
}

.clipboard__section textarea:focus {
  outline: none;
}

.dollar__symbol {
position: absolute;
top: 11px;
left: 10px;
font-size: 12px;
}

.featurelist__property {
white-space: nowrap; 
width:100%; 
overflow: hidden;
text-overflow: ellipsis;
font-size: 16px !important;
}

/*Hide number input arrows starts in cvv*/
/* Chrome, Safari, Edge, Opera */
.CCVCode input::-webkit-outer-spin-button,
.CCVCode input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.CCVCode input[type="number"] {
  -moz-appearance: textfield;
}

.term__sheet .right__block .terms__block .bottom__block {position:relative;}

.ant-week-picker {
  padding: 25px 20px 8px 15px;
  color: var(--primary-color);
  font-family: var(--primary-font-medium);
  text-transform: uppercase;
  display: flex;
  justify-content: end;
}

.desc_dark{
 color: #000;
}

.desc_light{
  color: #999;
}

.desc-placeholder{
  top: -8px;
  font-size: 13px;
  color: #5baffd;
}

.slick-arrow:before {
  font-size: 38px !important;
  color: black !important;
}

.slick-list{
  margin: 0 0 0 15px !important;
}

/* For Image  */

.user-image {
  border-radius: 50% !important;
  height: 70px !important;
  width: 70px !important;
}

.span_disabled {
  pointer-events: none;
}

.section-banner1__bg {
  background-image: url("../../assets/images/landingpage__banner1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(49vh - 0px);
}

.section-banner1__bg p {
  color: white;
  width: 352px;
  margin: 7rem 0rem 7rem 20rem;
  font-size: 25px;
  font-family: 'Montserrat';
}

.section-banner2__bg {
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(56vh - 0px);
}

.section-banner2_content{
  padding-top: 48px;
}

.section-banner2_content p{
  padding-left: 307px;
  font-family: 'Montserrat';
  font-size: 15px;
}

.cls-link{
  cursor:pointer;
  color:blue;
  text-decoration:underline !important;
}

.close__chat {
  width: 40px;
  height: 40px;
  background: #4f46e5;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -40px;
  cursor: pointer;
}