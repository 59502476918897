@media (max-width: 800px) and (min-width: 300px) {

    .sec1-h1-mobile-reponsive {
        font-size: 5vw !important;
        margin: 0 0 2vh 0 !important;
        width: 100% !important;
    }

    .sec1Bg {
        width: 75% !important;
        height: 10vh !important;
        padding: 0px 14px !important;
    }

    .my-field .jZkBQk {

        height: 8vh !important;
    }

    .my-field .jZkBQk>.wrapper {
        height: 8vh !important;

    }

    .jZkBQk .wrapper {
        font-size: 2vh !important;
    }

    .frjscc {
        min-height: unset !important;
    }

    .frjscc>input {
        letter-spacing: 0px;
        /* padding: 0 0 0 3px !important; */
    }


    .sec1-search-mobile-reponsive {
        width: 100% !important;
        font-size: 1vw !important;
    }

    .sec1-search-btn-mobile-reponsive {
        margin-left: 1vw !important;
        width: 50% !important;
        height: 8vh !important;
        font-size: 2.5vw !important;
    }

    .sec1-text-mobile-reponsive {
        font-size: 2.0vw !important;
        line-height: 2.4vw !important;
        width: 100% !important;
        margin-top: 4vw !important;
    }

    .sec1-buttons-mobile-reponsive {
        font-size: 2vw !important;
        padding: 1.5vw 2vw !important;
    }

    .sec3-heading-mobile-responsive {
        font-size: 7vw !important;
        padding: 14vw 0 0 0 !important;
    }


    .bg-section-3 h4 {
        font-size: 2vw !important;
        margin-bottom: 1vh !important;

    }

    .font-7w-mobile-responsive {
        font-size: 7vw !important;
        margin: 0 0 0 0 !important;

    }

    .bg-section-3 h5 {
        font-size: 2.5vw !important;
        margin: 0 0 1vh 0 !important;
    }

    .bg-section-3 p {
        line-height: 2vw !important;
        font-size: 1.5vw !important;
        margin-bottom: 1vh !important;
    }

    .navbar-logo-mobile-responsive {
        margin-left: 5vw !important;
    }

    .navbar-link-mobile-responsive {
        margin-left: 6vh !important;
    }

    .footer-link {
        font-size: 2vw !important;
        margin-left: 10px;
    }

    .icon-color {
        height: 3.5vw !important;
        width: 3.5vw !important;
    }

    .icon-color-2 {
        height: 3.5vw !important;
        width: 3.5vw !important;
    }

    .footer-icon {
        font-size: 4vw;
    }

    .footer-p {
        font-size: 1.6vw !important;
    }

    .section2-row-mobile-responsive {
        margin: 0 0 0 0 !important;
    }

    .video-parent-div-mobile-responsive {
        display: flex !important;
        justify-content: center !important;
    }

    .video-div-mobile-responsive {
        width: 60vw !important;
        height: 30vw !important;
        padding: 0 0 0 0 !important;

    }

    .redhanded-video .react-player__play-icon {
        color: var(--m-blue) !important;
        border-width: 1vw 0px 1vw 2.3vw;
        border-color: transparent transparent transparent white;
        margin-left: 0.5vw;
    }

    .video-btn-color {
        height: 7vw !important;
        width: 7vw !important;
    }

    .sec2-icon-mobile-responsive {
        display: flex !important;
        vertical-align: baseline !important;

    }

    .bg-section-2 h1 {
        font-size: 4vw;
        margin-top: 13vw !important;
        margin-bottom: 2vh !important;
    }

    .bg-section-2 h5 {
        font-size: 2vw;

    }

    .bg-section-2 p {
        font-size: 1.6vw !important;
        line-height: 2vw !important;
        color: #D0E7FF;
        font-family: 'Lato';
    }

    .sec2-p-mobile-responsive {
        margin-bottom: 0 !important;
    }

    .container-margin-padding {
        margin: 0 !important;
        padding: 0 !important;
    }

    .sec5-p-mobile-responsive {
        line-height: 3vw !important;
    }

    .start-commas {
        font-size: 5vw !important;
        top: 1vw !important;
        left: -2vw !important;
    }

    .end-commas {

        font-size: 5vw !important;
        top: 1.5vh !important;
        line-height: 1vh !important;
    }

    .sec5-p-mobile-responsive {
        font-size: 2.5vw !important;
    }

    .user-description-mobile-responsive {
        font-size: 2.5vw !important;
    }

    .user-pic img {
        width: 12vw;
        height: 12vw;
        margin-left: 0 !important;
    }

    .user-pic {
        margin-top: 1vw !important;
        margin-left: 0 !important;
    }

    .user-name-mobile-responsive {
        margin-bottom: 0 !important;
        font-size: 3.5vw !important;
    }

    .sec5-heading-mobile-responsive {
        font-size: 7vw !important;
        margin: 0 3vw 0 0 !important;
    }

    .sec5-mobile-responsive {
        padding-top: 3vw !important;
    }

    .sec5-text-mobile-responsive {
        margin-bottom: 0 !important;
        font-size: 2vw !important;
    }

    .user-mobile-responsive {
        margin-top: 2vw !important;
    }

    .margin-left-5vw {
        margin-left: 5vw !important;
    }

    .testimonial-card-height {
        height: 52vw !important;
    }

    .arrow-div {
        visibility: hidden;
    }

    .bg-section-4 .requirements h1 {
        font-size: 3vw !important;
        margin: 3vw 0 0 0 !important;
    }

    .bg-section-4 .requirements h5 {
        font-size: 2.5vw !important;
        margin: 0 0 0 0 !important;
    }

    .sec-address-listing {
        font-size: 2.5vw !important;

    }

    .sec-address-area-price {
        font-size: 2.6vw !important;

    }

    .primarry-address-listing {

        font-size: 4vw !important;
    }

    .slick-dots li button:before {
        border: 3px #458aee !important;
    }

}

@media (max-width: 750px) and (min-width: 600px) {
    .testimonial-card-height {
        height: 40vw !important;
    }
}

@media (max-width: 900px) and (min-width: 750px) {
    .testimonial-card-height {
        height: 35vw !important;
    }
}