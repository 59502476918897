:root {
    --v-light-blue: #F8FAFE;
    --d-blue: #08395D;
    --m-blue: #255cb7;
    --l-blue: #5BAFFD;
}

.d-flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.bg-l-blue {
    background-color: var(--l-blue);
}

.bg-m-blue {
    background-color: var(--m-blue);
}

.bg-d-blue {
    background-color: var(--d-blue);
}

.cl-l-blue {
    color: var(--l-blue);
}

.cl-m-blue {
    color: var(--m-blue);
}

.cl-d-blue {
    color: var(--d-blue);
}

.text-white {
    color: white !important;
}

.inline-block {
    display: inline-block;
}

.requirements .inline-block img {
    display: inline;
    width: auto !important;
    margin-right: 10px;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.foldbg {
    width: 100%;
    background-color: var(--v-light-blue);
    background-image: url(../images/circle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
}

.advantage-bg {
    width: 100%;
    background-image: url(../images/blue-circle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
}

.banner-logo {
    max-height: 360px;
}

.banner-heading {
    font-family: var(--tertiary-font-bold);
    color: var(--d-blue);
}

.btn-banner-one {
    background-color: var(--d-blue);
    border-color: var(--d-blue);
    font-family: var(--tertiary-font-regular);
    padding: 16px 36px !important;
    min-width: 220px;
    margin-bottom: 24px;
    border-radius: 6px;
    letter-spacing: .05em;
}

.btn-banner-two {
    font-family: var(--tertiary-font-regular);
    padding: 16px 36px !important;
    min-width: 220px;
    margin-bottom: 24px;
    border-radius: 6px;
    letter-spacing: .05em;
}

.montserrat {
    font-family: var(--tertiary-font-regular);
}

.redhanded-video {
    width: 80% !important;
    margin: auto;
}

.redhanded-video .react-player__preview {
    border-radius: 25px;
}

.redhanded-video .react-player__shadow {
    background: hsl(0deg 100% 44% / 87%) !important;
}

.redhanded-video .react-player__shadow {
    height: 100px !important;
    width: 100px !important;
}

.redhanded-video .react-player__shadow:hover {
    background: #900f0fde !important;
    height: 100px !important;
    width: 100px !important;
}

.redhanded-video .react-player__play-icon {
    color: var(--m-blue) !important;
    border-width: 25px 0px 25px 35px;
    border-color: transparent transparent transparent white;
    margin-left: 10px;
}

.color-red {
    color: var(--red-color);
}

.color-blue {
    color: var(--primary-blue-color);
}

.advantage-points li {
    font-family: var(--tertiary-font-regular);
    font-weight: bold;
    margin: 20px 0;
}

.advantage-points li::marker {
    font-size: 30px;
    color: rgba(169, 22, 34, 0.6);
}

.work-div {
    position: relative;
    padding: 4em 2em 3em;
    text-align: center;
}

.work-div span {
    font-family: var(--tertiary-font-regular);
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    right: 10px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-left: 2px solid white;
    padding: 6px 12px;
    bottom: 0;
}

.work-div h6 {
    margin-top: 1em;
}

span.price-listing-slider1 {
    /* position: absolute; */
    /* left: 0; */
    padding: .5em .25em;
    background-color: var(--m-blue);
    color: white !important;
    /* bottom: 0px; */
    text-align: center;
}

span.price-listing-slider {
    /* position: absolute; */
    /* right: 0; */
    padding: .5em .25em;
    background-color: var(--d-blue);
    color: white !important;
    /* bottom: 0px; */
    text-align: center;
}

.user-pic-parent-div {
    position: absolute;
    bottom: 10px;
}

.user-pic {
    margin-top: 1.5rem;
}

.user-pic img {
    margin: auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.testimonial .slick-list {
    min-height: 400px;
}

.testimonial .slick-track {
    top: 40px !important;
}

.small-carousel .user-name {
    margin-top: 0.3rem;
}

/* .testimonial .slick-slider {
    margin-top: 80px;
} */

@media (max-width: 767.98px) {
    .flex-xs-column {
        flex-direction: column;
    }

    div.carousel-user.small-carousel .carousel-body {
        padding: 5px 15px;
    }

    div.carousel-user.small-carousel {
        height: auto;
    }
}

@media (min-width: 768px) {
    .btn-banner-one {
        margin-right: 1.5em;
    }
}

.main-parent {
    overflow-x: hidden;
}