@media(max-width: 1400px) {
    .signup-wizard .form-bside {
        padding: 2.5rem;
    }
}

@media(max-width:1399px) {
    .react-player video {
        width: 100% !important;
    }

    .react-player {
        width: 100% !important;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .25rem;
        padding-left: .25rem;
        font-size: 13px;
    }
}

/* @media (min-width: 1460px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem !important;
        padding-left: .5rem !important;
        font-size: 24px !important;
    }
} */
@media(min-width:2450px) {
    .view-properties-body .view-properties-body-part .location-list .location-part img {
        max-height: 165px !important;
    }
}


@media (min-width: 1399px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
        font-size: 17px;
    }

    .image-size {
        height: 650px;
    }
}

@media (max-width: 1380px) {
    .cus-text-size {
        font-size: 14px !important;
        height: 43px;
    }

    .listing-height {
        height: 26px;
    }

    p.date-2::before {
        content: "";
        top: 9px;
        position: absolute;
        width: 70px;
        height: 1px;
        background-color: var(--color-background-grey-scondary);
        right: 65%;
    }

    p.date-2::after {
        content: "";
        top: 9px;
        position: absolute;
        width: 70px;
        height: 1px;
        background-color: var(--color-background-grey-scondary);
        left: 65%;
    }

    .message-design .msg-logo, .message-design .icon-modify {
        padding-left: 1rem;
    }

    .main-card .msg-logo {
        padding-left: 1rem;
    }
}

@media(max-width: 1350px) {
    .signup-wizard .form-bside {
        padding: 2rem;
    }
}

@media (max-width: 1345px) {
    .listing-page-detail-panel .main-success-wrapper .road-sign-right-side-content {
        padding: 65px 70px !important;
    }

    .redhanded-map-style-1 {
        width: 78% !important;
    }
}

@media (max-width: 1235px) {
    .listing-page-detail-panel .main-success-wrapper .road-sign-right-side-content {
        padding: 80px 70px !important;
    }

    .testimonial .carousel-user span {
        /* margin: 0; */
    }
}

@media (max-width: 1199px) {
    .redhanded-slogan, .redhanded-slogan-footer, .generic-heading.redhanded-thankyou-msg {
        font-size: 18px !important;
        letter-spacing: 0px !important;
    }

    .parent-search-filter-1 {
        padding-top: 10px !important;
    }

    footer .footer-left-menu a {
        font-size: 12px;
        margin: 0px 2px;
    }

    .slick-slider .carousel-user span.carousel-vedio-icon {
        top: 16%;
    }
}

@media (max-width: 1190px) {
    .listing-page-detail-panel .main-success-wrapper .success-body {
        padding: 40px 70px !important;
    }
}

@media (max-width: 1178px) {
    .listing-page-detail-panel .main-success-wrapper .road-sign-right-side-content {
        padding: 75px 70px !important;
    }

    .listing-page-detail-panel .main-success-wrapper .success-body {
        padding: 20px 70px !important;
    }

    .slick-slider .carousel-user span.carousel-vedio-icon {
        top: 25%;
    }
}

@media(max-width:1160px) {
    .redhanded .c-side p {
        font-size: 18px;
    }
}

@media (max-width: 1100px) {
    .date {
        word-break: break-word;
    }

    .redhanded-map-style-1 {
        width: 70% !important;
    }
}

@media (max-width: 1024) {
    .slick-slider .carousel-user span.carousel-vedio-icon {
        top: 25%;
    }
}

/* 
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .25rem;
        padding-left: .25rem;
    }
} */

@media (max-width:991px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .25rem;
        padding-left: .25rem;
        font-size: 15px;
    }

    .upgrade-div {
        padding: 0 10px;
    }

    .connection-name-part {
        margin-left: 10px;
    }

    .redhanded-map-style {
        width: 90% !important;
    }

    .dashboard-name-scroller {
        height: auto;
    }

    .list-detail-part .request {
        margin-top: 0;
    }

    .header-title-div {
        text-align: center;
    }

    div.how-it-works .work-list div {
        margin-top: 10px;
    }

    .position-player-card {
        margin: auto;
    }

    .cust-no-padd {
        padding: 0 !important;
        margin: 0 !important;
    }

    .redhanded-logo-homepage {
        margin-right: 0;
    }

    .redhanded-logo-homepage-parent {
        justify-content: center;
        display: flex;
    }

    .nav-bar-authentication-btn-row {
        justify-content: flex-start;
    }

    #nav-drop-down-profile .dropdown-menu {
        right: auto !important;
    }

    .social-icons-parent {
        justify-content: center;
    }

    .partner-donate-btn {
        float: none;
    }

    .days-button {
        background: #99B8CA;
        color: var(--white-color);
        border: none;
        padding: 10px 5px;
        position: absolute;
    }

    .small-form-side {
        padding: 3rem !important;
        margin: -4rem 2rem 10rem 4rem !important;
        background: var(--white-color);
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)
    }

    .main-body-form {
        padding-right: 15px !important;
    }

    .sidebar {
        left: -260px;
    }

    .sidebar.disappear {
        left: 0px;
    }

    .admin-margin-left {
        margin-left: 0;
    }

    button.menu-btn.menu-btn-link, button.cross-btn-navadmin {
        display: block;
    }
}

@media (max-width: 980px) {
    p.date-2::before {
        content: "";
        top: 9px;
        position: absolute;
        width: 70px;
        height: 1px;
        background-color: var(--color-background-grey-scondary);
        right: 70%;
    }

    p.date-2::after {
        content: "";
        top: 9px;
        position: absolute;
        width: 70px;
        height: 1px;
        background-color: var(--color-background-grey-scondary);
        left: 70%;
    }

    .message-design .paper {
        padding-left: 1rem !important;
    }

    .message-design .arrow {
        padding-left: 0rem !important;
    }

    .date {
        position: relative;
        right: 24px;
        word-break: normal !important;
    }
}

@media (max-width:950px) {
    .menu-item-nav-div a {
        padding-right: .5rem;
        padding-left: .5rem;
        font-weight: 500;
        font-size: 17px;
    }
}

@media (max-width: 900px) {
    .redhanded-slogan, .redhanded-slogan-footer, .generic-heading.redhanded-thankyou-msg {
        font-size: 15px !important;
    }

    .hide-me-onsmall {
        display: none;
    }

    .main-card .msg-logo {
        padding-left: 0.5rem;
    }
}

@media (max-width: 850px) {
    .post-property-panel .landlord-stepper .landlord-stepper-data, .post-property-panel .tenant-stepper .tenant-stepper-data {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .profile-mob-sub, .profile-mob-sub .profile-view {
        padding: 0 !important;
        margin: 0 !important;
    }

    .profile-mob-sub .profile-view .form-a {
        margin: 0 !important;
    }

    .profile-mob-sub h1 {
        font-size: 23px;
    }

    .date {
        word-break: normal;
        position: relative;
        right: 24px;
    }

    .main-card {
        padding: 1rem;
    }
}

@media (max-width:770px) {
    .aside-btn button {
        margin: 0px 25px 10px 0px !important;
    }

    .post-property-landlord-body .main-body, .post-property-panel .post-property-landlord-body .address-body, .post-property-panel .post-property-landlord-body .description-body, .post-property-panel .post-property-landlord-body .picture-body, .dashboard-body-parent, .bussiness-profile-body, .tenant-post-body .main-body {
        padding: 40px 5px;
        /* margin-top: 1rem !important; */
    }

    .main-card .msg-logo {
        padding-left: 0.5rem;
    }

    .post-property-panel .landlord-stepper .landlord-stepper-data {
        font-size: 13px;
    }
}

@media (min-width:990px) and (max-width:1030px) {
    #space-to-list {
        margin-left: 30%;
    }
}

@media (min-width:993px) and (max-width:1200px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .25rem;
        padding-left: .25rem;
        font-size: 15px !important;
    }
}

@media (min-width:1450px) and (max-width:1750px) {

    .carouserl-img-parent {
        height: 19vh;
    }
}

@media (max-width: 767px) {
    .show-on-mob-msg {
        display: block !important;
    }

    .hide-on-mob-msg, .hide-me-on-mob {
        display: none;
    }

    .show-mw-onMob {
        display: block;
    }

    .space-link {
        margin-left: 1rem;
    }

    .dashboard-body-parent .list-part {
        margin-left: 0;
        margin-right: 0;
    }

    .post-property-panel .landlord-stepper .landlord-stepper-data {
        font-size: 14px;
    }

    .aside-btn-role-parent {
        text-align: center;
    }

    .redhanded .b-side img {
        margin-top: 1rem;
    }

    .listing-page-detail-panel .main-success-wrapper .road-sign-right-side-content {
        position: unset !important;
    }

    .date {
        position: unset;
    }

    .comparison .row {
        background: #FFF;
    }

    .comparison td, .comparison th {
        border: 1px solid #CCC;
    }

    .price-info {
        border-top: 0 !important;
    }
}

@media (max-width:700px) {
    .signup-wizard .form-cside, .verification-body {
        margin: 2rem 3rem 10rem 3rem;
    }

    .post-property-panel .post-property-landlord-body .address-body {
        padding: 30px;
    }
}

@media (max-width:650px) {
    .days-button {
        background: #99B8CA;
        color: var(--white-color);
        border: none;
        padding: 10px 5px;
        position: absolute;
    }

    .dashboard-header-list-detail, .property-detail-parent .header-list-detail {
        padding: 0;
    }

    .property-detail-parent {
        margin: 0;
    }
}

@media (max-width: 639px) {
    .comparison .price-buy {
        padding: 5px 10px;
    }

    .comparison td, .comparison th {
        padding: 10px 5px;
    }

    .comparison .hide-mobile {
        display: none;
    }

    .comparison .price-now span {
        font-size: 16px;
    }

    .comparison .price-small {
        font-size: 16px !important;
        top: 0;
        left: 0;
    }

    .comparison .qbse, .comparison .qbo {
        font-size: 12px;
        padding: 10px 5px;
    }

    .comparison .price-buy {
        margin-top: 10px;
    }

    .compare-heading {
        font-size: 13px;
    }
}

@media (max-width:617px) {
    .dashboard-header-list-detail .requirement-body .business-profile-section {
        margin: 10px 3rem;
    }

    .dashboard-header-list-detail .requirement-body .requirment-name-section {
        margin: 10px 3rem;
    }

    .dashboard-header-list-detail .connection-listing-body .connection-list-part {
        margin: 0px 3rem;
    }

    .dashboard-header-list-detail .connection-listing-body .filter-part-connections {
        margin: 0px 3rem;
    }
}

@media (max-width: 600px) {
    .body-color {
        padding-left: 40px;
        padding-right: 40px;
    }

    .red {
        padding: 5px 15px 5px 15px;
        left: 38%;
        bottom: 10px;
    }

    .location {
        font-size: 20px;
        margin-left: 54px;
        padding-top: 40px;
    }

    .second-text {
        font-size: 12px;
        margin-left: 37px;
        padding-top: 4px;
    }

    .wd {
        width: 65%;
        margin-left: 54px;
        margin-top: 10px;
        margin-top: 17px;
    }

    .stst {
        font-size: 11px;
    }

    .view-profile-parent .profile-text {
        margin-left: 5rem;
        margin-top: -25px;
    }

    .view-profile-parent .view-listing-section .avenue-day-listed-row .listing-day-text {
        margin-left: 3rem;
        padding-top: 1rem;
    }

    .view-profile-parent .view-listing-section .sqft-psf-row .listing-1500-sqft-text {
        padding-top: 5px;
    }

    .view-profile-parent .view-listing-section .listing-view-btn {
        margin-left: 2rem;
        margin-bottom: 1rem;
        margin-top: -1px;
    }

    .view-profile-parent .form-a-business-profile .title-cofee-shop {
        margin-left: -4rem;
        margin-top: -2rem;
    }

    .view-profile-parent .leslee-invite-btn {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .view-profile-parent .tenant-logo .tea-mug-tenant {
        margin-left: 1rem;
        margin-bottom: -10px;
    }
}

@media (max-width:575px) {
    .btn-blue-border, .btn-blue-color {
        width: auto;
    }

    .profile .profile-view .form-a .main-bio-heading-1 {
        font-size: 20px;
    }

    .parent-search-filter {
        padding-top: 10px !important;
    }

    .dashboard-header-list-detail .connection-listing-body .connection-list-part {
        margin: 0px 1rem;
    }

    .dashboard-header-list-detail .requirement-body .business-profile-section {
        margin: 10px 1rem;
    }

    .header-title-div {
        margin-top: -35px;
    }

    .registration-as .registration-cards {
        margin: 0px;
    }

    .property-detail .analytics-body .analytics-parent .analtics-card .analytic-card-body .analytic-card-body-data {
        font-size: 2rem;
    }

    .dashboard-header h2, div.dashboard-body-parent .heading-dashboard, .post-property-panel .post-property-landlord-header .crossred, .post-property-panel .post-property-tenant-header .crossred {
        font-size: 30px;
        /* top: 85px; */
    }

    .dashboard-header h2, div.dashboard-body-parent .heading-dashboard, .post-property-panel .post-property-landlord-header .crossred-1, .post-property-panel .post-property-tenant-header .crossred-1 {
        right: 5px;
    }

    .dashboard-header-list-detail .requirement-body .requirment-name-section {
        margin: 10px 1rem;
    }

    .dashboard-header-list-detail .connection-listing-body .filter-part-connections {
        margin: 0px 1rem;
    }
}

@media (max-width:550px) {
    .add-coupon-btn {
        position: absolute;
        right: 10px;
        top: 60px;
    }

    .padding-right-to-msg {
        padding-left: 15px;
    }

    .property-detail-parent .header-list-detail .tenant-listing-body .tenant-body-part {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    /* .butn-one {
        left: -3%;
        font-size: 10px;
    }
    .butn-two {
        left: -2%;
        bottom: 6px;   
    } */
    .post-property-panel .post-property-landlord-body .description-body .form-aside .btn-1, .post-property-panel .post-property-landlord-body .picture-body .form-aside .btn-1 {
        margin-right: 10px;
    }

    .profile .profile-view .form-a span {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .property-detail .analytics-body .analytics-parent {
        padding: 0 10px;
    }

    .property-detail .analytics-body .analytics-tab-part span.tab-click {
        padding: 0 5px;
    }

    .profile .profile-view .form-a .main-bio-heading {
        font-size: 22px;
    }

    .review-landlord-detalis-utility-buttons {
        margin-left: 0px !important;
        font-size: 10px !important;
    }

    .custom---btn-right {
        margin-right: 0rem;
    }

    .custom---btn-left {
        margin-left: 0rem;
    }

    .main-body {
        padding: 10px;
    }

    .body-heading {
        font-size: 15px;
        padding-left: 0px;
        padding-top: 9px;
        margin-left: 30px;
    }

    .second-textt {
        font-size: 11px;
        padding-top: 2px;
        padding-left: 44px;
    }

    .square-footage {
        font-size: 11px;
        padding-top: 5px;
        padding-left: 45px;
    }

    .input-field1, .budget-sqft {
        margin-left: 15px;
    }

    .input-field2 {
        margin-left: 15px;
    }

    .utility-field {
        margin-left: 15px;
    }

    .state {
        font-size: 11px;
    }

    .button {
        width: 70px;
        margin-left: 52px;
        font-size: 10px;
    }

    /* .butn-one {
        border-radius: 5px;
        padding: 4px 12px 4px 12px;
        left: 13%;
        bottom: 45px;
        font-size: 12px;
        margin-right: 5px;
    } */
    /* .butn-two {
        border-radius: 5px;
        padding: 4px 12px 4px 12px;
        left: 13%;
        bottom: 45px;
        font-size: 12px;
    } */
    .profile .profile-view .form-a span {
        font-size: 10px;
    }
}

@media (max-width:476px) {
    .dashboard-header-list-detail .header-row span.section-heading {
        font-size: 14px;
    }

    .per-sqft {
        margin-left: 32px;
    }

    .property-detail .analytics-body .analytics-parent .upgrade-plan {
        padding: .7rem 5px;
    }

    .property-detail .analytics-body .analytics-parent .upgrade-plan .upgrade-plan-btn {
        padding: 10px;
    }
}

@media (max-width:470px) {
    .profile-section .user-name, .profile-image-section .profile-section {
        margin-left: 0px;
    }

    p.date-2::before {
        content: "";
        top: 9px;
        position: absolute;
        width: 60px;
        height: 1px;
        background-color: var(--color-background-grey-scondary);
        right: 75%;
    }

    p.date-2::after {
        content: "";
        top: 9px;
        position: absolute;
        width: 60px;
        height: 1px;
        background-color: var(--color-background-grey-scondary);
        left: 75%;
    }

    .main-card .msg-logo {
        padding-left: 0.5rem !important;
    }

    .date {
        position: relative !important;
        right: 10px;
    }
}

@media (max-width: 450px) {
    footer .footer-middle {
        position: absolute;
        bottom: 12px;
        /* bottom: 30px; */
        left: 100px !important;
    }

    footer .footer-aside {
        position: relative;
        top: 15px;
        right: 15px;
    }

    footer .footer-bside {
        position: absolute;
        right: 25px;
        bottom: 20px;
    }

    footer .footer-bside .bside-links {
        display: none;
    }

    footer .footer-left-menu {
        display: none;
    }

    .redhanded .a-side h1 {
        font-size: 1.5rem;
        margin: 3rem 0rem 0rem 0rem !important;
        line-height: inherit;
    }

    .redhanded .b-side img {
        position: relative;
        /* bottom: 12.5rem;
        left: 13rem; */
        width: 150px;
    }

    .redhanded .a-side .aside-btn button {
        width: 8rem;
        height: 2.5rem;
        font-size: 14px;
    }

    .redhanded .a-side .aside-btn {
        margin: 2rem 0rem 0rem 1rem !important;
    }

    .redhanded .c-side {
        display: none;
    }

    .requirements .tabs-btn {
        display: none;
    }

    .testimonial .coma {
        height: 80px !important;
    }

    .registration-as .registration-cards {
        margin: 0px !important;
    }

    div.how-it-works .work-list {
        padding: 0px !important;
    }

    .signup-wizard .form-aside, .SimilarListingSlider-body, .listing-page-detail-panel .main-success-wrapper .road-sign-right-side-content, .listing-page-detail-panel .main-success-wrapper .success-body {
        margin: 0rem !important;
        padding: 1rem !important;
    }

    .signup-wizard .form-aside h3, .signup-wizard .form-cside h3 {
        text-align: center !important;
        font-size: 1.4rem;
    }

    .signup-wizard .form-bside, .listing-page-detail-panel .somerset-avenue-body {
        padding: 2rem !important;
        margin: 2rem 0rem !important;
    }

    .signup-wizard .form-aside .form-group label {
        font-size: 0.9rem !important;
    }

    .signup-wizard .form-cside, .verification-body {
        margin: 2rem 0rem !important;
        padding: 2rem !important;
    }

    .profile .profile-view {
        margin: 2rem 0rem 2rem 0rem !important;
    }

    .profile .profile-view .a-side {
        margin: 0rem !important;
    }

    .profile .profile-view .form-a {
        margin: 2rem 0 !important;
        padding: 1rem !important;
    }

    .profile .profile-view .form-a .title {
        font-size: 1rem !important;
    }

    .profile .profile-view .form-a .title-info {
        font-size: 0.8rem !important;
    }
}

@media (max-width:440px) {
    .dashboard-header-list-detail .connection-listing-body .filter-part-connections {
        margin: 0px 1rem;
    }

    .slick-slider .carousel-user span.carousel-vedio-icon {
        top: 27%;
    }
}

@media (max-width: 425px) {
    .property-detail-parent .header-list-detail .header-row span.section-heading {
        font-size: 14px;
    }

    .date {
        position: relative !important;
        right: 20px;
    }

    .post-property-landlord-body .main-body, .post-property-panel .post-property-landlord-body .description-body, .post-property-panel .post-property-landlord-body .picture-body, .dashboard-body-parent, .bussiness-profile-body, .tenant-post-body .main-body {
        padding: 20px;
    }

    .user-detail-dashboard .tenant-dashboard-connection-status {
        font-size: 18px;
    }
}

@media (max-width: 390px) {
    .butn-two {
        margin-top: 1rem;
    }

    .butn-one {
        margin-left: 1rem;
    }
}

@media (max-width:380px) {
    .days-button {
        background: #99B8CA;
        color: var(--white-color);
        border: none;
        padding: 10px 5px;
        right: -35px;
        position: absolute;
    }

    .profile .profile-view .form-a .main-bio-heading {
        font-size: 20px !important;
    }

    .date {
        position: relative !important;
        right: 30px;
    }

    .post-property-panel .post-property-landlord-body .description-body .form-aside .btn-1, .post-property-panel .post-property-landlord-body .picture-body .form-aside .btn-1 {
        margin-left: 1rem;
        margin-right: 0rem;
    }

    .post-property-panel .post-property-landlord-body .address-body .form-aside .btn-2, .post-property-panel .post-property-landlord-body .description-body .form-aside .btn-2, .post-property-panel .post-property-landlord-body .picture-body .form-aside .btn-2 {
        margin-left: 1rem;
        margin-top: 1rem;
    }

    .dashboard-header-list-detail .header-row span.section-heading {
        font-size: 12px;
    }

    footer .footer-middle {
        position: absolute;
        bottom: 12px;
        left: 60px !important;
    }

    .button {
        width: 60px;
        height: 18px;
        margin-left: 34px;
        font-size: 8px;
    }

    .redhanded .a-side h1 {
        font-size: 1.3rem;
    }

    /* .redhanded .b-side img {
        bottom: 12.5rem;
        left: 11rem;
    } */
    .redhanded .a-side .aside-btn button {
        width: 7.7rem;
        height: 2.5rem;
        margin: 0px 10px 0px 0px;
        font-size: 14px;
    }

    .redhanded .a-side .aside-btn {
        margin: 2rem 0rem 0rem 1rem !important;
    }

    .requirements .tabs-btn {
        display: none;
    }

    .testimonial .coma {
        height: 80px !important;
    }

    .registration-as .registration-cards {
        margin: 0px !important;
    }

    div.how-it-works .work-list {
        padding: 0px !important;
    }

    .signup-wizard .form-aside {
        margin: 0rem !important;
        padding: 1rem !important;
    }

    .signup-wizard .form-aside h3, .signup-wizard .form-cside h3 {
        text-align: center !important;
        font-size: 1.4rem;
    }

    .signup-wizard .form-bside {
        padding: 2rem !important;
        margin: 2rem 0rem !important;
    }

    .signup-wizard .form-aside .form-group label {
        font-size: 0.9rem !important;
    }

    .signup-wizard .form-cside, .verification-body {
        margin: 2rem 0rem !important;
        padding: 2rem !important;
    }

    .profile .profile-view {
        margin: 2rem 0rem 2rem 0rem !important;
    }

    .profile .profile-view .a-side {
        margin: 0rem !important;
    }

    .profile .profile-view .form-a {
        margin: 2rem 0 !important;
        padding: 1rem !important;
    }

    .profile .profile-view .form-a .title {
        font-size: 1rem !important;
    }

    .profile .profile-view .form-a .title-info {
        font-size: 0.8rem !important;
    }

    .dashboard-header h2, div.dashboard-body-parent .heading-dashboard, .post-property-panel .post-property-landlord-header .crossred, .post-property-panel .post-property-tenant-header .crossred {
        font-size: 25px;
    }

    .slick-slider .carousel-user span.carousel-vedio-icon {
        top: 32%;
    }
}

@media(max-width:360px) {
    .navbar-header-title {
        font-size: 30px;
    }

    .review-landlord-description-icon {
        float: right !important;
        left: 161px !important;
        top: -30px !important;
    }

    .review-landlord-sqft-paragraph {
        margin-right: 0px !important;
    }
}

@media (max-width:346px) {
    .wd {
        width: 80%;
    }
}

@media (max-width:340px) {
    .dashboard-header-list-detail .connection-listing-body .connection-list-part {
        margin: 0px 0rem;
    }

    .dashboard-header-list-detail .requirement-body .business-profile-section {
        margin: 10px 0rem;
    }

    .dashboard-header-list-detail .requirement-body .requirment-name-section {
        margin: 10px 0rem;
    }

    .dashboard-header-list-detail .connection-listing-body .filter-part-connections {
        margin: 0px 0rem;
    }
}

@media (max-width:330px) {
    .property-detail-parent .header-list-detail .header-row span.section-heading {
        font-size: 12px !important;
    }

    .dashboard-header-list-detail .header-row span.section-heading {
        font-size: 12px;
    }
}

@media (max-width: 320px) {
    footer .footer-middle {
        position: absolute;
        bottom: 12px;
        left: 38px !important;
    }

    .redhanded .a-side .aside-btn button {
        width: 7.2rem;
        height: 2.5rem;
        font-size: 13px;
        margin: 0px 6px 0px 0px;
    }

    .requirements .tabs-btn {
        display: none !important;
    }

    .testimonial .coma {
        height: 80px !important;
    }

    .registration-as .registration-cards {
        margin: 0px !important;
    }

    div.how-it-works .work-list {
        padding: 0px !important;
    }

    .signup-wizard .form-aside {
        margin: 0rem !important;
        padding: 1rem !important;
    }

    .signup-wizard .form-aside h3, .signup-wizard .form-cside h3 {
        text-align: center !important;
        font-size: 1.4rem;
    }

    .signup-wizard .form-bside {
        padding: 2rem !important;
        margin: 2rem 0rem !important;
    }

    .signup-wizard .form-aside .form-group label {
        font-size: 0.9rem !important;
    }

    .signup-wizard .form-cside, .verification-body {
        margin: 2rem 0rem !important;
        padding: 2rem !important;
    }

    .profile .profile-view {
        margin: 2rem 0rem 2rem 0rem !important;
    }

    .profile .profile-view .a-side {
        margin: 0rem !important;
    }

    .profile .profile-view .form-a {
        margin: 1rem 0 !important;
        padding: 1rem !important;
    }

    .profile .profile-view .form-a .title {
        font-size: 1rem !important;
    }

    .profile .profile-view .form-a .title-info {
        font-size: 0.8rem !important;
    }
}

/*media query*/
@media (min-width:2450px) {
    .carousel-property-image-parent {
        height: 700px !important;
    }
}


@media(min-width:1700px) {
    /* .slick-slider .carousel-user span.carousel-vedio-icon, .testimonial .carousel-user span {
        left: 20px
    } */
}

@media (min-width:1320px) {
    .testimonial-text {
        min-width: 320px;
    }
}

@media(min-width:1230px) {
    .testimonial .carousel-user span {
        /* top: 70%; */
    }
}

@media (min-width:991px) {
    .connection-name-part {
        margin: auto;
    }

    .request-message-analytic-div {
        margin-top: 2rem;
    }

    .nav-bar-link-part {
        display: flex;
    }

    .position-player-card {
        float: right;
    }

    /* .login-signup-btn button {
        float: right;
    } */
    .login-signup-btn .login-modal {
        right: 0%;
    }

    .property-detail-parent .header-list-detail .connection-listing-body .filter-part-connections .list-sort .dropdown {
        float: right !important;
    }
}

@media (min-width:767px) {
    .listing-parent {
        margin-left: 4rem;
    }

    .padding-on-web {
        padding: 0 60px;
    }

}

@media (min-width:1700px) {
    .mar-top-detail-login {
        margin-top: 120px !important;
    }
}

@media(min-width:2150px) {

    /* .carosel-card-height-home {
        height: 11vh;
    } */
    /* .frjscc > input{
        font-size: 40px !important;
    } */
    .carouserl-img-parent {
        height: 16vh;
    }

    /* .requirements .carousel-user {
        height: 500px;
    } */

    /* .my-content-bottom-abs {
        position: absolute;
        bottom: -25px;
        display: flex;
        width: 90%;
    } */
}



@media(min-width:1750px) {
    /* .carouserl-img-parent {
        height: 270px;
    } */

    .field-search-btn {
        height: 80px;
        border-radius: 10px;
        margin-left: 1rem;
    }

    .my-field .jZkBQk {
        width: 100%;
        margin: auto;
        height: 86px;
    }

    .jZkBQk>.wrapper {
        border-radius: 5px;
        width: 100% !important;
        border-color: #a29999;
    }

    .my-field .jZkBQk>.wrapper {
        border-radius: 5px;
        width: 100% !important;
        border-color: #a29999;
        height: 80px;
    }
}

.my-content-bottom-abs span {
word-break: break-all;
font-size: 14px;
margin-top: 20px !important;
}

@media (min-width:800px) {
    .my-content-bottom-abs {
        /* position: absolute; */
        /* bottom: 0; */
        display: flex;
        /* justify-content: space-around !important; */
        width: 90%;
    }
}

@media (min-width:610px) {
    .connection-part .connection-detail .connection-detail-address {
        margin: auto;
    }
}

@media(min-width:575px) {
    .redhanded-video {
        height: inherit !important;
    }
}

@media (min-width:410px) {
    .RFS-ConnectorContainer {
        top: calc((2em - 1px) / 2);
        left: calc((-50% + 2em) - 16px) !important;
        right: calc((50% + 2em) - 16px) !important;
        position: absolute;
    }
}

@media (min-width:3351px) {
    .carousel-property-image-parent {
        height: 800px !important;
    }
}

@media (min-width:2401px)and (max-width:3350px) {
    .carousel-property-image-parent {
        height: 700px !important;
    }
}

@media (min-width:1950px)and (max-width:2400px) {
    .carousel-property-image-parent {
        height: 500px !important;
    }
}

@media(min-width:576px) and (max-width:767px) {
    .property-detail .analytics-body .analytics-parent .analtics-card .analytic-card-body .analytic-card-body-data {
        font-size: 3rem;
    }
}

@media (min-width:475px) and (max-width:575px) {
    .profile-image-section .profile-section {
        margin-left: 25px;
    }
}

/* end of media query*/