body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .7px;
  overflow-x: hidden;
}

@font-face {
  font-family: 'AvenirNextRegular';
  src: local('AvenirNextRegular'), url("./assets/Font/AvenirNext-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'AvenirNextMedium';
  src: local('AvenirNextMedium'), url("./assets/Font/AvenirNext-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'AvenirNextDemiBold';
  src: local('AvenirNextDemiBold'), url("./assets/Font/AvenirNext-DemiBold.ttf") format('truetype');
}

@font-face {
  font-family: 'AvenirNextBold';
  src: local('AvenirNextBold'), url("./assets/Font/AvenirNext-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'), url("./assets/Font/Roboto-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: local('RobotoMedium'), url("./assets/Font/Roboto-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'), url("./assets/Font/Roboto-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'), url("./assets/Font/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'), url("./assets/Font/Montserrat-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('MontserratSemiBold'), url("./assets/Font/Montserrat-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url("./assets/Font/Montserrat-Bold.ttf") format('truetype');
}