.redesign-table tbody::before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}
.redesign-table thead tr th {
  border-bottom: 0px;
}
.redesign-table tbody tr td {
  border-radius: 0px !important;
}
.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}
.notification-status-box {
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}
.notification-opened {
  color: #1bc5bd;
  background-color: #c9f7f5;
}
.notification-pending {
  color: #f64e60;
  background-color: #ffe2e5;
}
.dropdown-toggle::after {
  display: none;
}
.three-dot-icon {
  background-color: transparent !important;
}
.three-dot-icon svg {
  margin: 0px !important;
}
