.bg-section-2 h1 {
  font-size: 2.5vw;
  margin-top: 4vh !important;
  margin-bottom: 2vh !important;
}

.bg-section-2 h5 {
  font-size: 1.5vw;
  margin-bottom: 2vh !important;
  color: #FFF;
  font-family: 'Lato';
}

.bg-section-2 h6 {
  font-size: 1vw !important;
  margin-top: 4vh !important;
  margin-bottom: 1vh !important;
  font-family: 'Lato';
}

.bg-section-2 p {
  font-size: 1.3vw !important;
  line-height: 3vh !important;
  color: #D0E7FF;
  font-family: 'Lato';
}

.bg-section-2 svg {
  font-size: 5vh !important;
}

.bg-section-2 .left-div {
  margin: 2vh 1vh 0 !important;
}

.bg-section-2 .left-div .advantage-div {
  margin: 1vh 0 !important;
}

.bg-section-4 .requirements h1 {
  font-size: 2.1vw !important;
  margin: 6vh 0 1vh 0 !important;
}

.bg-section-4 .requirements h5 {
  font-size: 1.4vw !important;
}

.bg-section-3 h1 {

  margin: 0 0 2vh 0 !important;
  color: #FF9494;
}

.bg-section-3 h1 .heading {
  margin: 6vh 0 2vh 0 !important;
}

.bg-section-3 h4 {
  font-size: 1.5vw !important;
  margin-bottom: 2vh !important;
  color: #FF9898;
}

.bg-section-3 h5 {
  font-size: 2vw !important;
  margin: 2vh 0 1vh 0 !important;
}

.bg-section-3 p {
  line-height: 3.5vh !important;
  font-size: 1.3vw !important;
  margin-bottom: 2vh !important;
}

.bg-section-3 .img-size {
  width: 4.5vw !important;
  height: auto !important;
}

.bg-section-3 .content-row-1 {
  margin: 4vh 0 2vh 0 !important;
}

.bg-section-3 .content-row-2 {
  margin-bottom: 4vh !important;
}

.bg-section-3 .mrv-1 {
  margin-right: 2vw !important;
}

.bg-section-3 .div-img {
  margin: right 2vw !important;
}

.head {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.main-parent .sec1 {
  margin-top: 60px;
}

.bg-section-3 .col-margin-left {
  margin-left: 3vw !important;
}